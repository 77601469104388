import { ResponseType, ErrorsTypes } from '../utils/api'
import apiWithoutToken from '../utils/apiWithoutToken'
import { User as UserType } from '../types/types'
import { endpoints } from '../constants/constants'

/**
 * Токен.
 */
export type Token = string

/**
 * Ответ при запросе авторизации.
 */
export type LoginResponse = ResponseType & {
    data?: {
        /** Объект пользователя */
        id: number
        fullName: string
        password:string
        chatKey: string | null
        email: string
        phone: string | null
        photoPath: string
        dateOfBirth: string
        active: number
        createdAt:Date,
        updatedAt:Date,
        lastLoginAt: string | null
        /** Токен доступа */
        token: Token

    }
}


export type AuthLoginResult = {
    success: boolean
    errors?: ErrorsTypes
    data?: {
        user: UserType
    }
}

/**
 * Сервис для работы с авторизацией пользователя.
 */
export class Auth {
    /**
     * Запрос на авторизацию.
     * @param login     Логин (почта) пользователя.
     * @param password  Пароль.
     */

    
    static async login(
        email: string,
        password: string
    ): Promise<AuthLoginResult> {
        const result: AuthLoginResult = {
            success: false,
        }

        try {
            const response = await apiWithoutToken.post<LoginResponse>(
                
                endpoints.login,
                {
                    email,
                    password,
                }
            )

          

            Auth.saveTokens(
                response.data.data.token,
            )

            result.success = true
            result.data = {
                user: response.data.data,
            }
        } catch (error:any) {

            console.log('error', error.response);
            


            if (error.response && error.response.status === 422) {
                result.errors = error.response.data.errors
            } else {
                throw error
            }
        }

        return result
    }

    /**
     * Возвращает токен из localStorage.
     */
    static getToken() {
        return localStorage.getItem('access_token') || ''
    }

    /**
     * Удаляет access_token и refresh_token
     */
    static async removeToken() {
        // const accessToken = localStorage.getItem('access_token') || ''

        // await apiWithoutToken.delete<ResponseType>(endpoints.removeToken, {
        //     headers: {
        //         Authorization: accessToken,
        //     },
        // })

        // await apiWithoutToken.delete<ResponseType>(
        //     endpoints.removeRefreshToken,
        //     {
        //         headers: {
        //             Authorization: refreshToken,
        //         },
        //     }
        // )

        localStorage.removeItem('access_token')

    }

    /**
     * Сохранение токенов в localStorage
     * @param accessToken
     * @param refreshToken
     */
    static saveTokens(accessToken: Token): void {
        localStorage.setItem('access_token', accessToken)
    }

    // /**
    //  * Запрос на получение ссылки для восстановления пароля.
    //  * @param login Логин (почта) пользователя.
    //  */
    // static async sendPasswordRecoveryLink(login: string): Promise<void> {
    //     await apiWithoutToken.post<ResponseType>(
    //         endpoints.sendPasswordRecoveryLink,
    //         {
    //             login,
    //         }
    //     )
    // }

    // /**
    //  * Запрос на восстановления пароля.
    //  * @param token     Токен, который получен из письма для восстановления пароля.
    //  * @param password  Новый пароль.
    //  */
    // static async recoveryPassword(
    //     token: string,
    //     password: string
    // ): Promise<UserType> {
    //     const response = await apiWithoutToken.post<LoginResponse>(
    //         endpoints.recoveryPassword,
    //         { token, password }
    //     )

    //     Auth.saveTokens(
    //         response.data.data.token,

    //     )

    //     return response.data.data.user
    // }
}
