import { ErrorsTypes } from '../utils/api'

export type User = {
    id: number
    fullName: string
    password: string
    chatKey: string | null
    email: string
    phone: string | null
    photoPath: string
    dateOfBirth: string
    active: number
    createdAt: Date
    updatedAt: Date
    lastLoginAt: string | null
    publicEvents: number
    allEvents: number
}

export type NewsResponseType = {
    id: number
    authorId: number
    author: AuthorType
    categoryId: number
    category: {
        id: number
        name: string
    }
    placeId: number
    place: {
        id: number
        name: string
        address: string
        longitude: number
        latitude: number
        photoPath: string[]
        placesTypes: string | null
        socialNetworks: string[]
    }
    name: string
    description: string
    content: TextEditorType[]
    published: number
    viewsCount: number
    publishedAt: null
    createdAt: Date
    new_photos: ResponseNewPhotos[]
    updatedAt: Date
    imagePath: string[]
    photos: PhotoPath[]
    previews: PhotoPath[]
    isTop: 0 | 1
    link?: string
}

export type Thumbnails = {
    [size: string]: string
}
export type PhotoResponseType = {
    url: string
    id: number
    type?: AdvFileType
}

export type File = {
    id: number
    name: string
    ext: string
    original?: string
    hash?: string
    thumbnails: Thumbnails | null
}

export type Entity = {
    id: number
    title: string
}

export type PhotoPath = {
    id: number
    url: string
    type?: AdvFileType
}

export type InterestingFactsType = {
    id?: number
    eventId?: number
    description: string
    createdAt?: string
    updatedAt?: string
}
export type ArticleResponseType = {
    authorId: number
    createdAt: Date
    updatedAt: Date
    id: number
    photos: PhotoPath[]
    categoryId: number
    published: number // 1 or 0
    viewsCount: number
    name: string
    description: string
    content: TextEditorType[]
    author: AuthorType
    articleAuthor: string | null
    new_photos: ResponseNewPhotos[]
    category: CategoryArticleType
    previews: Array<PhotoPath>
}

export type CategoryArticleType = {
    id: number
    name: string
    description: string
}

export type AuthorType = {
    id: number
    fullName: string
    createdAt: Date
    updatedAt: Date
    token: string
    eventsCategories: string[]
    placesTypes: string[]
}

export type CreateEventResponseType = {
    id: number
}

export type ResponseNewPhotos = {
    photo_id: number
    photo_url: string
    type: PhotoType
    is_main: boolean
}

export type EventResponseType = {
    id: number
    placeId: number
    place: LocationResponse
    categoryId: number
    category: CategoryResponseType
    name: string
    description: string
    content: Array<TextEditorType>
    price: null | number
    priceMin: null | number
    priceMax: null | number
    viewsCount: number
    youtubeLink: null | string
    buyTicketLink: null | string
    startAt: string
    endAt: string
    published: number
    publishedAt: Date
    createdAt: Date
    updatedAt: Date
    photoPath: PhotoPath[]
    previewPath: PhotoPath[]
    seances: SeanceType[]
    interestingFacts: InterestingFactsType[]
    reviewsTotalRating: number
    reviewsTotalCount: number
    photos: Array<PhotoPath>
    previews: Array<PhotoPath>
    actors: Array<ActorsType>
    details: string
    new_photos: ResponseNewPhotos[]
    pushkinCard: boolean
    isTop: 0 | 1
    registrationOpened: 0 | 1
    tiflo: 0 | 1
}

export type PlaceType = {
    id: number
    name: string
    description: string
}

export type SeanceType = {
    id?: number
    eventId?: number
    startAt: string
    endAt: string
}

export type LocationResponse = {
    id: number
    name: string
    description: string
    address: string
    email: string | null
    contacts: string
    url: string | null
    longitude: number
    latitude: number
    createdAt: Date
    updatedAt: Date
    typeId: number
    schedule: string | null
    photoPath: Array<string>
    placesTypes: PlaceType
    socialNetworks: Array<string>
}

export type CategoryResponseType = {
    description: string
    name: string
    updatedAt?: Date
    createdAt?: Date
    id: number
}

export type CreateNewsResponseType = {
    authorId: number
    published: number
    id: number
}

export type CreateAdvertisingResponseType = {
    id: number
}

export type DataUploadPhoto = {
    file: File[]
}

export type UploadPhoto = {
    success: boolean
    errors?: ErrorsTypes
    data?: {
        events: PhotoResponseType
    }
}

export type RemovePhotoResponseType = {
    data: string[]
    baseUrl: string
    errors: string[]
    systemVersion: number
    needUpdate: number
    success: boolean
    meta: {
        totalCount: number
    }
}

export enum AdvFileType {
    MOBILE,
    WEB,
}

export enum PhotoType {
    MAIN_PHOTO = 'main_photo',
    PREVIEW = 'preview',
    BIG_PREVIEW = 'big_preview',
    MOBILE_PREVIEW = 'mobile_preview',
    MOBILE_MIN_PREVIEW = 'mobile_min_preview',
    MOBILE_HEAD_PHOTOS = 'mobile_head_photos',
    MOBILE_PHOTOS = 'mobile_photos',
    SIMPLE = 'simple',
    CAROUSEL_PREVIEW = 'carousel_preview',
}

export interface IPhotoArea {
    photo_id: number
    type: PhotoType
    is_main: boolean
    url: string
}

export type SizeType = {
    width: number
    height: number
    type: PhotoType
}

export type AdvertisingResponseType = {
    id: number
    title: string
    description: string
    link: string
    published: 1 | 0 | null
    viewsCount: number
    createdAt: Date | null
    updatedAt: Date
    photos: PhotoPath[]
    type: 0 | 1
}

export type ContactType = {
    name: string
    value: string
}

export type LocationResponseType = {
    id: number
    name: string
    description: string
    address: string
    phoneNumber: string | null
    email: string
    contacts: ContactType[]
    url: string
    longitude: string
    latitude: string
    typeId: number
    schedule: Array<Array<string>>
    createdAt: Date
    updatedAt: Date
    published: null | number
    photos: PhotoPath[]
    placesTypes: PlaceType
    new_photos: ResponseNewPhotos[]
    socialNetworks: SocialNetworks[]
    isTop: 0 | 1
    content: TextEditorType[]

    cityId: number
    order: string | null
}

export type SocialNetworks = {
    id?: number
    name: string
    photo: PhotoPath
    link: string
    socialId?: number
}

export type SpecialProjectResponseType = {
    title: string
    content: Array<TextEditorType>
    description: string
    links: Array<LinksProjectType>
    updatedAt: Date
    createdAt: Date
    id: number
    published: number
    publishedAt: Date
    new_photos: ResponseNewPhotos[]
    photos: PhotoPath[]
}

export type TextEditorType = {
    id: number
    text: string
    isQuote?: boolean
    isStrong?: boolean
    fontSize?: number
    photos: Array<PhotoPath>
}

export type MetaType = {
    totalCount: number
    maxCount: number
    pageCount: number

    perPage?: number
}

export type ActorsType = {
    role: string
    actors: string
}

export type FeedbackType = {
    id: number
    placeId: number
    userId: number
    rating: number
    title: string
    published: 1 | 0
    content: string
    createdAt: string
    updatedAt: string
    userPhoto: string
    userName: string
    placeName: string
}

export type LinksProjectType = {
    name: string
    link: string
}

/**
 * CITY
 */

export type CityType = {
    id: number
    name: string
    createdAt?: string
    updatedAt?: string
}

/**
 * Requests
 */
export enum RequestStatus {
    ON_VIEW = 0,
    AGREE = 1,
    DECLINE = 2,
    DECLINE_BY_USER = 3,
}

export type RequestUserType = {
    id: number
    fullName: string
    chatKey: null
    email: string
    phone: string | null
    photoPath: null
    dateOfBirth: null
    active: 0 | 1
    createdAt: string
    updatedAt: string
    lastLoginAt: string | null
    cityId: number
    firstName: string
    lastName: string
    eventsCategories: number[]
    placesTypes: number[]
    sendNotify: boolean
    notifications: number[]
    city: string
}

export type RequestType = {
    id: number
    eventId: number
    userId: number
    status: RequestStatus
    canceled: 0 | 1
    fio: string
    dateOfBirth: string | null
    createdAt: string
    updatedAt: string
    user: RequestUserType
    notes: string
    email: string
    event: EventResponseType
}
