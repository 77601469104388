import { ErrorsTypes } from '../utils/api'
import api from '../utils/api'
import { endpoints } from '../constants/constants'
import { getFormData } from '../constants/helper'
import { MetaType } from '../types/types'


export type LocationTypeResponse = {
    description: string,
    name: string,
    id: number
  }

export type LocationCategory = {
    success: boolean
    errors?: ErrorsTypes
    data?: {
        locationTypes: LocationTypeResponse
    }
}
export type GetListResponse = {
    data: LocationTypeResponse[],
    baseUrl: string,
    errors: any,
    systemVersion: number,
    needUpdate: number,
    success: boolean,
    meta: MetaType
}



/**
 * Сервис для работы с категориями статей
 */
export class LocationTypes {
    /**
     * Запрос на создание новой категории  стаьи
     * @param name  Название категории статьи
     * @param description  Описание статьи
     */

    
    static async createLocationType(
        name: string,
        description: string
    ): Promise<LocationCategory> {
        const result: LocationCategory = {
            success: false,
        }

        try {
            const fd = new FormData()
            fd.append("name", name)
            fd.append("description", description)
            const response = await api.post<LocationTypeResponse>(
                endpoints.createPlaceType,
                fd
            )

            result.success = true
            result.data = {
                locationTypes: response.data,
            }

            
        } catch (error:any) {
            console.log('error', error.response);
          
        }
        return result

    }  
    
    
     /**
     * Запрос на изменения уже существующей категории 
     * @param id  Индентификатор
     * @param name  Название категории статьи
     * @param description  Описание статьи
     */


      static async updateLocationType(
        id: number,
        data: {name: string, description: string}
    ): Promise<LocationCategory> {
        const result: LocationCategory = {
            success: false,
        }

        try {
           
            const response = await api.post<LocationTypeResponse>(
                `${endpoints.updatePlaceType}/${id}`,
                getFormData(data)
            )

            result.success = true
            result.data = {
                locationTypes: response.data,
            }

            
        } catch (error:any) {
            console.log('error', error.response);
          
        }
        return result

    } 


     /**
     * Метод для получения списка категорий
     */


    static async getListLocationTypes(page?: number, onPage?: number, limit:number = 10): Promise<GetListResponse> {
        const offset = (page ? page-1 : 0) * (onPage ?  onPage : 10)
        const lim = `&limit=${limit}`
        const response = await api.get(`${endpoints.listPlacesType}?offset=${offset}${lim}`)

        return response.data
    } 

     /**
     * Метод удаления категории статьи по id
     */

      static async deleteLocationType(id: number): Promise<boolean>{
        const response = await api.delete<GetListResponse>(`${endpoints.deletePlaceType}/${id}`)

        return response.data.success
    }
    

}