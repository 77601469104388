import React, { FC, ReactNode } from 'react'
import { ReactComponent as Logo } from '../../../assets/icons/logo.svg'
import styles from './AuthLayout.module.scss'

type Props = {
    children: ReactNode
}

const AuthLayout: FC<Props> = ({ children }) => {
    return (
        <div className={styles.main}>
            <div className={styles.leftContainer}>{children}</div>

            <div className={styles.rightContainer}>
                <Logo />
            </div>
        </div>
    )
}

export default AuthLayout
