import { getFormData } from './../constants/helper'
import {
    AuthorType,
    PhotoResponseType,
    RemovePhotoResponseType,
    NewsResponseType,
    MetaType,
} from './../types/types'
import { EventResponse } from './Event'
import { ErrorsTypes } from '../utils/api'
import api from '../utils/api'
import { endpoints } from '../constants/constants'
import {
    CreateNewsResponseType,
    DataUploadPhoto,
    UploadPhoto,
} from '../types/types'
import { route } from '../utils/route'

export type DataCreateNewsType = {
    categoryId: number | undefined
    placeId?: number | undefined
    name: string
    description: string
    content?: string
}

export type NewsData = {
    success: boolean
    errors?: ErrorsTypes
    data?: {
        news: NewsResponseType
    }
}
export type GetListResponse = {
    data: NewsResponseType[]
    baseUrl: string
    errors: any
    systemVersion: number
    needUpdate: number
    success: boolean
    meta: MetaType
}

export type CreateNewsResponse = {
    data: CreateNewsResponseType
    baseUrl: string
    errors: string[]
    systemVersion: number
    needUpdate: number
    success: boolean
    meta: any
}

/**
 * Сервис для работы с новостями
 */
export class News {
    /**
     * Метод для отмены публикации новости
     */
    static async unPublishEvent(id: number): Promise<boolean> {
        const response = await api.get<EventResponse>(
            `${endpoints.unPublishNews}/${id}`
        )

        return response.data.success
    }

    /**
     * Метод для отмены публикации новости
     */

    static async publishEvent(id: number): Promise<boolean> {
        const response = await api.get<EventResponse>(
            `${endpoints.publishNews}/${id}`
        )

        return response.data.success
    }

    /**
     * Метод для получения id создаваемой новости
     */
    static async createNews(): Promise<CreateNewsResponseType> {
        const response = await api.get<CreateNewsResponse>(endpoints.createNews)

        return response.data.data
    }

    /**
     * Метод для отправки фотографий контента
     */

    static async uploadPhoto(
        id: number,
        data: DataUploadPhoto
    ): Promise<UploadPhoto> {
        const result: UploadPhoto = {
            success: false,
        }

        try {
            const response = await api.post<PhotoResponseType>(
                `${endpoints.uploadPhotoNews}/${id}`,
                getFormData(data, true)
            )

            result.success = true
            result.data = {
                events: response.data,
            }
        } catch (error: any) {
            console.log('error', error.response)
        }
        return result
    }

    /**
     * Метод для отправки фотографий превью
     */

    static async uploadPreviewPhoto(
        id: number,
        data: DataUploadPhoto
    ): Promise<UploadPhoto> {
        const result: UploadPhoto = {
            success: false,
        }

        try {
            const response = await api.post<PhotoResponseType>(
                `${endpoints.uploadPreviewNews}/${id}`,
                getFormData(data)
            )

            result.success = true
            result.data = {
                events: response.data,
            }
        } catch (error: any) {
            console.log('error', error.response)
        }
        return result
    }

    /**
     * Метод для удаления фотографии контента
     */

    static async removePhoto(id: number): Promise<boolean> {
        const response = await api.delete<RemovePhotoResponseType>(
            `${endpoints.removePhotoNews}/${id}`
        )

        return response.data.success
    }

    /**
     * Метод для удаления фотографии превью
     */

    static async removePreviewPhoto(id: number): Promise<boolean> {
        const response = await api.delete<RemovePhotoResponseType>(
            `${endpoints.removePreviewNews}/${id}`
        )

        return response.data.success
    }

    /**
     * Метод для получения списка новостей
     */

    static async getListNews(
        page?: number,
        onPage?: number,
        limit: number = 10
    ): Promise<GetListResponse> {
        const offset = (page ? page - 1 : 0) * (onPage ? onPage : 10)
        const lim = `&limit=${limit}`
        const response = await api.get<GetListResponse>(
            `${endpoints.listNews}?offset=${offset}${lim}`
        )

        return response.data
    }
    /**
     * Метод для получения одной новости
     */

    static async getOneNews(id: number): Promise<NewsResponseType> {
        const response = await api.get(route(endpoints.getNews, { id }))

        return response.data.data
    }

    /**
     * Метод удаления категории новостей
     */

    static async deleteNews(id: number): Promise<boolean> {
        const response = await api.delete<GetListResponse>(
            `${endpoints.deleteNews}/${id}`
        )

        return response.data.success
    }

    /**
     * Запрос на создание новой новости
     *
     * @param categoryId id категория новости
     * @param placeId id места
     * @param name  Название новости
     * @param description  Описание новости
     * @param content Содержание новости
     * @param photo массив фотографий
     */

    static async updateNews(
        id: number,
        data: DataCreateNewsType
    ): Promise<NewsData> {
        const result: NewsData = {
            success: false,
        }

        try {
            const response = await api.post<NewsResponseType>(
                `${endpoints.updateNews}/${id}`,
                getFormData(data)
            )

            result.success = true
            result.data = {
                news: response.data,
            }
        } catch (error: any) {
            console.log('error', error.response)
        }
        return result
    }
}
