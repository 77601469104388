import { PhotoResponseType, EventResponseType, DataUploadPhoto, UploadPhoto, RemovePhotoResponseType, CreateEventResponseType, SocialNetworks, MetaType } from '../types/types';
import { getFormData } from '../constants/helper';
import { ErrorsTypes } from '../utils/api'
import api from '../utils/api'
import { endpoints } from '../constants/constants'
import { route } from '../utils/route';



export type  DataCreateSocialNetwork = {
    name: string,    
}



export type SocialNetworkData = {
    success: boolean
    errors?: ErrorsTypes
    data?: {
        events: EventResponseType
    }
}




export type EventResponse = {
    data: EventResponseType,
    baseUrl: string,
    errors: string[],
    systemVersion: number,
    needUpdate: number,
    success: boolean,
    meta: {
        totalCount: number
    }
}

export type GetListEventResponse = {
    data: SocialNetworks[],
    baseUrl: string,
    errors: string[],
    systemVersion: number,
    needUpdate: number,
    success: boolean,
    meta: MetaType
}
type ResponseUploadPhototype = {
    data: PhotoResponseType
    success: boolean
}





/**
 * Сервис для работы с событиями
 */
export class SocialNetwork {
    /**
     * Метод для получения id создаваемой статьи
     */
    static async createSocialNetwork(): Promise<CreateEventResponseType> {
        const response = await api.get<EventResponse>(endpoints.createSNtype)

        return response.data.data
    }  

    /**
     * Метод для отправки фотографий соц сети 
     */

     static async uploadPhoto(id: number, data: DataUploadPhoto): Promise<ResponseUploadPhototype> {
        const response = await api.post(`${endpoints.photoUploadSNtype}/${id}`,getFormData(data))
            return response.data
    }


     /**
     * Метод для удаления фотографии контента
     */

      static async removePhoto(id: number): Promise<boolean> {
        
        const response = await api.delete<RemovePhotoResponseType>(`${endpoints.photoRemoveSNtype}/${id}`)

        return response.data.success
    } 
    
    
    /**
     * Запрос на создание новой социальной
     * 
     * @param name название категории социальной сети
    
     */

          static async updateSocialNetwork(id: number, data: DataCreateSocialNetwork): Promise<SocialNetworkData> {
            const result: SocialNetworkData = {
                success: false,
            }
    
            try {
               
                const response = await api.post<EventResponseType>(
                    `${endpoints.updateSNtype}/${id}`,
                    getFormData(data)
                )
    
                result.success = true
                result.data = {
                    events: response.data,
                }
    
                
            } catch (error:any) {
                console.log('error', error.response);
              
            }
            return result
    
        }
    
    /**
     * Метод для получения списка типов социальных сетей
     */

    static async getListSocialNetwork(page?: number, onPage?: number, limit:number = 10): Promise<GetListEventResponse> {
        const offset = (page ? page-1 : 0) * (onPage ?  onPage : 10)
        const lim = `&limit=${limit}`
        const response = await api.get<GetListEventResponse>(`${endpoints.listSNtype}?offset=${offset}${lim}`)

        return response.data
    }  
    /**
     * Метод удаления
     */

    static async deleteSocial(id: number): Promise<boolean> {

        const response = await api.delete(route(endpoints.deleteSNtype, {id}))

        return response.data.success
    }  

}