import React, { FC } from 'react'
import styles from './Main.module.scss'
import { Button, Layout } from 'antd'
import { Content, Header, Footer } from 'antd/lib/layout/layout'
import { Redirect, Route, Switch, useHistory } from 'react-router'
import { Advertising } from '../Advertising'
import { routes } from '../../../constants/config'
import { CreateEvent } from '../CreateEvent'
import { CreateLocation } from '../CreateLocation'
import { CreateLocationType } from '../CreateLocationType'
import { CreateNews } from '../CreateNews'
import { EventCategory } from '../EventCategory'
import { NewsCategory } from '../NewsCategory'
import { CreateSpecialProjects } from '../CreateSpecialProjects'
import { ArticleCategory } from '../CategoryArticle'
import { Auth } from '../../../services/Auth'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { setAuthStatus } from '../../../store/slice/auth'
import { ListArticleCategories } from '../ListArticleCategories'
import { ListNewsCategories } from '../ListNewsCategories'
import { ListLocationTypes } from '../ListLocationTypes'
import { ListEventCategories } from '../ListEventCategories'
import { ListAds } from '../ListAds'
import { ListEvents } from '../ListEvents'
import { ListSpecialProject } from '../ListSpecialProject'
import { ListArticles } from '../ListArticles'
import { CreateArticle } from '../CreateArticle'
import { ListNews } from '../ListNews'
import { ListLocation } from '../ListLocation'
import { CreateSocialNetwork } from '../CreateSocialNetwork'
import { PlaceFeedbacks } from '../../pages/PlaceFeedbacks'
import { EventFeedbacks } from '../../pages/EventFeedbacks'
import { ListCity } from '../ListCity'
import { EditCityPage } from '../EditCityPage'
import { RequestsPage } from '../RequestsPage'
import { Users } from '../Users'

type Props = {}

const Main: FC<Props> = () => {
    const dispatch = useAppDispatch()
    const navigation = useHistory()
    const user = useAppSelector((state) => state.auth.user)

    const onClickHandler = async () => {
        await Auth.removeToken()
        dispatch(setAuthStatus())
        navigation.push(routes.login)
    }

    return (
        <Layout style={{ marginLeft: 270 }} className="site-layout">
            <Header
                className="site-layout-background"
                style={{
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    paddingRight: '15px',
                }}
            >
                <div className={styles.userInfo}>
                    <span className={styles.userInfoText}>
                        {user?.fullName}
                    </span>
                    <span className={styles.userInfoText}>{user?.email}</span>
                </div>
                <Button onClick={onClickHandler}>выйти</Button>
            </Header>
            <Content style={{ margin: '0 16px' }}>
                <Switch>
                    <Route
                        exact
                        path={routes.advertising}
                        component={Advertising}
                    />
                    <Route exact path={routes.get_ads} component={ListAds} />

                    <Route
                        path={routes.create_articles}
                        component={CreateArticle}
                    />
                    <Route
                        path={routes.get_article_list}
                        component={ListArticles}
                    />

                    <Route
                        path={routes.article_category}
                        component={ArticleCategory}
                    />
                    <Route
                        path={routes.get_article_categories}
                        component={ListArticleCategories}
                    />

                    <Route
                        exact
                        path={routes.news_category}
                        component={NewsCategory}
                    />
                    <Route
                        exact
                        path={routes.get_news_categories}
                        component={ListNewsCategories}
                    />

                    <Route
                        exact
                        path={routes.create_location_type}
                        component={CreateLocationType}
                    />
                    <Route
                        exact
                        path={routes.get_location_types}
                        component={ListLocationTypes}
                    />

                    <Route
                        exact
                        path={routes.event_category}
                        component={EventCategory}
                    />
                    <Route
                        exact
                        path={routes.get_event_categories}
                        component={ListEventCategories}
                    />

                    <Route
                        exact
                        path={routes.create_events}
                        component={CreateEvent}
                    />
                    <Route
                        exact
                        path={routes.get_events_list}
                        component={ListEvents}
                    />

                    <Route exact path={routes.get_users} component={Users} />

                    <Route
                        exact
                        path={routes.create_news}
                        component={CreateNews}
                    />
                    <Route
                        exact
                        path={routes.get_news_list}
                        component={ListNews}
                    />

                    <Route
                        exact
                        path={routes.create_location}
                        component={CreateLocation}
                    />
                    <Route
                        exact
                        path={routes.get_location_list}
                        component={ListLocation}
                    />
                    <Route
                        exact
                        path={routes.create_social_network}
                        component={CreateSocialNetwork}
                    />

                    <Route
                        exact
                        path={routes.special_projects}
                        component={CreateSpecialProjects}
                    />
                    <Route
                        exact
                        path={routes.get_special_projects}
                        component={ListSpecialProject}
                    />

                    <Route
                        exact
                        path={routes.feedbacksPlace}
                        component={PlaceFeedbacks}
                    />
                    <Route
                        exact
                        path={routes.feedbacksEvents}
                        component={EventFeedbacks}
                    />
                    <Route exact path={routes.cities} component={ListCity} />
                    <Route
                        exact
                        path={routes.editCities}
                        component={EditCityPage}
                    />
                    <Route
                        exact
                        path={routes.requests}
                        component={RequestsPage}
                    />
                    <Redirect to={routes.home} />
                </Switch>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
                “Культура и Искусство Южного Урала”
            </Footer>
        </Layout>
    )
}

export default Main
