import React, { FC } from 'react'
import { PublishBlock } from '../../simples/PublishBlock'

import styles from './PlaceFeedbacks.module.scss'

type Props = {
    name: string
    title: string
    content: string
    userPhoto?: string
    onPublish: () => void
    onUnPublish: () => void
    onChange: () => void
    onDelete: () => void
    isPublish: boolean
}

/**
 * 
 * @param name 
 * @param title 
 * @param content 
 * @param photoPath 
 * @param onPublish 
 * @param onUnPublish 
 * @param onChange 
 * @param onDelete 
 * @param isPublish 
 * @param withFeedback 
 * @param onFeedbackClick 
 * @returns 
 */

const Item:FC<Props> = ({
    name,
    title,
    content,
    onPublish,
    onUnPublish,
    onChange,
    onDelete,
    isPublish,
    userPhoto
}) => {
    return (
        <div className={styles.holder}>
        <div className={styles.left}>
            <div className={styles.image}>
                <img alt="" src={`${process.env.REACT_APP_API_URL}/${userPhoto}`} />
            </div>
            <div className={styles.textWrapper}>
                <h3 className={styles.title}>{title}</h3>
                <p className={styles.description}>{content}</p>
            </div>
        </div>
        <div className="">
            
        <PublishBlock
            onPublish={onPublish}
            onUnPublish={onUnPublish}
            onChange={onChange}
            onDelete={onDelete}
            isPublish={isPublish}
            changeLabel={'Просмотреть'}
        />
        </div>
    </div>
    )
}

export default Item