import React, {
    FC,
    InputHTMLAttributes,
    useCallback,
    useEffect,
    useState,
    FocusEvent,
    forwardRef,
} from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import style from './TextField.module.scss'


export enum TextFieldStatus {
    Normal,
    Success,
    Error,
}

export type Props = InputHTMLAttributes<
    HTMLInputElement | HTMLTextAreaElement
> & {
    id?: string
    label?: string
    helperText?: string
    error?: boolean
    multiline?: boolean
    classes?: {
        root?: string
        label?: string
        input?: string
    }
    outLabel?: boolean
    status?: TextFieldStatus
    note?: string
}

/**
 * Компонент Input
 * @param {string|undefined} id - идентификатор
 * @param {string} label - Название текстового поля
 * @param value - Значение текстового поля
 * @param {string} helperText - Текст подсказки под текстовым полем
 * @param {boolean} error - Подсветка ошибки
 * @param {boolean} multiline - Если true - выводиться textarea
 * @param classes - Дополнительные стилевые классы
 * @param onChange - Callback изменения текстового поля
 * @param onFocus - Callback фокуса
 * @param onBlur - Callback потери фокуса
 * @param rest
 * @constructor
 */
const TextField = forwardRef<HTMLInputElement, Props>(
    (
        {
            id: iid,
            label,
            value,
            helperText,
            error = false,
            multiline = false,
            classes = {},
            onChange,
            onFocus,
            onBlur,
            disabled,
            outLabel = false,
            status = TextFieldStatus.Normal,
            note = '',
            ...rest
        },
        ref
    ) => {
        const [id, setId] = useState('')
        const [focus, setFocus] = useState(false)

        // Получение идентификатора
        // Если идентификатор отсутствует, генерируется случайная строка
        useEffect(() => {
            const id = iid || _.uniqueId('text-field')
            setId(id)
        }, [iid, setId])

        // Обработчик фокуса
        // При фокусировки добавляется класс, который меняет местоположение label
        const onHandleFocus = useCallback(
            (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setFocus(true)
                if (onFocus) {
                    onFocus(event)
                }
            },
            [setFocus, onFocus]
        )

        // Обработка потери фокуса
        // Удаляется класс, который устанавливался при фокусировке
        const onHandleBlur = useCallback(
            (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setFocus(false)
                if (onBlur) {
                    onBlur(event)
                }
            },
            [onBlur]
        )

        // Вывод компонента
        return (
            <div
                className={classNames(style.root, classes.root, {
                    [style.focus]: focus,
                    [style.filled]: !!value,
                    [style.error]: error,
                    [style.disabled]: !!disabled,
                })}
            >
                {outLabel && label && (
                    <div className={style.outLabel}>{label}</div>
                )}

                <div className={style.base}>
                    {label && !outLabel && (
                        <label
                            className={classNames(style.label, classes.label)}
                            htmlFor={id}
                        >
                            {label}
                        </label>
                    )}

                    {multiline ? (
                        <textarea
                            {...rest}
                            className={classNames(style.input, classes.input)}
                            id={id}
                            value={value || ''}
                            onChange={onChange}
                            onFocus={onHandleFocus}
                            onBlur={onHandleBlur}
                            disabled={disabled}
                        />
                    ) : (
                        <input
                            {...rest}
                            className={classNames(style.input, classes.input)}
                            id={id}
                            value={value || ''}
                            onChange={onChange}
                            onFocus={onHandleFocus}
                            onBlur={onHandleBlur}
                            disabled={disabled}
                            ref={ref}
                        />
                    )}

                    {label && !outLabel && (
                        <div className={style.notch}>
                            <span className={style.notchContainer}>
                                {label}
                            </span>
                        </div>
                    )}
                </div>

                {helperText && <p className={style.helperText}>{helperText}</p>}
            </div>
        )
    }
)

export default TextField
