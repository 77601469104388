import { DeleteOutlined, EyeOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import { FC, useState } from 'react'
import { PhotoPath } from '../../../types/types'
import { PhotosLoader } from '../PhotosLoader'
import style from './PhotoGallery.module.scss'

type Props = {
    title: string
    data: Array<PhotoPath>
    onUpload: (value: any) => void
    onDelete: (id: number) => void
    multiple?: boolean
    isCrop?: boolean
}

/**
 *
 * @param title
 * @param data
 * @param onUpload
 * @param onDelete
 * @returns
 */
const PhotoGallery: FC<Props> = ({
    title,
    data,
    onUpload,
    onDelete,
    multiple = true,
    isCrop,
}) => {
    const [selectedPhotoForModal, setSelectedPhotoForModal] = useState<string>()
    const [isModalVisible, setIsModalVisible] = useState(false)

    const showModal = (url: string) => {
        setSelectedPhotoForModal(url)
        setIsModalVisible(true)
    }

    const handleCancelOldPhoto = () => {
        setSelectedPhotoForModal(undefined)
        setIsModalVisible(false)
    }

    return (
        <>
            <h2 className={style.title}>{title}</h2>
            <div className={style.customGalery}>
                {data && data.length !== 0 ? (
                    <div className={style.photoMainWrapper}>
                        {data.map((photo) => {
                            return (
                                <div
                                    key={photo.id}
                                    className={style.photoWrapper}
                                >
                                    <img
                                        className={style.photo}
                                        src={`${process.env.REACT_APP_API_URL}/${photo.url}`}
                                        alt="modal"
                                    />
                                    {!isCrop && (
                                        <>
                                            <div
                                                className={
                                                    style.iconsInsideWrapper
                                                }
                                            >
                                                <EyeOutlined
                                                    onClick={() =>
                                                        showModal(photo.url)
                                                    }
                                                    className={
                                                        style.iconInsidePhoto
                                                    }
                                                    style={{ color: 'white' }}
                                                />
                                                <DeleteOutlined
                                                    onClick={() =>
                                                        onDelete(photo.id)
                                                    }
                                                    className={
                                                        style.iconInsidePhoto
                                                    }
                                                    style={{ color: 'white' }}
                                                />
                                            </div>
                                            <Modal
                                                footer={null}
                                                visible={isModalVisible}
                                                onCancel={handleCancelOldPhoto}
                                            >
                                                <img
                                                    className={
                                                        style.oldImgInsideModal
                                                    }
                                                    src={`${process.env.REACT_APP_API_URL}/${selectedPhotoForModal}`}
                                                    alt="modal"
                                                />
                                            </Modal>
                                        </>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                ) : null}
                {!isCrop && (
                    <div>
                        {data.length > 0 ? null : (
                            <PhotosLoader
                                multiple={isCrop ? false : multiple}
                                updateData={async (value) => {
                                    onUpload(value)
                                }}
                                isCrop={isCrop}
                            />
                        )}
                    </div>
                )}
            </div>
        </>
    )
}

export default PhotoGallery
