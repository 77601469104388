import { CloseOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons'
import { Button, Collapse, List, message, Modal } from 'antd'
import Title from 'antd/lib/typography/Title'
import produce from 'immer'
import { FC, useEffect, useState } from 'react'
import { SocialNetwork, SocialNetwork as SocialNetworkService } from '../../../services/SocialNetwork'
import { CreateEventResponseType, PhotoPath, PhotoResponseType, SocialNetworks } from '../../../types/types'
import { InputLabled } from '../../simples/InputLabled'
import { ListHolder } from '../../simples/ListHolder'
import { PhotoGallery } from '../PhotoGallery'
import styles from './CreateSocialNetwork.module.scss'

import style from './CreateSocialNetwork.module.scss'
import SocialItem from './SocialItem'

type Props = {}

const init = {
    photo: {} as PhotoPath,
    name: '',
    id: 0,
    link: ''

}

const { Panel } = Collapse

const CreateSocialNetwork: FC<Props> = () => {

    const [list, setList] = useState<SocialNetworks[]>([])
    const [isError, setError] = useState(false)

    const [totalCount, setTotalCount] = useState<number>(0)
    const [onPage, setOnPage] = useState<number>(10)

    const [page, setPage] = useState<number>(1)

    const [editSoc, setEditSoc] = useState<SocialNetworks>(init)

    const getList = async () => {

        const list = await SocialNetworkService.getListSocialNetwork(page)
        setTotalCount(list.meta.maxCount)
        setOnPage(10)
        setList(list.data)
    }


    const loadingPhoto = async (photo: any) => {

        const data = {
            file: [photo]
        }

        let response = await SocialNetworkService.uploadPhoto(editSoc.id || 0, data)

        if (response.success) {
            setEditSoc((prev) =>( {...prev, photo: response.data}))
        } else {
            message.error('Произошла ошибка при загрузке фотографии')
        }
    }

    const deletePhoto = async (id: number) => {

        let result = await SocialNetworkService.removePhoto(id)
        if (result) {

            setEditSoc(prev => {
                return ({
                    ...prev,
                    photo: {
                        id: 0,
                        url: ''
                    }
                })
            })
        } else {
            message.error('Что-то пошло не так, не получилось удалить фотографию')
        }
    }

    const onSubmit = async () => {
        if (
            editSoc.name.trim() === '' ||
            editSoc.photo.id === 0

        ) {
            setError(true)
        }
        let data = {
            name: editSoc.name
        }

        const response = await SocialNetworkService.updateSocialNetwork(editSoc.id || 0, data)

        if (response.success) {
            setEditSoc(init)
            getList()
        }

    }

    const createSocialNewwork = async () => {
        const response = await SocialNetworkService.createSocialNetwork()
        setEditSoc({
            ...{} as SocialNetworks,
            id: response.id
        })

    }


    useEffect(() => {
        getList().then()
    }, [page])


    /**
     * delete function
     */
    const onHandleDelete = async (id: number) => {

        const response = await SocialNetwork.deleteSocial(id)
        if (response) {

            setList(produce(draft => draft.filter(i => i.id !== id)))
        }
    }


    return (
        <>
            <ListHolder
                totalCount={totalCount}
                onPageChange={setPage}
                onPageShow={setOnPage}
            >
                <Title level={2}>Социальные сети</Title>
                <Collapse defaultActiveKey={['social']} accordion>
                    <Panel header="Социальные сети" key="social">
                        <div className={style.linksWrapper}>
                            <List
                                style={{ border: "none", width: '100%' }}
                                size="default"
                                bordered
                                dataSource={list}
                                renderItem={(item, index) => {
                                    return (
                                        <List.Item style={{ width: '100%', justifyContent: 'flex-end', padding: 0, margin: 0 }}>
                                            <SocialItem
                                                onChange={() => setEditSoc(item)}
                                                data={item}
                                                onDelete={() => onHandleDelete(item.id || 0)} />
                                        </List.Item>

                                    )
                                }}
                            />
                        </div>
                    </Panel>
                </Collapse>
                <Button
                    onClick={createSocialNewwork}
                    style={{
                        marginTop: '10px'
                    }} type={'primary'}>Добавить социальную сеть</Button>
            </ListHolder>
            <Modal
                bodyStyle={{ height: '400px' }}
                width={800}
                title={"Создание социальной сети" }
                visible={!!editSoc?.id }
                onOk={onSubmit}
                onCancel={() => {
                    setEditSoc(init)
                }}
                okText={'Сохранить'}
                cancelText={'Отменить'}
            >
                <PhotoGallery
                    multiple={false}
                    title={'Фото'}
                    data={!!editSoc?.photo?.id ? [editSoc?.photo] : []}
                    onUpload={(value) => loadingPhoto(value)}
                    onDelete={(id) => deletePhoto(id)}
                />
                <InputLabled 
                value={editSoc.name} 
                onChange={(e) => setEditSoc({
                    ...editSoc,
                    name: e
                })} 
                label={'Название'} />

            </Modal>
        </>
    )
}

export default CreateSocialNetwork
