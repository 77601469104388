import { Alert, Button, message } from 'antd'
import Title from 'antd/lib/typography/Title'
import React, { FC, useEffect, useState } from 'react'
import { CategoryEvent as CategoryEventService } from '../../../services/CategoryEvent'
import { TitleAndDefinition } from '../../simples/TitleAndDefinition'
import styles from './EventCategory.module.scss'

type Props = {}

const EventCategory: FC<Props> = () => {

    const [namaCategoty, setCategory] = useState('')
    const [description, setDescription] = useState('')
    const [isError, setError] = useState(false)

    const onClickHandler = async () => {
        if (namaCategoty.trim() === '' || description.trim() === '') {
            setError(true)
            return
        }
        const response = await CategoryEventService.createEventCategory(namaCategoty, description)

        if (response.success) {
            setCategory('')
            setDescription('')
        } else {
            message.error('Не получилось создать категорию');
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setError(false)
        }, 5000);
    }, [isError])

    return (
        <div className={styles.wrapper}>
            <Title level={2}>Создание категории события</Title>
            {isError ? <Alert className={styles.alert} message="Все поля обязательны к заполнению" type="error" /> : null}
            <TitleAndDefinition
                isError={isError}
                firstValue={namaCategoty}
                textAreaValue={description}
                firstTitle='Название категории события'
                textAreaTitle='Описание категории события'
                firstPlaceholder={true}
                textAreaPlaceholder={true}
                firstPlaceholderTitle='Введите название категории'
                textAreaPlaceholderTitle='Введите описание категории'
                onChangeFirst={(value) => setCategory(value)}
                onChangeTextArea={(value) => setDescription(value)}
            />
            <Button onClick={onClickHandler}>Отправить</Button>
        </div>


    )
}

export default EventCategory
