import { endpoints } from '../constants/constants'
import { CityType, MetaType } from '../types/types'
import api from '../utils/api'
import { route } from '../utils/route'


type CilyListResponse = {
    data: CityType[],
    baseUrl: string,
    errors: any,
    systemVersion: number,
    needUpdate: number,
    success: boolean,
    meta: MetaType
}

export class CityService {
    static async getCityList(page?: number, onPage?: number, limit:number = 10):Promise<CilyListResponse> {
        const offset = (page ? page-1 : 0) * (onPage ?  onPage : 10)
        const lim = `&limit=${limit}`

        const response = await api.get(`${endpoints.cityList}?offset=${offset}${lim}`)
        return response.data
    }

    static async getCityById(id: string):Promise<CityType>{
        const response = await api.get(route(endpoints.getCityById, {id}))
        return response.data.data
    }

    static async createCity(data: {
        name: string,
        longitude: string,
        latitude: string
    }):Promise<CityType>{
        const fd = new FormData()
        const sendD: any = data
        for(let key in sendD){
            fd.append(key, sendD[key])
        }

        const response = await api.post(endpoints.createCity, fd)
        return response.data.data
    }
    static async updateCity(id: string,data: {
        name: string,
        longitude: string,
        latitude: string
    }):Promise<CityType>{
        const fd = new FormData()
        const sendD: any = data
        for(let key in sendD){
            fd.append(key, sendD[key])
        }

        const response = await api.patch(route(endpoints.updateCity, {id}), fd)
        return response.data.data
    }

    static async deleteCity(id: number){
        const response = await api.delete(route(endpoints.deleteCity, {id}))
        return response.data
    }

    static async getHints(search: string):Promise<Array<string>>{
        const response = await api.get(`${endpoints.cityHint}?city=${search}`)
        return response.data.data
    }
}