import { DeleteOutlined } from '@ant-design/icons';
import React, { FC } from 'react';
import { CityType } from '../../../types/types';
import styles from './ListCity.module.scss'

type Props = {
    city: CityType
    onClick: (id: number) => void
    onChange: (id: number) => void
    onDelete: (id: number) => void
}
const ListItem: FC<Props> = ({ city, onClick, onChange, onDelete }): JSX.Element => {
    return (
        <div
            onClick={() => onClick(city.id)}
            className={styles.item}>
            <span className={styles.cityName}>{city.name}</span>

            <div className="">
                <span onClick={() => onChange(city.id)} className={styles.change}>Изменить</span>
                <DeleteOutlined style={{
                    color: 'red'
                }} className={styles.icon} onClick={() => onDelete(city.id)} />
            </div>
        </div>
    )
}

export default React.memo(ListItem);
