import React, { FC, useEffect, useState } from 'react'
import {  Alert, Button, Menu, message } from 'antd'
import { TitleAndDefinition } from '../../simples/TitleAndDefinition'
import { CategoryNews } from '../../../services/CategoryNews'
import styles from './NewsCategory.module.scss'
import Title from 'antd/lib/typography/Title'

type Props = {}

const NewsCategory: FC<Props> = () => {

    const [namaCategoty, setCategory] = useState('')
    const [description, setDescription] = useState('')
    const [isError, setError] = useState(false)
    
    const onClickHandler = async () => {
        if (namaCategoty.trim() === '' || description.trim() === '') {
            setError(true)
            return
        }
        const response = await CategoryNews.createNewCategory(namaCategoty, description)

        if (response.success) {
            setCategory('')
            setDescription('')
        } else {
            message.error('Не получилось создать новость');
        }
    }

    useEffect(()=> {
       setTimeout(() => {
            setError(false) 
       }, 5000); 
    },[isError])


    return (
        
        <div className = {styles.wrapper}>
            <Title level={2}>Создание категории новостей</Title>
            {isError ? <Alert className = {styles.alert} message="Все поля обязательны к заполнению" type="error" /> : null}
            <TitleAndDefinition 
                isError = {isError}
                firstValue = {namaCategoty}
                textAreaValue = {description}
                firstTitle = 'Название категории новости'
                textAreaTitle = 'Описание категории новости'
                firstPlaceholder = {true}
                textAreaPlaceholder = {true}
                firstPlaceholderTitle = 'Введите название категории'
                textAreaPlaceholderTitle = 'Введите описание категории'
                onChangeFirst = {(value)=> setCategory(value)}
                onChangeTextArea = {(value) => setDescription(value)}
            />
            <Button type = 'primary' onClick = {onClickHandler}>Отправить</Button>
        </div> 

    )
}

export default NewsCategory
