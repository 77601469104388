import { FC, useCallback, useEffect, useState } from 'react'
import { Button, List, Select } from 'antd'
import styles from './ListEvents.module.scss'

import { Event, Event as EventServise } from '../../../services/Event'
import { Location as LocationService } from '../../../services/Location'
import {
    CategoryResponseType,
    EventResponseType,
    PlaceType,
} from '../../../types/types'
import { useHistory } from 'react-router'
import { routes } from '../../../constants/config'
import { ListItem } from '../ListItem'
import produce from 'immer'
import Title from 'antd/lib/typography/Title'
import { ListHolder } from '../../simples/ListHolder'

import XLSModal from './XLSModal'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { setEventPage } from '../../../store/slice/app'
import { CategoryEvent } from '../../../services/CategoryEvent'
import { getPrewiewPhoto } from '../../../utils/photoEditor'

const ListEventCategories: FC = () => {
    const dispatch = useAppDispatch()
    const activePage = useAppSelector((state) => state.app.eventPage)

    const nav = useHistory()
    const [list, setList] = useState<EventResponseType[]>([])
    const [removedStatus, setRemovedStatus] = useState(false)
    const [categories, setCategories] = useState<CategoryResponseType[]>([])
    const [categoriesId, setCategoriesId] = useState<Array<any>>([])
    const [showXLSModal, setShowXLSModal] = useState<boolean>(false)
    const [totalCount, setTotalCount] = useState<number>(0)
    const [placId, setPlaceId] = useState<number>()
    const [places, setPlaces] = useState<PlaceType[]>([])
    const [onPage, setOnPage] = useState<number>(10)

    const allEvents = useAppSelector((state) => state.auth.user?.allEvents) || 0
    const publicEvents =
        useAppSelector((state) => state.auth.user?.publicEvents) || 0

    /**
     * получения списка записей
     */
    const getList = async () => {
        const list = await EventServise.getListEvents(
            activePage,
            undefined,
            10,
            categoriesId,
            placId
        )

        setTotalCount(list.meta.maxCount)
        setOnPage(10)
        setList(list.data)
    }

    const unPublicCount = allEvents - publicEvents

    const removeCategory = async (id: number) => {
        const response = await EventServise.deleteEvent(id)

        if (response) {
            setRemovedStatus(true)
        }
    }

    /**
     * получения списка категорий
     */

    const getCategory = async () => {
        const listCategories = await CategoryEvent.getListEventCategories(
            undefined,
            undefined,
            999
        )

        setCategories(listCategories.data)
    }

    const getPlaces = async () => {
        const listPlaces = await LocationService.getListLocation(
            undefined,
            undefined,
            999
        )
        setPlaces(listPlaces.data)
    }

    const optionsCategories = categories.map((item: any) => ({
        value: item.id,
        label: item.name,
    }))

    const optionsPlaces = places.map((item: any) => ({
        value: item.id,
        label: item.name,
    }))

    const handleChangeCategories = (value: string) => {
        setCategoriesId([value])
    }

    const handleChangePlaces = (value: string) => {
        setPlaceId(+value)
    }

    useEffect(() => {
        if (!showXLSModal) {
            getList().then()
            getCategory().then()
            getPlaces().then()
        }
        setRemovedStatus(false)
    }, [categoriesId, removedStatus, activePage, showXLSModal, placId])

    /**
     * публикация
     */
    const onPublish = async (id: number) => {
        const response = await Event.publishEvent(id)
        if (response) {
            setList(
                produce((draft) => {
                    const find = draft.filter((item) => item.id === id)[0]
                    find.published = 1
                })
            )
        }
    }

    /**
     * снятие с публикации
     */
    const onUnPublish = async (id: number) => {
        const response = await Event.unPublishEvent(id)
        if (response) {
            setList(
                produce((draft) => {
                    const find = draft.filter((item) => item.id === id)[0]
                    find.published = 0
                })
            )
        }
    }

    const viewFeedBacks = useCallback((id: number) => {
        nav.push(`${routes.feedbacksEvents}?eventID=${id}`)
    }, [])

    return (
        <ListHolder
            currentPage={activePage}
            totalCount={totalCount}
            onPageChange={(page) => dispatch(setEventPage(page))}
            onPageShow={setOnPage}
        >
            <div className={styles.top}>
                <div>
                    <Title level={2}>Список событий</Title>
                </div>

                <div>
                    <Select
                        defaultValue="Выберите категорию"
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                        style={{ width: 200, marginRight: 15 }}
                        onChange={handleChangeCategories}
                        options={[
                            { value: '', label: 'Все категории' },
                            ...optionsCategories,
                        ]}
                    />

                    <Select
                        defaultValue="Выберите место"
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                        style={{ width: 300 }}
                        onChange={handleChangePlaces}
                        options={[
                            { value: '', label: 'Все места' },
                            ...optionsPlaces,
                        ]}
                    />
                </div>

                <div className={styles.countEventsBlock}>
                    <div>Всего событий:{allEvents}</div>
                    <div className={styles.rightEventCoutBlock}>
                        <span>Опубликованно:{publicEvents}</span>
                        <span>Не опубликованно:{unPublicCount}</span>
                    </div>
                </div>

                <Button onClick={() => setShowXLSModal(true)}>
                    Загрузить XLS
                </Button>
            </div>
            <List
                className={styles.itemsWrapper}
                itemLayout="horizontal"
                dataSource={list}
                renderItem={(item) => (
                    <>
                        <List.Item className={styles.item}>
                            <ListItem
                                withFeedback
                                photoPath={
                                    !!item.new_photos?.length
                                        ? getPrewiewPhoto(item.new_photos)
                                        : item.previews[0]?.url || ''
                                }
                                name={item.name}
                                description={item.description}
                                onFeedbackClick={() => viewFeedBacks(item.id)}
                                onPublish={() => onPublish(item.id)}
                                onUnPublish={() => onUnPublish(item.id)}
                                onChange={() =>
                                    nav.push(
                                        `${routes.create_events}?articleID=${item.id}`
                                    )
                                }
                                onDelete={() => removeCategory(item.id)}
                                isPublish={!!item.published}
                            />
                        </List.Item>
                    </>
                )}
            />
            <XLSModal
                status={showXLSModal}
                onClose={() => setShowXLSModal(false)}
            />
        </ListHolder>
    )
}

export default ListEventCategories
