import 'moment/locale/ru'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import Title from 'antd/lib/typography/Title'
import moment, { Moment } from 'moment'
import produce from 'immer'
import style from './CreateLocation.module.scss'
import { Alert, Button, Checkbox, Input, TimePicker, message } from 'antd'
import { Select } from 'antd'
import { List } from 'antd'
import { useHistory } from 'react-router-dom'
import { routes } from '../../../constants/config'
import {
    DOMAIN,
    locationPhotoSizes,
    PhotoGaleryType,
} from '../../../constants/constants'
import { validMail } from '../../../constants/helper'
import { useAppSelector } from '../../../hooks/store'
import { Location as LocationService } from '../../../services/Location'
import {
    LocationTypeResponse,
    LocationTypes,
} from '../../../services/LocationTypes'
import { SocialNetwork } from '../../../services/SocialNetwork'
import {
    IPhotoArea,
    PhotoPath,
    PhotoResponseType,
    PhotoType,
    SocialNetworks,
    TextEditorType,
} from '../../../types/types'
import { SaveBtnBlock } from '../../simples/SaveBtnBlock'
import { TitleAndDefinition } from '../../simples/TitleAndDefinition'
import { PhotoGallery } from '../PhotoGallery'
import { TextEditor } from '../TextEditor'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { PhotoArea } from '../PhotoArea'
import { isChecked, isMainPhoto } from '../../../utils/photoEditor'
import { LoadFiles } from '../../../services/FileService'

const { Option } = Select
const { RangePicker } = TimePicker

export type SocialNetworkForBD = {
    type: number | undefined
    link: string
}

export type ContactsForBD = {
    name: string
    value: string
}

export enum SectionType {
    CONTACT = 1,
    SOCIAL_NETWORK = 2,
}

type Props = {}

const CreateLocation: FC<Props> = () => {
    const cityList = useAppSelector((state) => state.storage.cities)
    const locationID = useHistory().location.search?.split('=')[1]
    const nav = useHistory()

    const [publish, setPublish] = useState(false)
    const [typeId, setTypeId] = useState<number | undefined>()
    const [name, setName] = useState<string>('')
    const [address, setAddress] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [url, setUrl] = useState<string>('')
    const [longitude, setLongitude] = useState<number>()
    const [latitude, setLatitude] = useState<number>()
    const [pictureData, setPictureData] = useState<PhotoResponseType[]>([])
    const [schedule, setSchedule] = useState<Array<Array<Moment> | null>>([])
    const [socialNetworks, setSocialNetworks] = useState<SocialNetworks[]>([])
    const [search, setSearch] = useState<string>('')
    const [cityId, setCityId] = useState<number>()
    const [newphotos, setNewPhotos] = useState<IPhotoArea[]>([])
    const [isTop, setIsTop] = useState<boolean>(false)
    const [isError, setError] = useState(false)
    const [createLocation_ID, setCreateLocation_ID] = useState<number>(
        locationID ? +locationID : 0
    )
    const [contacts, setContacts] = useState<ContactsForBD[]>([
        { name: '', value: '' },
    ])
    const [locationTypeList, setLocationTypeList] = useState<
        LocationTypeResponse[]
    >([])
    const [content, setContent] = useState<Array<TextEditorType>>([
        {
            id: new Date().getTime(),
            text: '',
            photos: [],
        },
    ])
    const [socialNetwork, setSocialNetwork] = useState<SocialNetworks[]>([
        {
            name: '',
            link: '',
            photo: {} as PhotoPath,
        },
    ])
    const [order, setOrder] = useState<string>('0')

    const week = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС']
    const format = 'HH:mm'

    /**
     *  опции для выбора места
     */
    const optionsPlaceList = useMemo(() => {
        return cityList.map((item) => {
            if (item.name.includes(search)) {
                return (
                    <Option key={item.id} value={item.id}>
                        {item.name}
                    </Option>
                )
            }
        })
    }, [cityList, search])

    const getMomentRange = (arr: Array<Array<string>>) => {
        let momentArr: Array<Moment[]> = []
        for (let i = 0; i < arr.length; i++) {
            if (arr && !!arr[i]) {
                let start = moment(arr[i][0])
                let end = moment(arr[i][1])
                momentArr.push([start, end])
            } else {
                momentArr.push([])
            }
        }

        return momentArr
    }
    /**
     * получаем мероприятие по id
     */
    const getLocation = useCallback(async () => {
        const response = await LocationService.getOneLocation(+locationID)
        setName(response.name)
        setDescription(response.description)
        setPictureData(response.photos)
        setSocialNetwork(
            response.socialNetworks.length > 0
                ? response.socialNetworks
                : [
                      {
                          name: '',
                          link: '',
                          photo: {} as PhotoPath,
                      },
                  ]
        )

        setSchedule(getMomentRange(response.schedule))
        setAddress(response.address)
        setUrl(response.url)
        setContacts(response.contacts)
        setLatitude(+response.latitude)
        setLongitude(+response.longitude)
        setPhoneNumber(response.phoneNumber || '')
        setEmail(response.email)
        setTypeId(response.typeId)
        setIsTop(!!response.isTop)
        setContent(response.content)
        setCityId(response.cityId)
        setOrder(response.order || '')

        if (response?.new_photos && !!response.new_photos.length) {
            setNewPhotos((prev) => [
                ...prev,
                ...response.new_photos.map((obj) => ({
                    ...obj,
                    url: DOMAIN + obj.photo_url,
                })),
            ])
        }
    }, [])

    useEffect(() => {
        if (!!locationID) {
            getLocation().then()
        } else {
            setTypeId(undefined)
            setName('')
            setOrder('')
            setAddress('')
            setContacts([{ name: '', value: '' }])
            setDescription('')
            setPhoneNumber('')
            setEmail('')
            setUrl('')
            setLongitude(undefined)
            setLatitude(undefined)
            setPictureData([])
            setSchedule([])
            setSocialNetwork([
                {
                    name: '',
                    link: '',
                    photo: {} as PhotoPath,
                    socialId: 0,
                },
            ])
            setContent([
                {
                    id: new Date().getTime(),
                    text: '',
                    photos: [],
                },
            ])
            createEvent()
        }
        getTypeSocialNetwork()
        getTypeLocationList().then()
    }, [locationID])

    /**
     *  опции для выбора категории
     */
    const optionsList = useMemo(() => {
        return locationTypeList.map((item) => {
            return {
                label: item.name,
                value: item.id,
            }
        })
    }, [locationTypeList])

    /**
     *  опции для выбора категории
     */
    const socialOptions: any = useMemo(() => {
        return socialNetworks.map((item) => {
            return {
                label: item.name,
                value: item.id,
            }
        })
    }, [socialNetworks])

    const onSubmit = async () => {
        if (
            typeId === undefined ||
            name.trim() === '' ||
            address.trim() === '' ||
            contacts.length === 0 ||
            description.trim() === '' ||
            longitude === undefined ||
            latitude === undefined
        ) {
            setError(true)
            return
        }
        if (email && !validMail(email)) {
            message.error('Email не корректен!')
            return
        }
        if (isNaN(longitude) || isNaN(latitude)) {
            message.error('Ширина и Высота должны должны быть числами')
            setLongitude(undefined)
            setLatitude(undefined)
            return
        }

        const date = new Date()

        let data = {
            typeId,
            name,
            description,
            address,
            phoneNumber,
            email,
            contacts: JSON.stringify(contacts),
            url,
            longitude,
            new_photos: newphotos.map((photo) => ({
                photo_id: photo.photo_id,
                type: photo.type,
                is_main: photo.is_main,
            })),
            latitude,
            //так как расписание может быть не подряд нужно передать null чтобы оно не сбилось
            schedule: JSON.stringify(
                schedule.map((i) => {
                    if (i && i.length > 0) {
                        return [
                            moment({
                                year: moment(date).year(),
                                month: moment(date).month(),
                                day: moment(date).date(),
                                hour: moment(i[0]).hour(),
                                minute: moment(i[0]).minute(),
                            }).format(),
                            moment({
                                year: moment(date).year(),
                                month: moment(date).month(),
                                day: moment(date).date(),
                                hour: moment(i[1]).hour(),
                                minute: moment(i[1]).minute(),
                            }).format(),
                        ]
                    } else {
                        return null
                    }
                })
            ),
            socialNetworks: correctFormat(socialNetwork),
            content: JSON.stringify(content),
            cityId,
            order: order ? order : '0',
        }

        const response = await LocationService.updateLocation(
            createLocation_ID,
            data
        )

        if (response.success) {
            setTypeId(undefined)
            setName('')
            setAddress('')
            setContacts([{ name: '', value: '' }])
            setDescription('')
            setPhoneNumber('')
            setEmail('')
            setUrl('')
            setLongitude(undefined)
            setLatitude(undefined)
            setPictureData([])
            setSchedule([])
            setSocialNetwork([
                {
                    name: '',
                    link: '',
                    photo: {} as PhotoPath,
                    socialId: 0,
                },
            ])
            setContent([
                {
                    id: new Date().getTime(),
                    text: '',
                    photos: [],
                },
            ])
            setCityId(undefined)

            if (publish) {
                const responsePublish = await LocationService.publishLocation(
                    createLocation_ID
                )

                if (!responsePublish) {
                    message.warning('Локация создана, но не опубликована ')
                }
            } else {
                const responseUnPublish =
                    await LocationService.unPublishLocation(createLocation_ID)
                if (responseUnPublish) {
                    message.warning('Локация создана, но не опубликована')
                }
            }

            if (isTop) {
                try {
                    const response = await LocationService.toTopPlace(
                        createLocation_ID
                    )
                } catch (error) {}
            }

            nav.push(routes.get_location_list)
        } else {
            message.error(
                (response.errors?.email as any) ?? 'Что-то пошло не так'
            )
        }
    }

    const loadingPhoto = async (photo: any, resourse: PhotoGaleryType) => {
        const data = {
            file: [photo],
        }

        let response: any

        if (resourse === PhotoGaleryType.MAIN) {
            response = await LocationService.uploadPhoto(
                createLocation_ID,
                data
            )

            if (response.success) {
                setPictureData((prev) => [...prev, response.data?.events.data])
                return response.data?.events.data
            } else {
                message.error('Произошла ошибка при загрузке фотографии')
            }
        }

        if (resourse === PhotoGaleryType.PHOTO_LIST) {
            const { image, type, is_main } = photo

            const isMobile = isChecked(type)
            const isEmtyType = !!newphotos
                ? newphotos.find((photo) => photo.type === type)
                : false

            response = await LoadFiles(image)

            if (response.success) {
                const { data } = response
                if (!isMobile && isEmtyType) {
                    setNewPhotos((prev) =>
                        prev.map((item) =>
                            item.type === type
                                ? {
                                      url: DOMAIN + data.path,
                                      type,
                                      photo_id: data.id,
                                      is_main,
                                  }
                                : item
                        )
                    )
                } else {
                    if (!!newphotos) {
                        setNewPhotos((prev) =>
                            isMainPhoto([
                                ...prev,
                                {
                                    url: DOMAIN + data.path,
                                    type,
                                    photo_id: data.id,
                                    is_main,
                                },
                            ])
                        )
                    } else {
                        setNewPhotos(
                            isMainPhoto([
                                {
                                    url: DOMAIN + data.path,
                                    type,
                                    photo_id: data.id,
                                    is_main,
                                },
                            ])
                        )
                    }
                }
            } else {
                message.error('Произошла ошибка при загрузке фотографии')
            }
        }
    }

    const correctFormat = (data: SocialNetworks[]) => {
        const socials = data.map((item) => {
            if (item.socialId) {
                return {
                    type: item.socialId,
                    link: item.link,
                }
            }
        })
        return JSON.stringify(socials)
    }

    useEffect(() => {
        setTimeout(() => {
            setError(false)
        }, 5000)
    }, [isError])

    const createEvent = async () => {
        const response = await LocationService.createLocation()

        setCreateLocation_ID(response.id)
    }
    const getTypeSocialNetwork = async () => {
        const list = await SocialNetwork.getListSocialNetwork(1, 10, 100)
        setSocialNetworks(list.data)
        return list
    }

    const getTypeLocationList = async () => {
        const list = await LocationTypes.getListLocationTypes(1, 10, 1000)

        setLocationTypeList(list.data)
        return list
    }

    const styleError = {
        border: isError ? '1px solid red' : '0px solid red',
    }

    const addNewLink = () => {
        setSocialNetwork(
            produce((draft) => {
                draft.push({
                    name: '',
                    link: '',
                    photo: {} as PhotoPath,
                })
            })
        )
    }

    const closeInput = (index: any, type: SectionType) => {
        if (type === SectionType.CONTACT) {
            if (contacts.length === 1) {
                setContacts(
                    produce((draft) => {
                        draft[index] = { name: '', value: '' }
                    })
                )
                return
            }
            setContacts(
                produce((draft) => {
                    draft.splice(index, 1)
                })
            )
        } else {
            if (socialNetwork.length === 1) {
                setSocialNetwork(
                    produce((draft) => {
                        draft[index] = {
                            name: '',
                            link: '',
                            photo: {} as PhotoPath,
                        }
                    })
                )
                return
            }
            setSocialNetwork(
                produce((draft) => {
                    draft.splice(index, 1)
                })
            )
        }
    }

    const handleChangeOption = (value: number, index: number) => {
        setSocialNetwork(
            produce((draft) => {
                draft[index].socialId = value
            })
        )
    }

    const addNewContact = () => {
        setContacts(
            produce((draft) => {
                draft.push({ name: '', value: '' })
            })
        )
    }

    const deletePhoto = async (id: number, resourse?: PhotoGaleryType) => {
        let result: boolean

        if (resourse === PhotoGaleryType.PHOTO_LIST) {
            setNewPhotos((prev) =>
                isMainPhoto(prev.filter((item) => item.photo_id !== id))
            )
        } else {
            result = await LocationService.removePhoto(id)

            if (result) {
                setPictureData((prev) => {
                    return prev.filter((item) => item.id !== id)
                })
                return result
            } else {
                message.error(
                    'Что-то пошло не так, не получилось удалить фотографию'
                )
                return false
            }
        }
    }

    const onChangeSchedule = (rangeTime: any[] | null, index: number) => {
        setSchedule(
            produce((draft) => {
                draft[index] = rangeTime ? rangeTime : null
            })
        )
    }

    const onChange = (id: number) => {
        setCityId(id)
        setSearch('')
    }

    const handleSelectMainPhoto = (id: number, type: PhotoType) => {
        setNewPhotos((prev) =>
            prev.map((photo) =>
                photo.photo_id === id && photo.type === type
                    ? { ...photo, is_main: true }
                    : photo.photo_id !== id && photo.type === type
                    ? { ...photo, is_main: false }
                    : photo
            )
        )
    }

    return (
        <>
            <Title level={2}>
                {locationID ? 'Редактирование места' : 'Создание места'}
            </Title>
            {isError ? (
                <Alert
                    className={style.alert}
                    message="Все поля обязательны к заполнению"
                    type="error"
                />
            ) : null}

            <div className={style.selectWrapper} style={styleError}>
                <h2 className={style.title}>Категория</h2>
                <Select
                    options={optionsList}
                    style={{ width: '100%' }}
                    value={typeId}
                    placeholder="Выбрать категорию локации"
                    onChange={(id: number) => setTypeId(id)}
                />
            </div>

            <TitleAndDefinition
                isError={isError}
                firstValue={name}
                secondValue={address}
                textAreaValue={description}
                firstTitle="Название локации"
                secondTitle="Адрес локации"
                textAreaTitle="Описание"
                firstPlaceholder={true}
                secondPlaceholder={true}
                textAreaPlaceholder={true}
                firstPlaceholderTitle="Введите название локации"
                secondPlaceholderTitle="Введите адрес"
                textAreaPlaceholderTitle="Введите описание"
                onChangeFirst={(value) => setName(value)}
                onChangeSecond={(value) => setAddress(value)}
                onChangeTextArea={(value) => setDescription(value)}
            />
            <div className={style.selectWrapper} style={styleError}>
                <h2 className={style.title}>Город</h2>
                <Select
                    showSearch
                    value={cityId}
                    style={{ width: '100%' }}
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    filterOption={false}
                    onSearch={setSearch}
                    onChange={onChange}
                    notFoundContent={null}
                >
                    {optionsPlaceList}
                </Select>
            </div>
            <TextEditor
                value={
                    content || [
                        {
                            id: new Date().getTime(),
                            text: '',
                            photos: [],
                        },
                    ]
                }
                onChange={(data: Array<TextEditorType>) => setContent(data)}
                onDeletePhoto={async (id: number) => await deletePhoto(id)}
                onUploadPhotos={async (data) =>
                    await loadingPhoto(data, PhotoGaleryType.MAIN)
                }
            />
            <div className={style.seanceBlock}>
                <h2 className={style.title}>Контакты</h2>
                <div className={style.linksWrapper}>
                    <Button
                        style={{ borderRadius: '50%' }}
                        onClick={addNewContact}
                        type="primary"
                        className={style.btn}
                        icon={<PlusOutlined />}
                    />
                    <List
                        style={{ border: 'none', width: '100%' }}
                        size="default"
                        bordered
                        dataSource={contacts}
                        renderItem={(item, index) => {
                            return (
                                <div className={style.inputFactsWrapper}>
                                    <List.Item
                                        style={{
                                            width: '100%',
                                            justifyContent: 'flex-end',
                                            padding: 0,
                                            margin: 0,
                                        }}
                                    >
                                        <TitleAndDefinition
                                            isSeanseStyle={true}
                                            firstValue={contacts[index].name}
                                            firstPlaceholderTitle="Название раздела (номер, почта, ссылка)"
                                            onChangeFirst={(value) =>
                                                setContacts(
                                                    produce((draft) => {
                                                        draft[index].name =
                                                            value
                                                    })
                                                )
                                            }
                                        />
                                        <TitleAndDefinition
                                            isSeanseStyle={true}
                                            firstValue={contacts[index].value}
                                            firstPlaceholderTitle="Содержание"
                                            onChangeFirst={(value) =>
                                                setContacts(
                                                    produce((draft) => {
                                                        draft[index].value =
                                                            value
                                                    })
                                                )
                                            }
                                        />
                                        <CloseOutlined
                                            onClick={() =>
                                                closeInput(
                                                    index,
                                                    SectionType.CONTACT
                                                )
                                            }
                                            className={style.closeIcon}
                                        />
                                    </List.Item>
                                </div>
                            )
                        }}
                    />
                </div>
            </div>
            <TitleAndDefinition
                isError={isError}
                firstValue={latitude}
                secondValue={longitude}
                firstTitle="Широта"
                secondTitle="Долгота"
                firstPlaceholder={true}
                secondPlaceholder={true}
                firstPlaceholderTitle="Введите широту"
                secondPlaceholderTitle="Введите долготу"
                onChangeFirst={(value) => {
                    setLatitude(value)
                }}
                onChangeSecond={(value) => {
                    setLongitude(value)
                }}
            />
            <TitleAndDefinition
                firstValue={email}
                secondValue={url}
                thirdValue={phoneNumber}
                firstTitle="E-mail"
                secondTitle="URL-адрес"
                thirdTitle="Номер телефона"
                firstPlaceholder={true}
                secondPlaceholder={true}
                thirdPlaceholder={true}
                firstPlaceholderTitle="Введите почту"
                secondPlaceholderTitle="Введите URL-адрес"
                thirdPlaceholderTitle="Ввдите номер телефона"
                onChangeFirst={(value) => setEmail(value)}
                onChangeSecond={(value) => setUrl(value)}
                onChangeThird={(value) => setPhoneNumber(value)}
            />

            {!!pictureData.length && (
                <div style={styleError}>
                    <PhotoGallery
                        title={'Фото события'}
                        data={pictureData}
                        onUpload={(value) =>
                            loadingPhoto(value, PhotoGaleryType.MAIN)
                        }
                        isCrop={true}
                        onDelete={(id) => deletePhoto(id)}
                    />
                </div>
            )}

            <PhotoArea
                data={newphotos}
                onSubmit={(value) =>
                    loadingPhoto(value, PhotoGaleryType.PHOTO_LIST)
                }
                sizes={locationPhotoSizes}
                onSelectMain={handleSelectMainPhoto}
                onDelete={(id) => deletePhoto(id, PhotoGaleryType.PHOTO_LIST)}
            />

            {
                <div className={style.seanceBlock}>
                    <h2 className={style.title}>Расписание</h2>
                    <List
                        size="small"
                        bordered
                        dataSource={week}
                        renderItem={(item, index) => {
                            return (
                                <div>
                                    <List.Item
                                        style={{ justifyContent: 'flex-start' }}
                                    >
                                        <span>{item}:</span>
                                        <RangePicker
                                            style={{ width: '310px' }}
                                            bordered={false}
                                            showNow={false}
                                            format={format}
                                            placeholder={[
                                                'Время начала',
                                                'Время окончания',
                                            ]}
                                            // @ts-ignore
                                            value={schedule[index]}
                                            onChange={(values: any) =>
                                                onChangeSchedule(values, index)
                                            }
                                        />
                                    </List.Item>
                                </div>
                            )
                        }}
                    />
                </div>
            }
            <div className={style.seanceBlock}>
                <h2 className={style.title}>Ссылки на социальные сети</h2>
                <div className={style.linksWrapper}>
                    <Button
                        style={{ borderRadius: '50%' }}
                        onClick={addNewLink}
                        type="primary"
                        className={style.btn}
                        icon={<PlusOutlined />}
                    />
                    <List
                        style={{ border: 'none' }}
                        size="default"
                        bordered
                        dataSource={socialNetwork}
                        renderItem={(item, index) => {
                            return (
                                <div className={style.inputLinkWrapper}>
                                    <List.Item
                                        style={{
                                            justifyContent: 'flex-start',
                                            alignItems: 'baseline',
                                            padding: 0,
                                            margin: 0,
                                        }}
                                    >
                                        {!!(socialOptions.length > 0) && (
                                            <Select
                                                placeholder={'Выберите сеть'}
                                                options={socialOptions}
                                                value={
                                                    !!item.socialId
                                                        ? item.socialId
                                                        : undefined
                                                }
                                                style={{ width: 350 }}
                                                onChange={(value) =>
                                                    handleChangeOption(
                                                        value,
                                                        index
                                                    )
                                                }
                                            />
                                        )}
                                        <TitleAndDefinition
                                            isSeanseStyle={true}
                                            firstValue={
                                                socialNetwork[index].link
                                            }
                                            firstPlaceholderTitle="Ссылка"
                                            onChangeFirst={(value) =>
                                                setSocialNetwork(
                                                    produce((draft) => {
                                                        draft[index].link =
                                                            value
                                                    })
                                                )
                                            }
                                        />
                                    </List.Item>
                                    <CloseOutlined
                                        onClick={() =>
                                            closeInput(
                                                index,
                                                SectionType.SOCIAL_NETWORK
                                            )
                                        }
                                        className={style.closeIcon}
                                    />
                                </div>
                            )
                        }}
                    />
                </div>
            </div>

            <div className={style.orderBlock}>
                <h2 className={style.title}>Порядок</h2>
                <Input
                    placeholder={'Порядок'}
                    type="number"
                    inputMode="numeric"
                    onChange={(e) => {
                        // @ts-ignore
                        setOrder(e.target.value)
                    }}
                    value={order}
                />
            </div>

            <div className={style.topPlaceCheckbox}>
                <Checkbox
                    checked={isTop}
                    onChange={(e) => {
                        setIsTop(e.target.checked)
                    }}
                >
                    Поместить в топ мест
                </Checkbox>
            </div>
            <SaveBtnBlock
                onSave={onSubmit}
                onPublish={setPublish}
                isPublish={publish}
                hasID={!!locationID}
            />
        </>
    )
}

export default CreateLocation
