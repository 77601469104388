import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { App } from './components'
import './styles/app.global.scss'
import { store } from './store'

/* eslint-disable no-console */
console.log('REACT_APP_ORIGIN', process.env.REACT_APP_ORIGIN)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);


