import api from '../utils/api'
import { endpoints } from '../constants/constants'

type ResponceType = {
    success: boolean
    data: {
        id: number
        path: string
        name: string
    }
}

export async function LoadFiles(file: File) {
    const formData = new FormData()
    formData.append('file', file)

    const { data } = await api.post<ResponceType>(endpoints.loadFile, formData)
    return data
}
