import { Modal } from 'antd'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { TextField } from '../../simples/TextField'
import styles from './TextEditor.module.scss'

type Props = {
    link: string
    onChange: (link: string) => void
    isVisible: boolean
    onClose: () => void
}

const AddLinkModal: FC<Props> = ({ link, onChange, isVisible, onClose }) => {

    const [currentLink, setCurrentLink] = useState<string>(link)
    const [currentText, setCurrentText] = useState<string>(link)

    const parseLink = (e: string) => {
        const hrefIndexStart = e.indexOf('"') + 1
        const hrefIndexEnd = e.indexOf('"', hrefIndexStart)
        const href = e.slice(hrefIndexStart, hrefIndexEnd)
        setCurrentLink(href)

        const textLinkIndexStart = e.indexOf('>') + 1
        const textLinkIndexEnd = e.indexOf('<', 2)

        const textLink = e.slice(textLinkIndexStart, textLinkIndexEnd)
        setCurrentText(textLink)

    }

    useEffect(() => {
        parseLink(link)
    }, [link])

    const onHandleAddLink = () => {
        onChange(`<a href="${currentLink}">${currentText}</a>`)
    }
    const onChangeTextLink = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentText(e.target.value)
    }
    const onChangeLink = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentLink(e.target.value)
    }
    return (
        <Modal
            visible={isVisible}
            onOk={onHandleAddLink}
            onCancel={onClose}
            className={styles.addLinkModal}
            okText={'Добавить'}
            cancelText={'Отмена'}
        >
            <div className={styles.inputHolder}>
                <TextField
                    label={'Текст ссылки'}
                    onChange={onChangeTextLink}
                    value={currentText}
                />
            </div>
            <div className={styles.inputHolder}>
                <TextField
                    label={'Адрес ссылки'}
                    onChange={onChangeLink}
                    value={currentLink}
                />
            </div>

        </Modal>
    )
}

export default AddLinkModal