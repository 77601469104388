import {
    PhotoResponseType,
    EventResponseType,
    DataUploadPhoto,
    UploadPhoto,
    RemovePhotoResponseType,
    CreateEventResponseType,
    MetaType,
    PhotoType,
} from './../types/types'
import { getFormData, getFormDataEvents } from './../constants/helper'
import { ErrorsTypes } from '../utils/api'
import api from '../utils/api'
import { endpoints } from '../constants/constants'
import { route } from '../utils/route'

export type DataCreateEventType = {
    placeId: number | undefined
    name: string
    description: string
    content?: string
    priceMin?: number
    priceMax?: number
    youtubeLink?: string
    buyTicketLink?: string
    startAt: string
    endAt: string
    seances: string
    interestingFacts?: string
    pushkinCard?: number
    new_photos?: { photo_id: number; type: PhotoType; is_main: boolean }[]
}

export type EventCategory = {
    success: boolean
    errors?: ErrorsTypes
    data?: {
        events: EventResponseType
    }
}

export type EventResponse = {
    data: EventResponseType
    baseUrl: string
    errors: string[]
    systemVersion: number
    needUpdate: number
    success: boolean
    meta: {
        totalCount: number
    }
}

export type GetListEventResponse = {
    data: EventResponseType[]
    baseUrl: string
    errors: string[]
    systemVersion: number
    needUpdate: number
    success: boolean
    meta: MetaType
}

/**
 * Сервис для работы с событиями
 */
export class Event {
    /**
     * Метод для получения id создаваемой статьи
     */
    static async createEvent(): Promise<CreateEventResponseType> {
        const response = await api.get<EventResponse>(endpoints.createEvent)

        return response.data.data
    }

    /**
     * Метод для для публикации события
     */
    static async unPublishEvent(id: number): Promise<boolean> {
        const response = await api.get<EventResponse>(
            `${endpoints.unPublishEvent}/${id}`
        )

        return response.data.success
    }

    /**
     * Метод для отмены публикации события
     */
    static async publishEvent(id: number): Promise<boolean> {
        const response = await api.get<EventResponse>(
            `${endpoints.publishEvent}/${id}`
        )

        return response.data.success
    }

    /**
     * Метод для получения списка событий
     */

    static async getListEvents(
        page?: number,
        onPage?: number,
        limit: number = 10,
        categoriesId: Array<number> = [],
        places?: number
    ): Promise<GetListEventResponse> {
        const offset = (page ? page - 1 : 0) * (onPage ? onPage : 10)
        const lim = `&limit=${limit}`
        const category = `&categories=${categoriesId}`
        const place = places ? `&places=${places}` : ''
        const response = await api.get<GetListEventResponse>(
            `${endpoints.listEvents}?offset=${offset}${lim}${category}${place}`
        )

        return response.data
    }
    /**
     * Метод для получения списка событий
     */

    static async getListEventsSearch(data: {
        page?: number
        onPage?: number
        limit?: number
        search?: string
    }): Promise<EventResponseType[]> {
        let query = []
        const sData: any = data
        for (let key in sData) {
            if (sData[key]) {
                query.push(`${key}=${sData[key]}`)
            }
        }
        const response = await api.get<GetListEventResponse>(
            `${endpoints.listEvents}?${query.join('&')}`
        )

        return response.data.data
    }
    /**
     * Метод для получения одного события
     */

    static async getEvent(id: number): Promise<EventResponseType> {
        const response = await api.get(route(endpoints.getEvent, { id }))

        return response.data.data
    }

    /**
     * Метод для отправки фотографий контента
     */

    static async uploadPhoto(
        id: number,
        data: DataUploadPhoto
    ): Promise<UploadPhoto> {
        const result: UploadPhoto = {
            success: false,
        }

        try {
            const response = await api.post<PhotoResponseType>(
                `${endpoints.uploadPhotoEvent}/${id}`,
                getFormData(data, true)
            )

            result.success = true
            result.data = {
                events: response.data,
            }
        } catch (error: any) {
            console.log('error', error.response)
        }
        return result
    }

    /**
     * Метод для отправки фотографий превью
     */

    static async uploadPreviewPhoto(
        id: number,
        data: DataUploadPhoto
    ): Promise<UploadPhoto> {
        const result: UploadPhoto = {
            success: false,
        }

        try {
            const response = await api.post<PhotoResponseType>(
                `${endpoints.uploadPreviewEvent}/${id}`,
                getFormData(data)
            )

            result.success = true
            result.data = {
                events: response.data,
            }
        } catch (error: any) {
            console.log('error', error.response)
        }
        return result
    }

    /**
     * Метод для удаления фотографии контента
     */

    static async removePhoto(id: number): Promise<boolean> {
        const response = await api.delete<RemovePhotoResponseType>(
            `${endpoints.removePhotoEvent}/${id}`
        )

        return response.data.success
    }

    /**
     * Метод для удаления фотографии превью
     */

    static async removePreviewPhoto(id: number): Promise<boolean> {
        const response = await api.delete<RemovePhotoResponseType>(
            `${endpoints.removePreviewEvent}/${id}`
        )

        return response.data.success
    }

    /**
     * Запрос на обновление события
     *
     * @param categoryId id места где будет ивент
     * @param placeId id места где будет ивент
     * @param name  Название события
     * @param description  Описание события
     * @param content Содержание события
     * @param price цена на ивент
     * @param youtubeLink ссылка на ютуб
     * @param buyTicketLink ссылка на билеты
     * @param startAt date format дата начала
     * @param endAt date format дата окончания
     * @param seances JSON интересные факты
     * @param interestingFacts JSON интересные факты
     */

    static async updateEvent(
        id: number,
        data: DataCreateEventType
    ): Promise<EventCategory> {
        const result: EventCategory = {
            success: false,
        }

        try {
            const response = await api.post<EventResponseType>(
                `${endpoints.updateEvent}/${id}`,
                getFormDataEvents(data)
            )

            result.success = true
            result.data = {
                events: response.data,
            }
        } catch (error: any) {
            console.log('error', error.response)
        }
        return result
    }

    /**
     * Метод удаления статьи по id
     */

    static async deleteEvent(id: number): Promise<boolean> {
        const response = await api.delete<GetListEventResponse>(
            `${endpoints.deleteEvent}/${id}`
        )

        return response.data.success
    }

    /**
     * EXPORT XLS
     */

    static async xlsEvents(fd: FormData) {
        const response = await api.post(endpoints.xlsEvents, fd)

        return response.data
    }
}
