import { getFormData } from './../constants/helper';
import { EventResponseType, MetaType } from './../types/types';
import { ErrorsTypes } from '../utils/api'
import api from '../utils/api'
import { endpoints } from '../constants/constants'
import { CategoryResponseType } from '../types/types'


export type EventCategory = {
    success: boolean
    errors?: ErrorsTypes
    data?: {
        eventCategory: CategoryResponseType
    }
}
export type GetListResponse = {
    data: CategoryResponseType[],
    baseUrl: string,
    errors: any,
    systemVersion: number,
    needUpdate: number,
    success: boolean,
    meta: MetaType
}



/**
 * Сервис для работы с категориями статей
 */
export class CategoryEvent {

    /**
     * Метод для получения списка категорий событий
     */

    static async getListEventCategories(page?: number, onPage?: number, limit:number = 10): Promise<GetListResponse> {
        const offset = (page ? page-1 : 0) * (onPage ?  onPage : 10)
        const lim = `&limit=${limit}`
        const response = await api.get<GetListResponse>(`${endpoints.listEventCategories}?offset=${offset}${lim}`)
        return response.data
    } 

    /**
     * Запрос на создание новой категории  стаьи
     * @param name  Название категории статьи
     * @param description  Описание статьи
     */

    
    static async createEventCategory(
        name: string,
        description: string
    ): Promise<EventCategory> {
        const result: EventCategory = {
            success: false,
        }

        try {
            const fd = new FormData()
            fd.append("name", name)
            fd.append("description", description)
            const response = await api.post<CategoryResponseType>(
                endpoints.createEventCategory,
                fd
            )

            result.success = true
            result.data = {
                eventCategory: response.data,
            }

            
        } catch (error:any) {
            console.log('error', error.response);
          
        }
        return result

    }   

     /**
     * Метод удаления категории статьи по id
     */

      static async deleteEventCategory(id: number): Promise<boolean>{
        const response = await api.delete<GetListResponse>(`${endpoints.deleteEventCategory}/${id}`)

        return response.data.success
    }
    
    
     /**
     * Запрос на изменения уже существующей категории 
     * @param id  Индентификатор
     * @param name  Название категории статьи
     * @param description  Описание статьи
     */


      static async updateEventCategory(
        id: number,
        data: {name: string, description: string}
        ): Promise<EventCategory> {
        const result: EventCategory = {
            success: false,
        }

        try {
           
            const response = await api.post<CategoryResponseType>(
                `${endpoints.updateEventCategory}/${id}`,
                getFormData(data)
            )

            result.success = true
            result.data = {
                eventCategory: response.data,
            }

            
        } catch (error:any) {
            console.log('error', error.response);
          
        }
        return result

    } 


    

}