import { Alert, Checkbox, message } from 'antd'
import { FC, useCallback, useEffect, useState } from 'react'
import { TitleAndDefinition } from '../../simples/TitleAndDefinition'
import { Advertising as AdvertisingService } from './../../../services/Advertising'
import { AdvFileType, PhotoResponseType } from '../../../types/types'
import styles from './Advertising.module.scss'
import { useHistory } from 'react-router-dom'
import { PhotoGallery } from '../PhotoGallery'
import { SaveBtnBlock } from '../../simples/SaveBtnBlock'
import { routes } from '../../../constants/config'
import Title from 'antd/lib/typography/Title'

type Props = {}

const Advertising: FC<Props> = () => {
    const nav = useHistory()
    const advID = useHistory().location.search?.split('=')[1]

    const [createAd_ID, setCreateAd_ID] = useState<number>(advID ? +advID : 0)
    const [title, setTitle] = useState('')
    const [link, setLink] = useState('')
    const [description, setDescription] = useState('')
    const [pictureData, setPictureData] = useState<PhotoResponseType[]>([])
    const [pictureDataMobile, setPictureDataMobile] = useState<
        PhotoResponseType[]
    >([])
    const [isError, setError] = useState<boolean>(false)

    const [publish, setPublish] = useState<boolean>(false)

    const [typeSizeBanner, setTypeSizeBanner] = useState<number>(0)

    /**
     * получаем одно рекламное объявления
     * @returns
     */
    const getOneAdv = useCallback(async () => {
        try {
            const adv = await AdvertisingService.getOneAd(+advID)
            setTitle(adv.title)
            setLink(adv.link)
            setTypeSizeBanner(adv.type)
            setDescription(adv.description)
            setPictureData(adv.photos.filter((i) => i.type === AdvFileType.WEB))
            setPictureDataMobile(
                adv.photos.filter((i) => i.type === AdvFileType.MOBILE)
            )
            setPublish(!!publish)
        } catch (error) {}
    }, [advID, publish])

    /**
     * сохраняем рекламу
     * @returns
     */
    const onClickHandler = async () => {
        if (
            title.trim() === '' ||
            description.trim() === '' ||
            link.trim() === '' ||
            pictureData.length === 0
        ) {
            setError(true)
            return
        }

        const data = {
            title,
            description,
            link,
            type: typeSizeBanner,
        }

        try {
            const response = await AdvertisingService.updateAd(
                createAd_ID,
                data
            )

            console.log('data save', data)
            console.log('response save', response)

            if (response.success) {
                setTitle('')
                setLink('')
                setDescription('')
                setPictureData([])
                setPublish(false)

                if (publish) {
                    const responsePublish = await AdvertisingService.publishAdv(
                        createAd_ID
                    )

                    if (!responsePublish) {
                        message.warning(
                            'Рекламное объявление создано, но не опубликовано '
                        )
                    } else {
                        message.warning(
                            'Рекламное объявление создано и опубликовано '
                        )
                    }
                } else {
                    const responseUnPublish =
                        await AdvertisingService.unPublishAdv(createAd_ID)
                    if (responseUnPublish) {
                        message.warning(
                            'Рекламное объявление сохранено, но не опубликовано '
                        )
                    }
                }
            } else {
                message.error(
                    'Что-то пошло не так, не получилось создать рекламный пост'
                )
            }
        } catch (error) {
            message.error(
                'Что-то пошло не так, не получилось создать рекламный пост'
            )
        }

        nav.push(routes.get_ads)
    }

    /**
     *
     * @param photo загружаем фото
     */
    const loadingPhoto = async (photo: any, type: AdvFileType) => {
        const data = {
            file: [photo],
        }

        let response = await AdvertisingService.uploadPhoto(
            createAd_ID,
            type,
            data
        )
        if (response.success) {
            if (response.data.type === AdvFileType.WEB) {
                setPictureData((prev) => [...prev, response.data])
            } else {
                setPictureDataMobile((prev) => [...prev, response.data])
            }
        } else {
            message.error('Произошла ошибка при загрузке фотографии')
        }
    }

    const deletePhoto = async (id: number, type: AdvFileType) => {
        let result = await AdvertisingService.removePhoto(id)

        if (result) {
            if (type === AdvFileType.WEB) {
                setPictureData((prev) => {
                    return prev.filter((item) => item.id !== id)
                })
            } else {
                setPictureDataMobile((prev) => {
                    return prev.filter((item) => item.id !== id)
                })
            }
        } else {
            message.error(
                'Что-то пошло не так, не получилось удалить фотографию'
            )
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setError(false)
        }, 5000)
    }, [isError])

    /**
     * если редактируем, то получаем рекламу по id из url
     * если нет ID то создаем новую рекламу
     */
    useEffect(() => {
        if (advID) {
            getOneAdv().then()
        } else {
            createAd().then()
        }
    }, [advID])

    /**
     * создаем новую рекламу и записываем ее id в стэйт
     */
    const createAd = async () => {
        const response = await AdvertisingService.createAd()
        setCreateAd_ID(response.id)
    }

    const styleError = {
        border: isError ? '1px solid red' : '0px solid red',
    }

    return (
        <div className={styles.wrapper}>
            <Title level={2}>
                {advID ? 'Редактирование рекламы' : 'Создание рекламы'}
            </Title>
            {isError ? (
                <Alert
                    className={styles.alert}
                    message="Все поля обязательны к заполнению"
                    type="error"
                />
            ) : null}
            <TitleAndDefinition
                isError={isError}
                firstValue={title}
                secondValue={link}
                textAreaValue={description}
                firstTitle="Название"
                secondTitle="Ссылка на источник"
                textAreaTitle="Описание"
                firstPlaceholder={true}
                secondPlaceholder={true}
                textAreaPlaceholder={true}
                firstPlaceholderTitle="Напишите заголовок"
                secondPlaceholderTitle="Bведите ссылку на источник"
                textAreaPlaceholderTitle="Напишите описание"
                onChangeFirst={(value) => setTitle(value)}
                onChangeSecond={(value) => setLink(value)}
                onChangeTextArea={(value) => setDescription(value)}
            />
            <div style={styleError}>
                <PhotoGallery
                    title={'Фото рекламной записи для WEB'}
                    data={pictureData}
                    onUpload={(value) => loadingPhoto(value, AdvFileType.WEB)}
                    onDelete={(id) => deletePhoto(id, AdvFileType.WEB)}
                />
            </div>
            <div style={styleError}>
                <PhotoGallery
                    title={'Фото рекламной записи для MOBILE'}
                    data={pictureDataMobile}
                    onUpload={(value) =>
                        loadingPhoto(value, AdvFileType.MOBILE)
                    }
                    onDelete={(id) => deletePhoto(id, AdvFileType.MOBILE)}
                />
            </div>

            <div className={styles.bannareTypes}>
                <Checkbox
                    checked={typeSizeBanner === 0}
                    onChange={(e) => {
                        setTypeSizeBanner(0)
                    }}
                >
                    Стандартный баннер
                </Checkbox>
                <Checkbox
                    checked={typeSizeBanner === 1}
                    onChange={(e) => {
                        setTypeSizeBanner(1)
                    }}
                >
                    Широкий баннер
                </Checkbox>
            </div>

            <SaveBtnBlock
                onSave={onClickHandler}
                onPublish={setPublish}
                isPublish={publish}
                hasID={!!advID}
            />
        </div>
    )
}

export default Advertising
