import { getFormData } from './../constants/helper';
import { ErrorsTypes } from '../utils/api'
import api from '../utils/api'
import { endpoints } from '../constants/constants'
import { MetaType } from '../types/types';


export type ArticleCategoryTypeResponse = {
    description: string,
    name: string,
    updatedAt: Date,
    createdAt: Date,
    id: number
  }

export type ArticleCategory = {
    success: boolean
    errors?: ErrorsTypes
    data?: {
        articleCategory: ArticleCategoryTypeResponse
    }
}
export type GetListResponse = {
    data: ArticleCategoryTypeResponse[],
    baseUrl: string,
    errors: any,
    systemVersion: number,
    needUpdate: number,
    success: boolean,
    meta: MetaType
}

/**
 * Сервис для работы с категориями статей
 */
export class CategoryArticle {
    /**
     * Запрос на создание новой категории  стаьи
     * @param name  Название категории статьи
     * @param description  Описание статьи
     */

    
    static async createNewCategory(
        name: string,
        description: string
    ): Promise<ArticleCategory> {
        const result: ArticleCategory = {
            success: false,
        }

        try {
            const fd = new FormData()
            fd.append("name", name)
            fd.append("description", description)
            const response = await api.post<ArticleCategoryTypeResponse>(
                endpoints.createArticleCategory,
                fd
            )

            result.success = true
            result.data = {
                articleCategory: response.data,
            }

            
        } catch (error:any) {
            console.log('error', error.response);
          
        }
        return result

    }   

     /**
     * Запрос на изменения уже существующей категории 
     * @param id  Индентификатор
     * @param name  Название категории статьи
     * @param description  Описание статьи
     */


    static async updateCategory(
        id: number,
        data: {name: string, description: string}
        ): Promise<ArticleCategory> {
        const result: ArticleCategory = {
            success: false,
        }

        try {
           
            const response = await api.post<ArticleCategoryTypeResponse>(
                `${endpoints.updateArticleCategory}/${id}`,
                getFormData(data)
            )

            result.success = true
            result.data = {
                articleCategory: response.data,
            }

            
        } catch (error:any) {
            console.log('error', error.response);
          
        }
        return result

    } 


     /**
     * Метод для получения списка категорий
     */


    static async getListArticleCategories(page?: number, onPage?: number, limit:number = 10): Promise<GetListResponse> {
        const offset = (page ? page-1 : 0) * (onPage ?  onPage : 10)
        const lim = `&limit=${limit}`
        const response = await api.get<GetListResponse>(`${endpoints.listArticleCategories}?offset=${offset}${lim}`)
        return response.data
    } 

     /**
     * Метод удаления категории статьи по id
     */

      static async deleteArticleCategory(id: number): Promise<boolean>{
        const response = await api.delete<GetListResponse>(`${endpoints.deleteArticleCategory}/${id}`)

        return response.data.success
    }




}