import { DeleteOutlined } from '@ant-design/icons'
import React, { FC } from 'react'
import styles from './PublishBlock.module.scss'

type Props = {
    onPublish: () => void
    onUnPublish: () => void
    onChange: () => void
    onDelete: () => void
    isPublish: boolean
    changeLabel?: string
}

/**
 *
 * @param onPublish
 * @param onUnPublish
 * @param onChange
 * @param onDelete
 * @param isPublish
 * @param changeLabel
 * @returns
 */

const PublishBlock: FC<Props> = ({
    onPublish,
    onUnPublish,
    onChange,
    onDelete,
    isPublish,
    changeLabel,
}) => {
    return (
        <div>
            <span
                className={styles.btn}
                onClick={() => (!isPublish ? onPublish() : onUnPublish())}
            >
                {isPublish ? 'Снять с публикации' : 'Опубликовать'}
            </span>

            <span onClick={onChange} className={styles.change}>
                {!!changeLabel ? changeLabel : 'Изменить'}
            </span>
            <DeleteOutlined
                style={{
                    color: 'red',
                }}
                className={styles.icon}
                onClick={onDelete}
            />
        </div>
    )
}

export default React.memo(PublishBlock)
