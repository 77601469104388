import { message } from 'antd';
import { FC, useEffect, useState } from 'react'
import { List } from 'antd';
import produce from 'immer';

import styles from './ListAds.module.scss'
import { AdvertisingResponseType} from '../../../types/types';
import { Advertising as AdvertisingService } from '../../../services/Advertising';
import { ListItem } from '../ListItem';
import { useHistory } from 'react-router';
import { routes } from '../../../constants/config';
import Title from 'antd/lib/typography/Title';
import { ListHolder } from '../../simples/ListHolder';


type Props = {}

const ListAds: FC<Props> = () => {
  const [page, setPage] = useState<number>(1)

  const [totalCount, setTotalCount] = useState<number>(0)
  const [onPage, setOnPage] = useState<number>(10)
  const nav = useHistory()

  const [list, setList] = useState<AdvertisingResponseType[]>([])




/**
 * получения списка записей
 */
 const getList = async () => {
  const list = await AdvertisingService.getListAd(page)
  setTotalCount(list.meta.maxCount)
        setOnPage(10) 
        let filterData:AdvertisingResponseType[] = []
        for(let i = 0; i < list.data.length; i++){
          if(!list.data[i].title && !list.data[i].description){
           await removeCategory(list.data[i].id).then()
          } else {
            filterData.push(list.data[i])
          }
        }
  setList(filterData)
}

const removeCategory = async (id: number) => {

  await AdvertisingService.deleteAd(id)

}


useEffect(() => {
  getList().then()
}, [page])

/**
 * публикация
 */
const onPublish = async (id: number) => {
  const response = await AdvertisingService.publishAdv(id)
  if (response) {
    setList(
      produce((draft) => {
        const find = draft.filter((item) => item.id === id)[0]
        find.published = 1
      })
    )
  }
}

/**
 * снятие с публикации
 */
const onUnPublish = async (id: number) => {
  const response = await AdvertisingService.unPublishAdv(id)
  if (response) {
    setList(
      produce((draft) => {
        const find = draft.filter((item) => item.id === id)[0]
        find.published = 0
      })
    )
  }
}

return (
  <ListHolder 
  totalCount={totalCount} 
  onPageChange={setPage} 
  onPageShow={setOnPage}>
  <Title level={2}>Список рекламных компаний</Title>
    <List
      className={styles.itemsWrapper}
      bordered={true}
      itemLayout="horizontal"
      dataSource={list}
      renderItem={item => (
        <>
          <List.Item className={styles.item}>
          <ListItem
                photoPath={item.photos[0]?.url || ''}
                name={item.title}
                description={item.description}
                onPublish={() => onPublish(item.id)}
                onUnPublish={() => onUnPublish(item.id)}
                onChange={() => nav.push(`${routes.advertising}?advID=${item.id}`)}
                onDelete={() => removeCategory(item.id)}
                isPublish={!!item.published}
              />
          </List.Item>
        </>
      )}
    />
  </ListHolder>


)
}

export default ListAds
