import { EventResponse } from './Event'
import { MetaType, RemovePhotoResponseType } from './../types/types'
import { getFormData } from './../constants/helper'
import { ErrorsTypes } from '../utils/api'
import api from '../utils/api'
import { endpoints } from '../constants/constants'
import {
    ArticleResponseType,
    DataUploadPhoto,
    PhotoResponseType,
    UploadPhoto,
} from '../types/types'
import { route } from '../utils/route'

export type DataCreateArticleType = {
    categoryId: number
    name: string
    description: string
    content?: string
}

export type ArticleType = {
    success: boolean
    errors?: ErrorsTypes
    data?: {
        articles: ArticleResponseType
    }
}
export type GetListResponse = {
    data: ArticleResponseType[]
    baseUrl: string
    errors: any
    systemVersion: number
    needUpdate: number
    success: boolean
    meta: MetaType
}

export type CreateArticleResponse = {
    data: ArticleResponseType
    baseUrl: string
    errors: any
    systemVersion: number
    needUpdate: number
    success: boolean
    meta: any
}

/**
 * Сервис для работы с статьями
 */
export class Article {
    /**
     * Метод для получения id создаваемой статьи
     */
    static async createArticle(): Promise<{
        author?: {}
        id: number
    }> {
        const response = await api.get<CreateArticleResponse>(
            endpoints.createArticle
        )

        return response.data.data
    }

    /**
     * Метод для отправки фотографий статьи
     */

    static async uploadPhoto(
        id: number,
        data: DataUploadPhoto
    ): Promise<UploadPhoto> {
        const result: UploadPhoto = {
            success: false,
        }

        try {
            const response = await api.post<PhotoResponseType>(
                `${endpoints.uploadPhotoArticle}/${id}`,
                getFormData(data, true)
            )

            result.success = true
            result.data = {
                events: response.data,
            }
        } catch (error: any) {
            console.log('error', error.response)
        }
        return result
    }

    /**
     * Метод для отправки фотографий для превью в статьях
     */

    static async uploadPreviewPhoto(
        id: number,
        data: DataUploadPhoto
    ): Promise<UploadPhoto> {
        const result: UploadPhoto = {
            success: false,
        }

        try {
            const response = await api.post<PhotoResponseType>(
                `${endpoints.uploadPreviewArticle}/${id}`,
                getFormData(data)
            )

            result.success = true
            result.data = {
                events: response.data,
            }
        } catch (error: any) {
            console.log('error', error.response)
        }
        return result
    }

    /**
     * Метод для удаления фотографии контента
     */

    static async removePhoto(id: number): Promise<boolean> {
        const response = await api.delete<RemovePhotoResponseType>(
            `${endpoints.removePhotoArticle}/${id}`
        )

        return response.data.success
    }

    /**
     * Метод для удаления фотографии превью
     */

    static async removePreviewPhoto(id: number): Promise<boolean> {
        const response = await api.delete<RemovePhotoResponseType>(
            `${endpoints.removePreviewArticle}/${id}`
        )

        return response.data.success
    }

    /**
     * Запрос на обновление статьи
     *
     * @param categoryId id категории статьи
     * @param name  Название статьи
     * @param description  Описание статьи
     * @param content Содержание статьи
     */

    static async updateArticle(
        id: number,
        data: DataCreateArticleType
    ): Promise<ArticleType> {
        const result: ArticleType = {
            success: false,
        }

        try {
            const response = await api.post<ArticleResponseType>(
                `${endpoints.updateArticle}/${id}`,
                getFormData(data)
            )
            result.success = true
            result.data = {
                articles: response.data,
            }
        } catch (error: any) {
            console.log('error', error.response)
        }
        return result
    }

    /**
     * Метод для для публикации статьи
     */
    static async unPublishEvent(id: number): Promise<boolean> {
        const response = await api.get<EventResponse>(
            `${endpoints.unPublishArticle}/${id}`
        )

        return response.data.success
    }

    /**
     * Метод для отмены публикации статьи
     */
    static async publishEvent(id: number): Promise<boolean> {
        const response = await api.get<EventResponse>(
            `${endpoints.publishArticle}/${id}`
        )

        return response.data.success
    }

    /**
     * Метод для получения списка статей
     */

    static async getListArticle(
        page?: number,
        onPage?: number,
        limit: number = 10
    ): Promise<GetListResponse> {
        const offset = (page ? page - 1 : 0) * (onPage ? onPage : 10)
        const lim = `&limit=${limit}`
        const response = await api.get<GetListResponse>(
            `${endpoints.listArticle}?offset=${offset}${lim}`
        )

        return response.data
    }
    /**
     * Метод для получения одной статьи
     */

    static async getOneArticle(id: number): Promise<ArticleResponseType> {
        const response = await api.get(route(endpoints.getArticle, { id }))

        return response.data.data
    }

    /**
     * Метод удаления статьи по id
     */

    static async deleteArticle(id: number): Promise<boolean> {
        const response = await api.delete<GetListResponse>(
            `${endpoints.deleteArticle}/${id}`
        )

        return response.data.success
    }
}
