import React, { FC, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory  } from 'react-router-dom'
import { checkUser, setAuthStatus, updateUser,  } from '../../../store/slice/auth'
// import { TextField, TextFieldStatus } from '../../simples/TextField'
import { Button } from '../../simples/Button'

import styles from './AuthForm.module.scss'
import { PassField } from '../../simples/PassField'

import { TextField } from '../../simples/TextField'
import { TextFieldStatus } from '../../simples/TextField/TextField'
import { routes } from '../../../constants/config'
import { Auth } from '../../../services/Auth'


const AuthForm: FC = () => {
    const [login, setLogin] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [isErLog, setErLog] = useState<boolean>(false)

    const navigation = useHistory()

    const dispatch = useDispatch()

    const handleSubmit = useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault()
            try {
                const user = await Auth.login(login, password)

                if (user.success) {
                    dispatch(updateUser(user.data?.user))
                    dispatch(checkUser())
                    dispatch(setAuthStatus())
                    navigation.push(routes.home)
                }
                
            } catch (e:any) {
                console.dir(e);
                
                setErLog(true)
            }
        },
        [dispatch, login, password]
    )

    return (
        <div className={styles.content}>
            <h2 className={styles.auth_h2}>Авторизация</h2>

            <form action="/" onSubmit={(e) => handleSubmit(e)}>
                <div className={styles.formGroup}>
                    <TextField
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                        placeholder={'Логин'}
                        // @ts-ignore
                        // status={isErLog ? TextFieldStatus.Error : undefined}
                        required
                        // note={isErLog ? 'Логин или пароль неверные' : ''}
                    />
                </div>
                <div className={styles.formGroup}>
                    <PassField
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder={'Пароль'}
                        required
                        // @ts-ignore
                        status={isErLog ? TextFieldStatus.Error : undefined}
                    />
                </div>

                <div className={styles.marg_btn}>
                    {/* <Button type={'submit'} className={buttonClasses}> */}
                    <Button type={'submit'} >
                        Войти
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default AuthForm
