import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './RequestsPage.module.scss'

type Props = {
    label: string
    onClick: () => void
    isActive: boolean
}
const TabItem: FC<Props> = ({ label, onClick, isActive }) => {
    return (
        <div
            onClick={onClick}
            className={classNames(styles.tabItem, {
                [styles.isActive]: isActive
            })}>{label}</div>
    )
}

export default React.memo(TabItem);
