import { InboxOutlined } from '@ant-design/icons';
import { message, Modal, Select } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useAppSelector } from '../../../hooks/store';
import { Event } from '../../../services/Event';
import { DropDown } from '../../simples/DropDown';
import { OptionType } from '../../simples/DropDown/DropDown';
import style from './ListEvents.module.scss'

type Props = {
    status: boolean
    onClose: () => void
}

const { Option } = Select;

const XLSModal: FC<Props> = ({ status, onClose }) => {

    const eventList = useAppSelector(state => state.storage.eventCategories)
    const placeList = useAppSelector(state => state.storage.places)

    const [categoryId, setCategoryId] = useState<number>()
    const [search, setSearch] = useState<string>('')

    const [place, setPlace] = useState<number>()

    const [file, setFile] = useState<any>()

    /**
 *  опции для выбора категории
 */
    const optionsList = useMemo(() => {
        return eventList.map((item) => {
            return ({
                label: item.name,
                value: item.id
            })
        })
    }, [eventList])
    /**
 *  опции для выбора места
 */


    const onUpload = useCallback(async () => {
        if (categoryId && file) {
            const fd = new FormData()
            fd.append('categoryId', `${categoryId}`)
            fd.append('placeId', `${place}`)
            fd.append('file', file)
            try {
                const response = await Event.xlsEvents(fd)
                message.success('Данные загружены')
                onClose()
            } catch (error) {

            }


        }

    }, [categoryId, file, onClose, place])

    const loadFile = (e: any) => {
        setFile(e?.target?.files[0])
    }

    const onChange = (id: number) => {
        console.log('id1', id);

        setPlace(id)
        setSearch('')
    }

    const goToOptionType = (arr: any): OptionType[] => {
        return arr.map((item: any) => {
            return {
                label: item.name,
                value: item.id
            }
        })
    }


    return (
        <Modal
            bodyStyle={{ height: '250px' }}
            width={600}
            title="Загрузка файла"
            visible={status}
            onOk={onUpload}
            onCancel={onClose}
            okText={'Загрузить'}
            cancelText={'Отмена'}
        >
            <div className={style.selectWrapper}>
                <h2 className={style.title}>Категория</h2>
                <Select
                    options={optionsList}
                    style={{ width: '100%' }}
                    value={categoryId}
                    placeholder="Выбрать категорию"
                    onChange={(id: number) => setCategoryId(id)}
                />

                <div className={style.zone}>
                    <DropDown
                        onChange={onChange}
                        list={goToOptionType(placeList)}
                    />
                </div>
            </div>

            <div className={style.zone}>
                <input type={'file'} onChange={loadFile} />
            </div>
        </Modal>
    )
}

export default XLSModal
    ;
