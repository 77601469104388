import { MetaType, FeedbackType } from './../types/types';
import api from '../utils/api'
import { endpoints } from '../constants/constants'
import { route } from '../utils/route';

type PublishResponse = {
    data: FeedbackType
    baseUrl: string
    errors: Array<any>
    systemVersion: number
    needUpdate: 0 | 1
    success: boolean
    meta: MetaType
}

type GetListResponse = {
    data: FeedbackType[]
    baseUrl: string
    errors: Array<any>
    systemVersion: number
    needUpdate: 0 | 1
    success: boolean
    meta: MetaType
}


/**
 * Сервис для работы с событиями
 */
export class FeedbackService {
    /**
     * публикуем
     * @param id отзыва
     * @returns 
     */
    static async publishFeedback(id: number): Promise<boolean> {
        const response = await api.get<PublishResponse>(route(endpoints.publishFeedback, { id }))
        return response.data.success
    }
    /**
     * откланяем
     * @param id отзыва
     * @returns 
     */
    static async unPublishFeedback(id: number): Promise<boolean> {
        const response = await api.get<PublishResponse>(route(endpoints.unPublishFeedback, { id }))
        return response.data.success
    }
    /**
     * получаем список отзывов по ID
     * @param id отзыва
     * @returns 
     */
    static async getFeedbackListById(id: number, page?: number, onPage?: number, limit: number = 10): Promise<GetListResponse> {
        const offset = (page ? page - 1 : 0) * (onPage ? onPage : 10)
        const lim = `&limit=${limit}`
        const response = await api.get<GetListResponse>(`${route(endpoints.getFeedbackListById, { id })}&offset=${offset}${lim}`)
        return response.data
    }
    /**
     * получаем список отзывов
     * @param id отзыва
     * @returns 
     */
    static async getFeedbackFullList(page?: number, onPage?: number, limit: number = 10): Promise<GetListResponse> {
        const offset = (page ? page - 1 : 0) * (onPage ? onPage : 10)
        const lim = `&limit=${limit}`
        const response = await api.get<GetListResponse>(`${endpoints.getFeedbackList}?offset=${offset}${lim}`)
        return response.data
    }
    /**
     * получаем один отзыв по ID
     * @param id отзыва
     * @returns 
     */
    static async getOneFeedback(id: number): Promise<FeedbackType> {
        const response = await api.get(route(endpoints.getOneFeedback, { id }))
        return response.data.data
    }
    /**
     * получаем один отзыв по ID
     * @param id отзыва
     * @returns 
     */
    static async deleteFeedback(id: number): Promise<boolean> {
        const response = await api.delete(route(endpoints.deleteFeedback, { id }))
        return response.data.success
    }
    /**
     * публикуем
     * @param id отзыва
     * @returns 
     */
    static async publishFeedbackEvent(id: number): Promise<boolean> {
        const response = await api.get<PublishResponse>(route(endpoints.publishEventFeedback, { id }))
        return response.data.success
    }
    /**
     * откланяем
     * @param id отзыва
     * @returns 
     */
    static async unPublishEventFeedback(id: number): Promise<boolean> {
        const response = await api.get<PublishResponse>(route(endpoints.unPublishEventFeedback, { id }))
        return response.data.success
    }
    /**
     * получаем список отзывов по ID
     * @param id отзыва
     * @returns 
     */
    static async getFeedbackEventListById(id: number, page?: number, onPage?: number, limit: number = 10): Promise<GetListResponse> {
        const offset = (page ? page - 1 : 0) * (onPage ? onPage : 10)
        const lim = `&limit=${limit}`
        const response = await api.get<GetListResponse>(`${route(endpoints.getFeedbackEventListById, { id })}&offset=${offset}${lim}`)
        return response.data
    }
    /**
     * получаем список отзывов
     * @param id отзыва
     * @returns 
     */
    static async getEventFeedbackFullList(page?: number, onPage?: number, limit: number = 10): Promise<GetListResponse> {
        const offset = (page ? page - 1 : 0) * (onPage ? onPage : 10)
        const lim = `&limit=${limit}`
        const response = await api.get<GetListResponse>(`${endpoints.getFeedbackEventList}?offset=${offset}${lim}`)
        return response.data
    }
    /**
     * получаем один отзыв по ID
     * @param id отзыва
     * @returns 
     */
    static async getOneEventFeedback(id: number): Promise<FeedbackType> {
        const response = await api.get(route(endpoints.getOneFeedbackEvent, { id }))
        return response.data.data
    }
    /**
     * получаем один отзыв по ID
     * @param id отзыва
     * @returns 
     */
    static async deleteFeedbackEvent(id: number): Promise<boolean> {
        const response = await api.delete(route(endpoints.deleteFeedbackEvent, { id }))
        return response.data.success
    }

}