import React, { FC, useCallback, useEffect, useState } from 'react'
import { List } from 'antd'
import styles from './ListLocation.module.scss'
import produce from 'immer'
import { Location as LocationService } from '../../../services/Location'
import { LocationResponseType } from '../../../types/types'
import { ListItem } from '../ListItem'
import { useHistory } from 'react-router'
import { routes } from '../../../constants/config'
import Title from 'antd/lib/typography/Title'
import { ListHolder } from '../../simples/ListHolder'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import { setLocationPage } from '../../../store/slice/app'
import { getPrewiewPhoto } from '../../../utils/photoEditor'

type Props = {}

const ListLocation: FC<Props> = () => {
    const dispatch = useAppDispatch()
    const activePage = useAppSelector((state) => state.app.locationPage)

    const [list, setList] = useState<LocationResponseType[]>([])
    const [removedStatus, setRemovedStatus] = useState(false)
    const nav = useHistory()

    const [totalCount, setTotalCount] = useState<number>(0)
    const [onPage, setOnPage] = useState<number>(10)

    const getList = async () => {
        const list = await LocationService.getListLocation(activePage)
        setTotalCount(list.meta.maxCount)
        setOnPage(10)
        setList(list.data)
    }

    const removeCategory = async (id: number) => {
        const response = await LocationService.deleteLocation(id)

        if (response) {
            setRemovedStatus(true)
        }
    }

    /**
     * публикация
     */
    const onPublish = async (id: number) => {
        const response = await LocationService.publishLocation(id)
        if (response) {
            setList(
                produce((draft) => {
                    const find = draft.filter((item) => item.id === id)[0]
                    find.published = 1
                })
            )
        }
    }

    /**
     * снятие с публикации
     */
    const onUnPublish = async (id: number) => {
        const response = await LocationService.unPublishLocation(id)
        if (response) {
            setList(
                produce((draft) => {
                    const find = draft.filter((item) => item.id === id)[0]
                    find.published = 0
                })
            )
        }
    }

    useEffect(() => {
        getList().then((res) => {})
        setRemovedStatus(false)
    }, [removedStatus, activePage])

    const viewFeedBacks = useCallback((id: number) => {
        nav.push(`${routes.feedbacksPlace}?placeID=${id}`)
    }, [])

    return (
        <ListHolder
            currentPage={activePage}
            totalCount={totalCount}
            onPageChange={(page) => dispatch(setLocationPage(page))}
            onPageShow={setOnPage}
        >
            <Title level={2}>Список мест</Title>
            <List
                className={styles.itemsWrapper}
                bordered={true}
                itemLayout="horizontal"
                dataSource={list}
                renderItem={(item) => (
                    <>
                        <List.Item className={styles.item}>
                            <ListItem
                                photoPath={
                                    !!item.new_photos?.length
                                        ? getPrewiewPhoto(item.new_photos)
                                        : item.photos[0]?.url || ''
                                }
                                name={item.name}
                                description={item.description}
                                onPublish={() => onPublish(item.id)}
                                onUnPublish={() => onUnPublish(item.id)}
                                onChange={() =>
                                    nav.push(
                                        `${routes.create_location}?locationID=${item.id}`
                                    )
                                }
                                onDelete={() => removeCategory(item.id)}
                                isPublish={!!item.published}
                            />
                        </List.Item>
                        <div className={styles.borderline} />
                    </>
                )}
            />
        </ListHolder>
    )
}

export default ListLocation
