import { Alert, Button, message, List } from 'antd'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { TitleAndDefinition } from '../../simples/TitleAndDefinition'
import { SpecialProject as SpecialProjectService } from '../../../services/SpecialProject'
import {
    IPhotoArea,
    LinksProjectType,
    PhotoResponseType,
    PhotoType,
    TextEditorType,
} from '../../../types/types'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import styles from './CreateSpecialProjects.module.scss'
import { SaveBtnBlock } from '../../simples/SaveBtnBlock'
import { useHistory } from 'react-router'
import { PhotoGallery } from '../PhotoGallery'
import { TextEditor } from '../TextEditor'
import { routes } from '../../../constants/config'
import Title from 'antd/lib/typography/Title'
import produce from 'immer'
import {
    DOMAIN,
    PhotoGaleryType,
    specialProjectPhotoSizes,
} from '../../../constants/constants'
import { PhotoArea } from '../PhotoArea'
import { isChecked, isMainPhoto } from '../../../utils/photoEditor'
import { LoadFiles } from '../../../services/FileService'

type Props = {}

const CreateSpecialProjects: FC<Props> = () => {
    const nav = useHistory()
    const projectID = useHistory().location.search?.split('=')[1]

    const [publish, setPublish] = useState(false)
    const [description, setDescription] = useState<string>('')
    const [pictureData, setPictureData] = useState<PhotoResponseType[]>([])
    const [isError, setError] = useState(false)
    const [title, setTitle] = useState('')
    const [newphotos, setNewPhotos] = useState<IPhotoArea[]>([])
    const [createSpecialProject_ID, setCreateSpecialProject_ID] =
        useState<number>(!!projectID ? +projectID : 0)
    const [links, setLinks] = useState<LinksProjectType[]>([
        {
            link: '',
            name: '',
        },
    ])
    const [content, setContent] = useState<TextEditorType[]>([
        {
            id: new Date().getTime(),
            text: '',
            photos: [],
        },
    ])

    const isHas = useRef<boolean>(false)

    /**
     * получение проекта по ID
     */
    const getOneProject = useCallback(async () => {
        try {
            const response = await SpecialProjectService.getOneProject(
                +projectID
            )
            setPublish(!!response.published)
            setTitle(response.title)
            setLinks(response.links)
            setContent(response.content)
            setDescription(response.description)
            setPictureData(response.photos)
            setLinks(response.links)

            if (response?.new_photos && !!response.new_photos.length) {
                setNewPhotos((prev) => [
                    ...prev,
                    ...response.new_photos.map((obj) => ({
                        ...obj,
                        url: DOMAIN + obj.photo_url,
                    })),
                ])
            }

            isHas.current = true
        } catch (error) {}
    }, [projectID])

    /**
     *
     * сохранение
     */
    const onSubmit = async () => {
        const data = {
            title,
            content: JSON.stringify(content),
            links: JSON.stringify(links),
            description,
            new_photos: newphotos.map((photo) => ({
                photo_id: photo.photo_id,
                type: photo.type,
                is_main: photo.is_main,
            })),
        }

        const mess = isHas ? 'сохранен' : 'создан'

        try {
            const response = await SpecialProjectService.updateSpecialProject(
                createSpecialProject_ID,
                data
            )
            if (response.success) {
                if (publish) {
                    const responsePublish =
                        await SpecialProjectService.publishSpecialProject(
                            createSpecialProject_ID
                        )
                    if (!responsePublish) {
                        message.warning(`Проект ${mess}, но не опубликован`)
                    } else {
                        message.warning(`Проект ${mess} и опубликован`)
                    }
                } else {
                    const responseUnPublish =
                        await SpecialProjectService.unPublishSpecialProject(
                            createSpecialProject_ID
                        )
                    if (responseUnPublish) {
                        message.warning(`Проект ${mess}, но не опубликован `)
                    }
                }
                nav.push(routes.get_special_projects)
            }
        } catch (error) {
            message.error('Произошла ошибка при сохранении')
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setError(false)
        }, 5000)
    }, [isError])

    useEffect(() => {
        if (!!projectID) {
            getOneProject().then()
        } else {
            createSpecialProject().then()
        }
    }, [])

    /**
     * сохранение фото
     * @param photo фото
     * @returns фото id and url
     */
    const loadingPhoto = async (photo: any, resourse?: PhotoGaleryType) => {
        if (resourse && resourse === PhotoGaleryType.PHOTO_LIST) {
            const { image, type, is_main } = photo

            const isMobile = isChecked(type)
            const isEmtyType = !!newphotos
                ? newphotos.find((photo) => photo.type === type)
                : false

            const response = await LoadFiles(image)

            if (response.success) {
                const { data } = response
                if (!isMobile && isEmtyType) {
                    setNewPhotos((prev) =>
                        prev.map((item) =>
                            item.type === type
                                ? {
                                      url: DOMAIN + data.path,
                                      type,
                                      photo_id: data.id,
                                      is_main,
                                  }
                                : item
                        )
                    )
                } else {
                    if (!!newphotos) {
                        setNewPhotos((prev) =>
                            isMainPhoto([
                                ...prev,
                                {
                                    url: DOMAIN + data.path,
                                    type,
                                    photo_id: data.id,
                                    is_main,
                                },
                            ])
                        )
                    } else {
                        setNewPhotos(
                            isMainPhoto([
                                {
                                    url: DOMAIN + data.path,
                                    type,
                                    photo_id: data.id,
                                    is_main,
                                },
                            ])
                        )
                    }
                }
            } else {
                message.error('Произошла ошибка при загрузке фотографии')
            }
        } else {
            const data = {
                file: [photo],
            }

            let response: any = await SpecialProjectService.uploadPhoto(
                createSpecialProject_ID,
                data
            )

            if (response.success) {
                setPictureData((prev) => [...prev, response.data])
                return response.data
            } else {
                message.error('Произошла ошибка при загрузке фотографии')
            }
        }
    }

    /**
     * удаляем фото
     * @param id фото
     * @returns успех или нет
     */
    const deletePhoto = async (id: number, sourse?: PhotoGaleryType) => {
        if (sourse === PhotoGaleryType.PHOTO_LIST) {
            setNewPhotos((prev) =>
                isMainPhoto(prev.filter((item) => item.photo_id !== id))
            )
        } else {
            let result: boolean = await SpecialProjectService.removePhoto(id)
            if (result) {
                setPictureData((prev) => {
                    return prev.filter((item) => item.id !== id)
                })
                return result
            } else {
                message.error(
                    'Что-то пошло не так, не получилось удалить фотографию'
                )
                return false
            }
        }
    }

    /**
     * создание нового проекта
     */
    const createSpecialProject = async () => {
        const response = await SpecialProjectService.createSpecialProject()
        setCreateSpecialProject_ID(response.id)
    }

    const styleError = {
        border: isError ? '1px solid red' : '0px solid red',
    }

    /**
     * ADD link
     */

    const addLink = () => {
        setLinks(
            produce((draft) => {
                draft.push({ name: '', link: '' })
            })
        )
    }

    /**
     * delete link
     */
    const ondeleteLink = (i: number) => {
        setLinks((prev) => prev.filter((_, index) => i !== index))
    }

    const handleSelectMainPhoto = (id: number, type: PhotoType) => {
        setNewPhotos((prev) =>
            prev.map((photo) =>
                photo.photo_id === id && photo.type === type
                    ? { ...photo, is_main: true }
                    : photo.photo_id !== id && photo.type === type
                    ? { ...photo, is_main: false }
                    : photo
            )
        )
    }
    /**
     * Вывод компонента
     */
    return (
        <div className={styles.wrapper}>
            <Title level={2}>
                {projectID ? 'Редактирование проекта' : 'Создание проекта'}
            </Title>
            {isError ? (
                <Alert
                    className={styles.alert}
                    message="Все поля обязательны к заполнению"
                    type="error"
                />
            ) : null}

            <TitleAndDefinition
                isError={isError}
                firstValue={title}
                textAreaValue={description}
                firstTitle={'Название специального проекта'}
                textAreaTitle={'Описание специального проекта'}
                firstPlaceholder={true}
                textAreaPlaceholder={true}
                firstPlaceholderTitle={'Напишите заголовок'}
                textAreaPlaceholderTitle={'Напишите описание'}
                onChangeFirst={(value) => setTitle(value)}
                onChangeTextArea={(value) => setDescription(value)}
            />

            <TextEditor
                value={content}
                onChange={(data: Array<TextEditorType>) => setContent(data)}
                onDeletePhoto={async (id: number) => await deletePhoto(id)}
                key={content.length}
                onUploadPhotos={async (data) => await loadingPhoto(data)}
            />

            <div className={styles.seanceBlock}>
                <div className={styles.linksWrapper}>
                    <div className={styles.addBtnWrapper}>
                        <Button
                            style={{ borderRadius: '50%' }}
                            onClick={addLink}
                            type="primary"
                            className={styles.btn}
                            icon={<PlusOutlined />}
                        />
                    </div>
                    <List
                        style={{ border: 'none', width: '100%' }}
                        size="default"
                        bordered
                        dataSource={links || []}
                        renderItem={(item, index) => {
                            return (
                                <div className={styles.inputFactsWrapper}>
                                    <List.Item
                                        style={{
                                            width: '100%',
                                            justifyContent: 'flex-end',
                                            padding: 0,
                                            margin: 0,
                                        }}
                                    >
                                        <div
                                            style={{
                                                flex: 1,
                                            }}
                                        >
                                            <TitleAndDefinition
                                                isSeanseStyle={true}
                                                firstValue={links[index].name}
                                                firstPlaceholderTitle="Название ссылки"
                                                onChangeFirst={(value) =>
                                                    setLinks(
                                                        produce((draft) => {
                                                            draft[index].name =
                                                                value
                                                        })
                                                    )
                                                }
                                            />
                                        </div>
                                        <div
                                            style={{
                                                flex: 3,
                                                marginLeft: '10px',
                                            }}
                                        >
                                            <TitleAndDefinition
                                                isSeanseStyle={true}
                                                firstValue={links[index].link}
                                                firstPlaceholderTitle="Ссылка"
                                                onChangeFirst={(value) =>
                                                    setLinks(
                                                        produce((draft) => {
                                                            draft[index].link =
                                                                value
                                                        })
                                                    )
                                                }
                                            />
                                        </div>
                                        <div
                                            style={{
                                                width: '40px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {links.length > 1 && (
                                                <CloseOutlined
                                                    onClick={() =>
                                                        ondeleteLink(index)
                                                    }
                                                    className={styles.closeIcon}
                                                />
                                            )}
                                        </div>
                                    </List.Item>
                                </div>
                            )
                        }}
                    />
                </div>
            </div>

            {!!pictureData.length && (
                <div style={styleError}>
                    <PhotoGallery
                        title={'Фото события'}
                        data={pictureData}
                        onUpload={(value) => loadingPhoto(value)}
                        isCrop={true}
                        onDelete={(id) => deletePhoto(id)}
                    />
                </div>
            )}

            <PhotoArea
                data={newphotos}
                onSubmit={(value) =>
                    loadingPhoto(value, PhotoGaleryType.PHOTO_LIST)
                }
                sizes={specialProjectPhotoSizes}
                onSelectMain={handleSelectMainPhoto}
                onDelete={(id) => deletePhoto(id, PhotoGaleryType.PHOTO_LIST)}
            />

            <SaveBtnBlock
                onSave={onSubmit}
                onPublish={setPublish}
                isPublish={publish}
                hasID={!!projectID}
            />
        </div>
    )
}

export default CreateSpecialProjects
