import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Button, Checkbox, message } from 'antd'
import { TitleAndDefinition } from '../../simples/TitleAndDefinition'
import { Select } from 'antd'
import 'moment/locale/ru'
import { Article as ArticleService } from '../../../services/Article'
import {
    ArticleCategoryTypeResponse,
    CategoryArticle,
} from '../../../services/CategoryArticle'
import {
    IPhotoArea,
    PhotoResponseType,
    PhotoType,
    TextEditorType,
} from '../../../types/types'
import {
    articlePhotoSizes,
    DOMAIN,
    PhotoGaleryType,
} from '../../../constants/constants'
import style from './CreateArticle.module.scss'
import { TextEditor } from '../TextEditor'
import { useHistory } from 'react-router-dom'
import { routes } from '../../../constants/config'
import { PhotoGallery } from '../PhotoGallery'
import Title from 'antd/lib/typography/Title'
import { InputLabled } from '../../simples/InputLabled'
import { PhotoArea } from '../PhotoArea'
import { isChecked, isMainPhoto } from '../../../utils/photoEditor'
import { LoadFiles } from '../../../services/FileService'

type Props = {}

const CreateArticle: FC<Props> = () => {
    const nav = useHistory()
    const articleID = useHistory().location.search?.split('=')[1]

    const [publish, setPublish] = useState(false)
    const [author, setAuthor] = useState<string | null>('')
    const [pictureData, setPictureData] = useState<PhotoResponseType[]>([])
    const [categoryId, setCategoryId] = useState<number>()
    const [name, setName] = useState<string>('')
    const [newphotos, setNewPhotos] = useState<IPhotoArea[]>([])
    const [description, setDescription] = useState<string>('')
    const [createArticle_ID, setCreateArticle_ID] = useState<number>(
        articleID ? +articleID : 0
    )
    const [picturePreviewData, setPicturePreviewData] = useState<
        PhotoResponseType[]
    >([])
    const [isError, setError] = useState(false)
    const [categoryArticleList, setCategoryArticleList] = useState<
        ArticleCategoryTypeResponse[]
    >([])

    const [content, setContent] = useState<Array<TextEditorType>>([
        {
            id: new Date().getTime(),
            text: '',
            photos: [],
        },
    ])

    /**
     *  опции для выбора категории
     */
    const optionsList = useMemo(() => {
        return categoryArticleList.map((item) => {
            return {
                label: item.name,
                value: item.id,
            }
        })
    }, [categoryArticleList])

    const getArticle = useCallback(async () => {
        const response = await ArticleService.getOneArticle(+articleID)
        setName(response.name)
        setDescription(response.description)

        setContent(response.content)
        setPicturePreviewData(response.previews)
        setPictureData(response.photos)
        setCategoryId(response.categoryId)
        setPublish(!!response.published)
        setAuthor(response.articleAuthor)

        if (response?.new_photos && !!response.new_photos.length) {
            setNewPhotos((prev) => [
                ...prev,
                ...response.new_photos.map((obj) => ({
                    ...obj,
                    url: DOMAIN + obj.photo_url,
                })),
            ])
        }
    }, [articleID])

    const onSubmit = async () => {
        if (
            categoryId === undefined ||
            name.trim() === '' ||
            description.trim() === ''
        ) {
            setError(true)
            return
        }

        let data = {
            categoryId,
            name,
            description,
            content: JSON.stringify(content),
            articleAuthor: author,
            new_photos: newphotos.map((photo) => ({
                photo_id: photo.photo_id,
                type: photo.type,
                is_main: photo.is_main,
            })),
        }
        //@ts-ignore
        const response = await ArticleService.updateArticle(
            createArticle_ID,
            data
        )

        if (response.success) {
            setCategoryId(undefined)
            setName('')
            setDescription('')
            setContent([
                {
                    id: new Date().getTime(),
                    text: '',
                    photos: [],
                },
            ])
            setPictureData([])
            setPicturePreviewData([])

            if (publish) {
                const responsePublish = await ArticleService.publishEvent(
                    createArticle_ID
                )

                if (!responsePublish) {
                    message.warning('Статья создана, но не опубликована ')
                } else {
                    message.warning('Статья создана, и опубликована ')
                }
            } else {
                const responseUnPublish = await ArticleService.unPublishEvent(
                    createArticle_ID
                )
                if (responseUnPublish) {
                    message.warning('Статья изменена, и снята с публикации ')
                }
            }
        }

        nav.push(routes.get_article_list)
    }

    const loadingPhoto = async (photo: any, resourse: PhotoGaleryType) => {
        const data = {
            file: [photo],
        }

        let response: any

        if (resourse === PhotoGaleryType.MAIN) {
            response = await ArticleService.uploadPhoto(createArticle_ID, data)

            if (response.success) {
                setPictureData((prev) => [...prev, response.data?.events.data])
                return response.data?.events.data
            } else {
                message.error('Произошла ошибка при загрузке фотографии')
            }
        }

        if (resourse === PhotoGaleryType.PREVIEW) {
            response = await ArticleService.uploadPreviewPhoto(
                createArticle_ID,
                data
            )

            if (response.success) {
                setPicturePreviewData((prev) => [
                    ...prev,
                    response.data?.events.data,
                ])
            } else {
                message.error('Произошла ошибка при загрузке фотографии')
            }
        }

        if (resourse === PhotoGaleryType.PHOTO_LIST) {
            const { image, type, is_main } = photo

            const isMobile = isChecked(type)
            const isEmtyType = !!newphotos
                ? newphotos.find((photo) => photo.type === type)
                : false

            response = await LoadFiles(image)

            if (response.success) {
                const { data } = response
                if (!isMobile && isEmtyType) {
                    setNewPhotos((prev) =>
                        prev.map((item) =>
                            item.type === type
                                ? {
                                      url: DOMAIN + data.path,
                                      type,
                                      photo_id: data.id,
                                      is_main,
                                  }
                                : item
                        )
                    )
                } else {
                    if (!!newphotos) {
                        setNewPhotos((prev) =>
                            isMainPhoto([
                                ...prev,
                                {
                                    url: DOMAIN + data.path,
                                    type,
                                    photo_id: data.id,
                                    is_main,
                                },
                            ])
                        )
                    } else {
                        setNewPhotos(
                            isMainPhoto([
                                {
                                    url: DOMAIN + data.path,
                                    type,
                                    photo_id: data.id,
                                    is_main,
                                },
                            ])
                        )
                    }
                }
            } else {
                message.error('Произошла ошибка при загрузке фотографии')
            }
        }
    }

    const deletePhoto = async (id: number, sourse: PhotoGaleryType) => {
        let result: boolean

        if (sourse === PhotoGaleryType.MAIN) {
            result = await ArticleService.removePhoto(id)

            if (result) {
                setPictureData((prev) => {
                    return prev.filter((item) => item.id !== id)
                })
                return result
            } else {
                message.error(
                    'Что-то пошло не так, не получилось удалить фотографию'
                )
                return false
            }
        } else if (sourse === PhotoGaleryType.PREVIEW) {
            result = await ArticleService.removePreviewPhoto(id)

            if (result) {
                setPicturePreviewData((prev) => {
                    return prev.filter((item) => item.id !== id)
                })
            } else {
                message.error(
                    'Что-то пошло не так, не получилось удалить фотографию'
                )
            }
        } else {
            setNewPhotos((prev) =>
                isMainPhoto(prev.filter((item) => item.photo_id !== id))
            )
        }
    }

    useEffect(() => {
        if (!!articleID) {
            getArticle().then()
        } else {
            createArticle().then()
        }

        getArticleCategoryList().then()
    }, [articleID])

    useEffect(() => {
        setTimeout(() => {
            setError(false)
        }, 5000)
    }, [isError])

    const createArticle = async () => {
        const response = await ArticleService.createArticle()
        setCreateArticle_ID(response.id)
    }

    const getArticleCategoryList = async () => {
        const list = await CategoryArticle.getListArticleCategories(1, 10, 1000)
        setCategoryArticleList(list.data)
    }

    const handleSelectMainPhoto = (id: number, type: PhotoType) => {
        setNewPhotos((prev) =>
            prev.map((photo) =>
                photo.photo_id === id && photo.type === type
                    ? { ...photo, is_main: true }
                    : photo.photo_id !== id && photo.type === type
                    ? { ...photo, is_main: false }
                    : photo
            )
        )
    }

    const styleError = {
        border: isError ? '1px solid red' : '0px solid red',
    }

    return (
        <>
            <Title level={2}>
                {articleID ? 'Редактирование статьи' : 'Создание статьи'}
            </Title>
            {isError ? (
                <Alert
                    className={style.alert}
                    message="Все поля обязательны к заполнению"
                    type="error"
                />
            ) : null}

            <div className={style.selectWrapper} style={styleError}>
                <h2 className={style.title}>Категория</h2>
                <Select
                    options={optionsList}
                    style={{ width: '100%' }}
                    value={categoryId}
                    placeholder="Выбрать категорию"
                    onChange={(id: number) => setCategoryId(id)}
                />
            </div>

            <TitleAndDefinition
                isError={isError}
                firstValue={name}
                secondValue={description}
                firstTitle={'Название статьи'}
                secondTitle={'Описание статьи'}
                textAreaTitle={'Содержание статьи'}
                firstPlaceholder={true}
                secondPlaceholder={true}
                firstPlaceholderTitle={'Введите название статьи'}
                secondPlaceholderTitle={'Введите описание статьи'}
                textAreaPlaceholderTitle={'Введите содержание статьи'}
                onChangeFirst={(value) => setName(value)}
                onChangeSecond={(value) => setDescription(value)}
                onChangeTextArea={(value) => setContent(value)}
            />
            <TextEditor
                value={content}
                onChange={(data: Array<TextEditorType>) => setContent(data)}
                onDeletePhoto={async (id: number) =>
                    await deletePhoto(id, PhotoGaleryType.MAIN)
                }
                key={content.length}
                onUploadPhotos={async (data) =>
                    await loadingPhoto(data, PhotoGaleryType.MAIN)
                }
            />

            <InputLabled
                value={author || ''}
                onChange={setAuthor}
                label={'Автор статьи'}
            />

            {!!pictureData.length && (
                <div style={styleError}>
                    <PhotoGallery
                        title={'Фото события'}
                        data={pictureData}
                        onUpload={(value) =>
                            loadingPhoto(value, PhotoGaleryType.MAIN)
                        }
                        isCrop={true}
                        onDelete={(id) => deletePhoto(id, PhotoGaleryType.MAIN)}
                    />
                </div>
            )}

            {!!picturePreviewData.length && (
                <div style={styleError}>
                    <PhotoGallery
                        title={'Фото для превью и для верхнего слайдера'}
                        data={picturePreviewData}
                        onUpload={(value) =>
                            loadingPhoto(value, PhotoGaleryType.PREVIEW)
                        }
                        isCrop={true}
                        onDelete={(id) =>
                            deletePhoto(id, PhotoGaleryType.PREVIEW)
                        }
                    />
                </div>
            )}

            <PhotoArea
                data={newphotos}
                onSubmit={(value) =>
                    loadingPhoto(value, PhotoGaleryType.PHOTO_LIST)
                }
                sizes={articlePhotoSizes}
                onSelectMain={handleSelectMainPhoto}
                onDelete={(id) => deletePhoto(id, PhotoGaleryType.PHOTO_LIST)}
            />

            <div className={style.btnWrapper}>
                <Button type="primary" onClick={onSubmit}>
                    {articleID ? 'Сохранить' : 'Отправить'}
                </Button>
                <Checkbox
                    checked={publish}
                    onChange={(e) => {
                        setPublish(e.target.checked)
                    }}
                >
                    {!!publish ? 'Опубликовано' : 'Опубликовать'}
                </Checkbox>
            </div>
        </>
    )
}

export default CreateArticle
