import { Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import React from 'react'
import { AvatarCrop } from '../AvatarCrop'

interface Props {
    updateData: (values: any) => void
    resetComplicated?: () => void
    reset?: boolean
    values?: any[]
    multiple?: boolean
    isCrop?: boolean
}

class PhotosLoader extends React.Component<Props> {
    public static defaultProps = {
        multiple: true,
    }

    state = {
        file: undefined,
    }

    // @ts-ignore
    handleChange = ({ file }) => {
        if (this.props.isCrop) {
            const fileReader = new FileReader()

            fileReader.onload = ({ target }) => {
                if (target?.result) {
                    this.setState({ file })
                }
            }

            fileReader.readAsDataURL(file)
        } else {
            this.props.updateData(file)
        }
    }

    onClose = () => {
        this.setState({ file: undefined })
    }

    render() {
        // if (this.state.file && this.props.isCrop) {
        //     return (
        //         <AvatarCrop
        //             onSubmit={this.props.updateData}
        //             onClose={this.onClose}
        //             image={this.state.file}
        //         />
        //     )
        // }
        return (
            <Upload
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                showUploadList={false}
                onChange={this.handleChange}
                multiple={false}
                beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
            >
                <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Загрузить</div>
                </div>
            </Upload>
        )
    }
}

export default PhotosLoader
