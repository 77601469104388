import api, { ResponseType } from '../utils/api'
import { endpoints } from './../constants/constants'
import { User as UserType } from '../types/types'

export type GetUserResponse = ResponseType & {
    data: UserType
}

// export type GetUserListResponse = {
//     data: UserType[]
//     meta: {
//         totalCount: number
//         total: number
//     }
// }

/**
 * User service.
 */
export class User {
    /**
     * Get info of auth user.
     * @return {Promise<UserType>}
     */
    static async getUserList(page?: number) {
        const offset = page ? page * 10 : 0
        const limit = `&limit=${10}`
        const { data } = await api.get(
            endpoints.getUserList + `?offset=${offset}` + limit
        )

        return { usersCount: data.meta.total, users: data.data[0] }
    }

    static async getUser(): Promise<UserType> {
        const response = await api.get<GetUserResponse>(endpoints.getUser)
        const user = response.data.data

        if (user.created_at) {
            user.created_at = new Date(user.created_at)
        }

        if (user.profile && user.profile.birthday) {
            user.profile.birthdayDate = new Date(user.profile.birthday)
        }

        return user
    }
}
