import React, { FC } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { useAppSelector } from '../../../hooks/store'
import { AuthScreen } from '../../pages/Auth'

type Props = RouteProps

const PrivateRoute: FC<Props> = ({ children, ...rest }) => {
    const isAuth = useAppSelector((state) => state.auth.isAuth)

    
    
    return (
        <Route {...rest}>
           
            {isAuth ? (
           
                <>{children}</>
            ) : (
                
                    <AuthScreen />
                
            )}
        </Route>
    )
}

export default PrivateRoute
