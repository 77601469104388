import { Button, DatePicker, message, Modal, Select } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAppSelector } from '../../../hooks/store'
import { Event } from '../../../services/Event'
import { RequestService } from '../../../services/RequestService'
import {
    EventResponseType,
    RequestStatus,
    RequestType,
} from '../../../types/types'
import { MainPreloader } from '../../simples/MainPreloader'
import { TextField } from '../../simples/TextField'
import styles from './RequestsPage.module.scss'
import TabItem from './TabItem'
import FileSaver from 'file-saver'
import produce from 'immer'

const { Option } = Select

enum RequestTab {
    ALL,
    AGREE,
    DECLINE,
}

const statusName = {
    [RequestStatus.ON_VIEW]: 'на рассмотрении',
    [RequestStatus.AGREE]: 'одобрено',
    [RequestStatus.DECLINE]: 'отклонено',
    [RequestStatus.DECLINE_BY_USER]: 'отклонено пользователем',
}

export type ExportType = {
    id: boolean
    fio: boolean
    createdAt: boolean
    event: boolean
    place: boolean
    city: boolean
    status: boolean
}

const RequestsPage: FC = (): JSX.Element => {
    const places = useAppSelector((state) => state.storage.places)
    const cities = useAppSelector((state) => state.storage.cities)
    const [activeTab, setActiveTab] = useState<RequestTab>(RequestTab.ALL)
    const [placeId, setPlaceId] = useState<number>()
    const [eventId, setEventId] = useState<number>()
    const [createdAt, setCreateAt] = useState()
    const [updatedAt, setUpdatedAt] = useState()
    const [fio, setFio] = useState<string>('')
    const [eventList, setEventList] = useState<EventResponseType[]>([])

    const [modal, setModal] = useState<boolean>(false)

    const scrollRef = useRef<HTMLDivElement>(null)
    const reached = useRef(false)

    const [fields, setFields] = useState<ExportType>({
        id: false,
        fio: false,
        createdAt: false,
        event: false,
        place: false,
        city: false,
        status: false,
    } as ExportType)

    const [list, setList] = useState<RequestType[]>([])
    const currentList = useRef<RequestType[]>(list)

    currentList.current = list

    const has = useRef(true)
    const [loading, setLoading] = useState(false)
    const offset = useRef(0)

    const load = async () => {
        if (!has.current || loading) {
            return
        }
        setLoading(true)

        try {
            const result = await RequestService.getRequestList({
                createdAt,
                updatedAt,
                fio,
                eventId,
                placeId,
                status: activeTab as number,
                offset: offset.current,
                limit: 30,
            })

            if (!result.length) {
                has.current = false
                return
            }

            offset.current = offset.current + result.length
            setList([...currentList.current, ...result])
        } catch (error) {
            setLoading(false)
            has.current = false
        } finally {
            setLoading(false)
        }
    }

    const clear = () => {
        currentList.current = []
        offset.current = 0
        has.current = true
        setList([])
        load().then()
    }

    useEffect(() => {
        load().then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        clear()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fio, placeId, eventId, createdAt, updatedAt, activeTab])

    const optionsListPlaces = useMemo(() => {
        const options = places.map((i) => ({
            label: i.name,
            value: i.id,
        }))
        return options
    }, [places])

    const optionsListCities = useMemo(() => {
        const options = cities.map((i) => ({
            label: i.name,
            value: i.id,
        }))
        return options
    }, [cities])

    const getEventHint = async (search: string) => {
        try {
            const response = await Event.getListEventsSearch({
                limit: 30,
                search,
            })
            setEventList(response)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getEventHint('').then()
    }, [])

    /**
     *  опции для выбора Cj,snbz
     */
    const optionsEventList = useMemo(() => {
        return eventList.map((item) => {
            return (
                <Option key={item.id} value={item.id}>
                    {item.name}
                </Option>
            )
        })
    }, [eventList])

    const onChangeCreatedAt = (e: any) => {
        if (e) {
            setCreateAt(e.format().slice(0, 10))
        } else {
            setCreateAt(undefined)
        }
    }
    const onChangeUpdatedAt = (e: any) => {
        if (e) {
            setUpdatedAt(e.format().slice(0, 10))
        } else {
            setUpdatedAt(undefined)
        }
    }

    const onImport = async () => {
        const response = await RequestService.getXls(fields, {
            createdAt,
            updatedAt,
            fio,
            eventId,
            placeId,
            status: activeTab as number,
            limit: 100000000,
        })

        FileSaver.saveAs(response, 'отчет.xlsx')
    }

    /**
     *
     * dynamic pagination
     */
    const handleScroll = () => {
        if (!scrollRef.current) {
            return
        }

        const contentHeight = scrollRef.current.offsetHeight
        const scrollHeight = scrollRef.current.scrollHeight

        const scrollTop = scrollRef.current.scrollTop

        if (scrollHeight <= contentHeight) {
            return
        }

        const afterEndReach =
            scrollHeight - (scrollTop + contentHeight) < contentHeight / 2

        if (afterEndReach && !reached.current) {
            reached.current = true

            load().then()
        } else if (!afterEndReach && reached.current) {
            reached.current = false
        }
    }

    const onAgree = useCallback(async (id: number) => {
        try {
            const response = await RequestService.changeStatus(
                id,
                RequestStatus.AGREE
            )
            setList(
                produce((draft) => {
                    const find = draft.find((i) => i.id === response.id)
                    if (find) {
                        find.status = response.status
                    }
                })
            )
            message.success('Заявка одобрена')
        } catch (error) {
            message.error('Ошибка при изменении статуса заявки')
        }
    }, [])

    const onDecline = useCallback(async (id: number) => {
        try {
            const response = await RequestService.changeStatus(
                id,
                RequestStatus.DECLINE
            )
            setList(
                produce((draft) => {
                    const find = draft.find((i) => i.id === response.id)
                    if (find) {
                        find.status = response.status
                    }
                })
            )
            message.success('Заявка отклонена')
        } catch (error) {
            message.error('Ошибка при изменении статуса заявки')
        }
    }, [])

    return (
        <div className={styles.root}>
            <div className={styles.tabs}>
                <TabItem
                    isActive={activeTab === RequestTab.ALL}
                    onClick={() => setActiveTab(RequestTab.ALL)}
                    label={'Все заявки'}
                />
                <TabItem
                    isActive={activeTab === RequestTab.AGREE}
                    onClick={() => setActiveTab(RequestTab.AGREE)}
                    label={'Одобренные'}
                />
                <TabItem
                    isActive={activeTab === RequestTab.DECLINE}
                    onClick={() => setActiveTab(RequestTab.DECLINE)}
                    label={'Отклоненные'}
                />
            </div>

            <div className={styles.filter}>
                <table className={styles.filterTable}>
                    <tr>
                        <td>
                            <div className={styles.filterPart}>
                                <div className={styles.filterString}>
                                    <span className={styles.filterLabel}>
                                        Дата создания
                                    </span>
                                    <DatePicker
                                        placeholder="выберите дату"
                                        className={styles.datePicker}
                                        onChange={onChangeCreatedAt}
                                    />
                                </div>
                                <div className={styles.filterString}>
                                    <span className={styles.filterLabel}>
                                        Дата изменения
                                    </span>
                                    <DatePicker
                                        placeholder="выберите дату"
                                        className={styles.datePicker}
                                        onChange={onChangeUpdatedAt}
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className={styles.filterPart}>
                                <div className={styles.filterString}>
                                    <span className={styles.filterLabel}>
                                        Город
                                    </span>
                                    <Select
                                        className={styles.selector}
                                        options={optionsListCities}
                                        style={{ width: '100%' }}
                                        value={placeId}
                                        placeholder="Выбрать категорию"
                                        onChange={setPlaceId}
                                        allowClear
                                    />
                                </div>
                                <div className={styles.filterString}>
                                    <span className={styles.filterLabel}>
                                        ФИО заявителя
                                    </span>
                                    <TextField
                                        classes={{
                                            root: styles.textField,
                                        }}
                                        className={styles.textField}
                                        value={fio}
                                        onChange={(e) => setFio(e.target.value)}
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className={styles.filterPart}>
                                <div className={styles.filterString}>
                                    <span className={styles.filterLabel}>
                                        Мероприятие
                                    </span>
                                    <Select
                                        className={styles.selector}
                                        showSearch
                                        value={eventId}
                                        style={{ width: '100%' }}
                                        defaultActiveFirstOption={false}
                                        showArrow={true}
                                        filterOption={false}
                                        onSearch={getEventHint}
                                        onChange={setEventId}
                                        notFoundContent={null}
                                        allowClear
                                    >
                                        {optionsEventList}
                                    </Select>
                                </div>
                                <div className={styles.filterString}>
                                    <span className={styles.filterLabel}>
                                        Место
                                    </span>
                                    <Select
                                        className={styles.selector}
                                        options={optionsListPlaces}
                                        style={{ width: '100%' }}
                                        value={placeId}
                                        placeholder="Выбрать категорию"
                                        onChange={setPlaceId}
                                        allowClear
                                    />
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>

            <Button onClick={() => setModal(true)}>Выгрузить в excel</Button>

            <div className={styles.tableWrapper}>
                {loading && <MainPreloader />}

                <div
                    onScroll={handleScroll}
                    ref={scrollRef}
                    className={styles.table}
                >
                    <table>
                        <thead>
                            <tr>
                                <th>Номер заявки</th>
                                <th>ФИО заявителя</th>
                                <th>Дата создания</th>
                                <th>Примечания</th>
                                <th>Мероприятие</th>
                                <th>Место</th>
                                <th>Город</th>
                                <th>Статус</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {list &&
                                list.map((i) => (
                                    <tr key={i.id}>
                                        <td>{i.id}</td>
                                        <td>{i.fio}</td>
                                        <td>{i.createdAt.slice(0, 10)}</td>
                                        <td>{i?.notes || ''}</td>
                                        <td>{i.event.name}</td>
                                        <td>{i.event.place.name || ''}</td>
                                        <td>{i.user.city}</td>
                                        <td>{statusName[i.status]}</td>
                                        <td>
                                            <Button
                                                onClick={() => onAgree(i.id)}
                                                type={'primary'}
                                            >
                                                Принять
                                            </Button>
                                            <Button
                                                onClick={() => onDecline(i.id)}
                                                type="link"
                                                danger
                                            >
                                                Отклонить
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <Modal
                bodyStyle={{ height: '400px' }}
                width={800}
                title="Выберите поля для выгрузки"
                visible={modal}
                onOk={onImport}
                onCancel={() => setModal(false)}
                okText={'Выгрузить'}
                cancelText={'Отменить'}
            >
                <div className={styles.modalField}>
                    <Checkbox
                        onChange={(e) =>
                            setFields({ ...fields, id: e.target.checked })
                        }
                    >
                        Номер заявки
                    </Checkbox>
                    <Checkbox
                        onChange={(e) =>
                            setFields({ ...fields, fio: e.target.checked })
                        }
                    >
                        ФИО заявителя
                    </Checkbox>
                    <Checkbox
                        onChange={(e) =>
                            setFields({
                                ...fields,
                                createdAt: e.target.checked,
                            })
                        }
                    >
                        Дата создания
                    </Checkbox>
                    <Checkbox
                        onChange={(e) =>
                            setFields({ ...fields, event: e.target.checked })
                        }
                    >
                        Мероприятие
                    </Checkbox>
                    <Checkbox
                        onChange={(e) =>
                            setFields({ ...fields, place: e.target.checked })
                        }
                    >
                        Место
                    </Checkbox>
                    <Checkbox
                        onChange={(e) =>
                            setFields({ ...fields, city: e.target.checked })
                        }
                    >
                        Город
                    </Checkbox>
                    <Checkbox
                        onChange={(e) =>
                            setFields({ ...fields, status: e.target.checked })
                        }
                    >
                        Статус
                    </Checkbox>
                </div>
            </Modal>
        </div>
    )
}

export default RequestsPage
