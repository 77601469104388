import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Checkbox, ConfigProvider, DatePicker, message } from 'antd'
import { TitleAndDefinition } from '../../simples/TitleAndDefinition'
import { Select } from 'antd'
import 'moment/locale/ru'
import { Location } from '../../../services/Location'
import style from './CreateNews.module.scss'
import { CategoryNews, NewsTypeResponse } from '../../../services/CategoryNews'
import { News as NewsService } from '../../../services/News'
import {
    IPhotoArea,
    LocationResponseType,
    PhotoResponseType,
    PhotoType,
    TextEditorType,
} from '../../../types/types'
import {
    DOMAIN,
    PhotoGaleryType,
    photoSizes,
} from '../../../constants/constants'
import { TextEditor } from '../TextEditor'
import { useHistory } from 'react-router'
import { routes } from '../../../constants/config'
import { PhotoGallery } from '../PhotoGallery'
import { SaveBtnBlock } from '../../simples/SaveBtnBlock'
import Title from 'antd/lib/typography/Title'
import moment from 'moment'
import locale from 'antd/lib/locale/ru_RU'
import { isChecked, isMainPhoto } from '../../../utils/photoEditor'
import { PhotoArea } from '../PhotoArea'
import { LoadFiles } from '../../../services/FileService'

const formatDate = 'DD-MM-YYYY'
type Props = {}

const CreateNews: FC<Props> = () => {
    const nav = useHistory()
    const newsID = useHistory().location.search?.split('=')[1]

    const [publish, setPublish] = useState(false)
    const [categoryId, setCategoryId] = useState<number | undefined>()
    const [placeId, setPlaceId] = useState<number | undefined>()
    const [name, setName] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [categoryList, setCategoryList] = useState<NewsTypeResponse[]>([])
    const [newphotos, setNewPhotos] = useState<IPhotoArea[]>([])
    const [locationList, setLocationList] = useState<LocationResponseType[]>([])
    const [isTop, setIsTop] = useState<0 | 1>(0)
    const [pictureData, setPictureData] = useState<PhotoResponseType[]>([])
    const [isError, setError] = useState(false)
    const [publishedAt, setPublishedAt] = useState<string>()
    const [createNews_ID, setCreateNews_ID] = useState<number>(
        !!newsID ? +newsID : 0
    )
    const [picturePreviewData, setPicturePreviewData] = useState<
        PhotoResponseType[]
    >([])
    const [content, setContent] = useState<Array<TextEditorType>>([
        {
            id: new Date().getTime(),
            text: '',
            photos: [],
        },
    ])

    /**
     *  опции для выбора категории
     */
    const optionsList = useMemo(() => {
        return categoryList.map((item) => {
            return {
                label: item.name,
                value: item.id,
            }
        })
    }, [categoryList])
    /**
     *  опции для выбора места проведения
     */
    const optionsPlaceList = useMemo(() => {
        return locationList.map((item) => {
            return {
                label: item.name,
                value: item.id,
            }
        })
    }, [locationList])

    const getOneNews = useCallback(async () => {
        const response = await NewsService.getOneNews(+newsID)
        setName(response.name)
        setDescription(response.description)
        setPlaceId(response.placeId)
        setContent(response.content)
        setPicturePreviewData(response.previews)
        setPictureData(response.photos)
        setCategoryId(response.categoryId)
        setPublish(!!response.published)
        setIsTop(response.isTop)
        // setLink(response.link || '')
        setPublishedAt(response.publishedAt || undefined)

        if (response?.new_photos && !!response.new_photos.length) {
            setNewPhotos((prev) => [
                ...prev,
                ...response.new_photos.map((obj) => ({
                    ...obj,
                    url: DOMAIN + obj.photo_url,
                })),
            ])
        }
    }, [newsID])

    const onSubmit = async () => {
        if (categoryId === undefined || name.trim() === '') {
            setError(true)
            return
        }

        let data = {
            categoryId: Number(categoryId),
            placeId: Number(placeId),
            name,
            description,
            content: JSON.stringify(content),
            isTop,
            // link,
            publishedAt,
            new_photos: newphotos.map((photo) => ({
                photo_id: photo.photo_id,
                type: photo.type,
                is_main: photo.is_main,
            })),
        }

        const response = await NewsService.updateNews(createNews_ID, data)

        if (response.success) {
            setCategoryId(undefined)
            setPlaceId(undefined)
            setName('')
            setDescription('')
            setContent([
                {
                    id: new Date().getTime(),
                    text: '',
                    photos: [],
                },
            ])
            setPictureData([])
            setPicturePreviewData([])
            setIsTop(0)
            // setLink('')

            if (publish) {
                const responsePublish = await NewsService.publishEvent(
                    createNews_ID
                )

                if (!responsePublish) {
                    message.warning('Событие создано, но не опубликовано ')
                }
            } else {
                const responseUnPublish = await NewsService.unPublishEvent(
                    createNews_ID
                )
                if (responseUnPublish) {
                    message.warning('Новость обновлена, но не опубликовано ')
                }
            }
        }
        nav.push(routes.get_news_list)
    }

    useEffect(() => {
        if (!!newsID) {
            getOneNews().then()
        } else {
            createNews().then()
        }

        getEventCategoryList().then()
        getListPlaces().then()
    }, [newsID])

    useEffect(() => {
        setTimeout(() => {
            setError(false)
        }, 5000)
    }, [isError])

    const createNews = async () => {
        const response = await NewsService.createNews()
        setCreateNews_ID(response.id)
    }

    const getEventCategoryList = async () => {
        const list = await CategoryNews.getListNewsCategories(1, 10, 1000)
        setCategoryList(list.data)
    }

    const getListPlaces = async () => {
        const list = await Location.getListLocation(1, 10, 1000)
        setLocationList(list.data)
        return list
    }

    /**
     * загружаем фотки
     * @param photo фото
     * @param resourse куда грузим
     * @returns
     */

    const loadingPhoto = async (photo: any, resourse: PhotoGaleryType) => {
        const data = {
            file: [photo],
        }

        let response: any

        if (resourse === PhotoGaleryType.MAIN) {
            response = await NewsService.uploadPhoto(createNews_ID, data)

            if (response.success) {
                setPictureData((prev) => [...prev, response.data?.events.data])
                return response.data?.events.data
            } else {
                message.error('Произошла ошибка при загрузке фотографии')
            }
        }

        if (resourse === PhotoGaleryType.PREVIEW) {
            response = await NewsService.uploadPreviewPhoto(createNews_ID, data)

            if (response.success) {
                setPicturePreviewData((prev) => [
                    ...prev,
                    response.data?.events.data,
                ])
            } else {
                message.error('Произошла ошибка при загрузке фотографии')
            }
        }

        if (resourse === PhotoGaleryType.PHOTO_LIST) {
            const { image, type, is_main } = photo

            const isMobile = isChecked(type)
            const isEmtyType = !!newphotos
                ? newphotos.find((photo) => photo.type === type)
                : false

            response = await LoadFiles(image)

            if (response.success) {
                const { data } = response
                if (!isMobile && isEmtyType) {
                    setNewPhotos((prev) =>
                        prev.map((item) =>
                            item.type === type
                                ? {
                                      url: DOMAIN + data.path,
                                      type,
                                      photo_id: data.id,
                                      is_main,
                                  }
                                : item
                        )
                    )
                } else {
                    if (!!newphotos) {
                        setNewPhotos((prev) =>
                            isMainPhoto([
                                ...prev,
                                {
                                    url: DOMAIN + data.path,
                                    type,
                                    photo_id: data.id,
                                    is_main,
                                },
                            ])
                        )
                    } else {
                        setNewPhotos(
                            isMainPhoto([
                                {
                                    url: DOMAIN + data.path,
                                    type,
                                    photo_id: data.id,
                                    is_main,
                                },
                            ])
                        )
                    }
                }
            } else {
                message.error('Произошла ошибка при загрузке фотографии')
            }
        }
    }

    const deletePhoto = async (id: number, sourse: PhotoGaleryType) => {
        let result: boolean
        if (sourse === PhotoGaleryType.MAIN) {
            result = await NewsService.removePhoto(id)

            if (result) {
                setPictureData((prev) => {
                    return prev.filter((item) => item.id !== id)
                })
                return true
            } else {
                message.error(
                    'Что-то пошло не так, не получилось удалить фотографию'
                )
                return false
            }
        } else if (sourse === PhotoGaleryType.PREVIEW) {
            result = await NewsService.removePreviewPhoto(id)

            if (result) {
                setPicturePreviewData((prev) => {
                    return prev.filter((item) => item.id !== id)
                })
            } else {
                message.error(
                    'Что-то пошло не так, не получилось удалить фотографию'
                )
            }
        } else {
            setNewPhotos((prev) =>
                isMainPhoto(prev.filter((item) => item.photo_id !== id))
            )
        }
    }

    const styleError = {
        border: isError ? '1px solid red' : '0px solid red',
    }

    const onChangeCreatedAt = (e: any) => {
        setPublishedAt(e.format().slice(0, 10))
    }

    const handleSelectMainPhoto = (id: number, type: PhotoType) => {
        setNewPhotos((prev) =>
            prev.map((photo) =>
                photo.photo_id === id && photo.type === type
                    ? { ...photo, is_main: true }
                    : photo.photo_id !== id && photo.type === type
                    ? { ...photo, is_main: false }
                    : photo
            )
        )
    }

    return (
        <>
            <Title level={2}>
                {newsID ? 'Редактирование новости' : 'Создание новости'}
            </Title>
            {isError ? (
                <Alert
                    className={style.alert}
                    message="Все поля обязательны к заполнению"
                    type="error"
                />
            ) : null}

            <div className={style.selectWrapper} style={styleError}>
                <h2 className={style.title}>Категория</h2>
                <Select
                    options={optionsList}
                    style={{ width: '100%' }}
                    value={categoryId}
                    placeholder="Выбрать категорию"
                    onChange={(id: number) => setCategoryId(id)}
                />
            </div>
            <div className={style.selectWrapper} style={styleError}>
                <h2 className={style.title}>Место проведения</h2>
                <Select
                    options={optionsPlaceList}
                    style={{ width: '100%' }}
                    value={placeId}
                    filterOption={(input, option) =>
                        (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                    }
                    showSearch
                    placeholder="Выбрать место"
                    onChange={(id: number) => setPlaceId(id)}
                />
            </div>

            <TitleAndDefinition
                isError={isError}
                firstValue={name}
                secondValue={description}
                firstTitle={'Название новости'}
                secondTitle={'Описание новости'}
                firstPlaceholder={true}
                secondPlaceholder={true}
                firstPlaceholderTitle={'Введите название новости'}
                secondPlaceholderTitle={'Введите описание новости'}
                onChangeFirst={(value) => setName(value)}
                onChangeSecond={(value) => setDescription(value)}
                onChangeTextArea={(value) => setContent(value)}
            />
            <div className={style.holder}>
                <div className={style.title}>Дата новости</div>
                <ConfigProvider locale={locale}>
                    <DatePicker
                        format={formatDate}
                        value={moment(publishedAt)}
                        onChange={onChangeCreatedAt}
                        allowClear={false}
                    />
                </ConfigProvider>
            </div>

            <TextEditor
                value={content}
                onChange={(data: Array<TextEditorType>) => setContent(data)}
                onDeletePhoto={async (id: number) =>
                    await deletePhoto(id, PhotoGaleryType.MAIN)
                }
                key={content.length}
                onUploadPhotos={(data) =>
                    loadingPhoto(data, PhotoGaleryType.MAIN)
                }
            />

            {!!pictureData.length && (
                <div style={styleError}>
                    <PhotoGallery
                        title={'Фото события'}
                        data={pictureData}
                        onUpload={(value) =>
                            loadingPhoto(value, PhotoGaleryType.MAIN)
                        }
                        isCrop={true}
                        onDelete={(id) => deletePhoto(id, PhotoGaleryType.MAIN)}
                    />
                </div>
            )}

            {!!picturePreviewData.length && (
                <div style={styleError}>
                    <PhotoGallery
                        title={'Фото для превью и для верхнего слайдера'}
                        data={picturePreviewData}
                        onUpload={(value) =>
                            loadingPhoto(value, PhotoGaleryType.PREVIEW)
                        }
                        isCrop={true}
                        onDelete={(id) =>
                            deletePhoto(id, PhotoGaleryType.PREVIEW)
                        }
                    />
                </div>
            )}

            <PhotoArea
                data={newphotos}
                onSubmit={(value) =>
                    loadingPhoto(value, PhotoGaleryType.PHOTO_LIST)
                }
                sizes={photoSizes}
                onSelectMain={handleSelectMainPhoto}
                onDelete={(id) => deletePhoto(id, PhotoGaleryType.PHOTO_LIST)}
            />

            <div className={style.priceWrapper}>
                <Checkbox
                    checked={!!isTop}
                    onChange={(e) => setIsTop(!!e.target.checked ? 1 : 0)}
                >
                    <h2 className={style.title}>Топовое место</h2>
                </Checkbox>
            </div>

            <SaveBtnBlock
                onSave={onSubmit}
                onPublish={setPublish}
                isPublish={publish}
                hasID={!!newsID}
            />
        </>
    )
}

export default CreateNews
