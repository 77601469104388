import AddLinkModal from './AddLinkModal'
import JoditEditor from 'jodit-react'
import {
    ChangeEvent,
    FC,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import produce from 'immer'
import style from './TextEditor.module.scss'
import {
    PhotoResponseType,
    PhotoType,
    TextEditorType,
} from '../../../types/types'
import { DeleteOutlined } from '@ant-design/icons'
import { AvatarCrop } from '../AvatarCrop'

const buttons = ['bold', 'underline', 'italic', '|', 'fontsize', 'link']

const config = {
    readonly: false,
    addNewLineOnDBLClick: false,
    toolbar: true,
    spellcheck: true,
    language: 'ru',
    toolbarButtonSize: 'medium',
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    defaultActionOnPaste: 'insert_as_html',
    buttons: buttons,
    uploader: {
        insertImageAsBase64URI: false,
    },
    width: '100%',
    height: '100%',
    FontSizer: 24,
    tabIndex: 1,
    enter: 'P',
}

type OneBlock = {
    id: number
    text: string
    isQuote?: boolean
    isStrong?: boolean
    fontSize?: number
    photos: Array<{
        id: number
        url: string
    }>
}

type Props = {
    onUploadPhotos: (file: File) => Promise<PhotoResponseType> | undefined
    onDeletePhoto: (id: number) => Promise<boolean | undefined>
    onChange: (content: Array<TextEditorType>) => void
    value: Array<TextEditorType>
}

/**
 *
 * @param onUploadPhotos - загрузка фотографий возвращает id and url
 * @param onDeletePhoto - удаление фото
 * @param onChange - изменение блока
 * @param value - значения для редактирования
 * @returns - компонент создания контента с подгрузкой фотографий
 */

const TextEditor: FC<Props> = ({
    onUploadPhotos,
    onDeletePhoto,
    onChange,
    value,
}) => {
    const inputRef = useRef<any>()
    const cursorPositionRef = useRef<number>(0)
    const [block, setBlock] = useState<OneBlock[]>(value)
    const [addModal, setAddModal] = useState<boolean>(false)
    const [activeEditor, setActiveEditor] = useState(false)
    const [file, setFile] = useState<File>()
    const [itemId, setItemId] = useState(0)

    /**
     * добавляем фото
     */

    const openEditor = (
        { target }: ChangeEvent<HTMLInputElement>,
        id: number
    ) => {
        const file = target.files && target.files[0]

        if (file) {
            setFile(file)
            setActiveEditor(true)
            setItemId(id)
        }
    }

    const addPhotos = async (data: any) => {
        const response = await onUploadPhotos(data)

        if (response) {
            setBlock(
                produce((draft) => {
                    const bl = draft.find((el) => el.id === itemId)

                    if (bl)
                        bl.photos = [
                            ...bl.photos,
                            {
                                id: response.id,
                                url: response.url,
                            },
                        ]
                })
            )
        }
    }

    /**
     * изменяем текст
     */
    const onChangeTextArea = useCallback((e: string, id: number) => {
        setBlock(
            produce((draft) => {
                const block = draft.filter((el, ind) => el.id === id)[0]
                block.text = e
            })
        )
    }, [])

    /**
     * add link into text
     */
    const onAddLink = useCallback(
        (e: string, id: number) => {
            setBlock(
                produce((draft) => {
                    const block = draft.filter((el, ind) => el.id === id)[0]
                    const startText = block.text.slice(
                        0,
                        cursorPositionRef.current + 1
                    )
                    const endText = block.text.slice(cursorPositionRef.current)
                    const allText = startText + e + endText
                    block.text = allText
                })
            )
        },
        [cursorPositionRef.current]
    )

    /**
     * Add block
     */
    const onAddBlock = useCallback(() => {
        setBlock(
            produce((draft) => {
                draft.push({
                    id: new Date().getTime(),
                    text: '',
                    isQuote: false,
                    isStrong: false,
                    fontSize: 16,
                    photos: [],
                })
            })
        )
    }, [])

    /**
     * Удаляем фото
     * @param id фото
     * @param fileId фото в объекте
     */

    const deletePhoto = async (id: number, fileId: number) => {
        const response = await onDeletePhoto(fileId)
        if (response) {
            setBlock(
                produce((draft) => {
                    const block = draft.filter((el) => el.id === id)[0]
                    block.photos = block.photos.filter((i) => i.id !== fileId)
                })
            )
        }
    }

    /**
     * удаляем блок
     * @param id блока
     */
    const deleteBlock = (id: number) => {
        setBlock((prev) => prev.filter((b) => b.id !== id))
    }

    useEffect(() => {
        setBlock(value)
    }, [value])

    useEffect(() => {
        onChange(block)
    }, [block])

    /**
     * вывод компонента
     */

    return (
        <div className={style.inputContainer} style={{}}>
            <h2 className={style.title}>Содержание</h2>
            <div className={style.container}>
                <div className={style.inputBlock}>
                    {block.map((item, index) => {
                        return (
                            <div key={item.id}>
                                <div className={style.textBlock}>
                                    <JoditEditor
                                        config={config as any}
                                        onChange={(e) => {
                                            onChangeTextArea(e, item.id)
                                        }}
                                        value={item.text}
                                    />
                                    {block.length > 1 && (
                                        <div className={style.delBlock}>
                                            <DeleteOutlined
                                                style={{
                                                    fontSize: '30px',
                                                    color: '#1890ff',
                                                }}
                                                onClick={() =>
                                                    deleteBlock(item.id)
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className={style.imgHolder}>
                                    {item.photos.length > 0 &&
                                        item.photos.map((file, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={style.imgBlock}
                                                >
                                                    <div className="">
                                                        <img
                                                            style={{
                                                                maxWidth:
                                                                    '100%',
                                                                maxHeight:
                                                                    '100px',
                                                                objectFit:
                                                                    'contain',
                                                            }}
                                                            alt={'ttttt'}
                                                            src={`${process.env.REACT_APP_API_URL}/${file.url}`}
                                                        />
                                                    </div>
                                                    <DeleteOutlined
                                                        style={{
                                                            fontSize: '30px',
                                                            color: '#1890ff',
                                                        }}
                                                        onClick={() =>
                                                            deletePhoto(
                                                                item.id,
                                                                file.id
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )
                                        })}
                                    {}
                                </div>
                                <div className={style.header}>
                                    {index === block.length - 1 && (
                                        <div
                                            onClick={onAddBlock}
                                            className={style.addButton}
                                        >
                                            Добавить блок
                                        </div>
                                    )}
                                    {item.photos.length === 0 && (
                                        <label className={style.addButton}>
                                            Добавить фото
                                            <input
                                                id={`input_${item.id}`}
                                                style={{
                                                    width: 0,
                                                    height: 0,
                                                }}
                                                accept=".png, .jpg, .jpeg"
                                                ref={inputRef}
                                                type={'file'}
                                                onChange={(e) =>
                                                    openEditor(e, item.id)
                                                }
                                            />
                                        </label>
                                    )}
                                </div>
                                <AddLinkModal
                                    link={
                                        '<a href="https://google.com">Text</a>'
                                    }
                                    onChange={function (link: string): void {
                                        onAddLink(link, item.id)
                                    }}
                                    isVisible={addModal}
                                    onClose={function (): void {
                                        setAddModal(false)
                                    }}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>

            {activeEditor && (
                <AvatarCrop
                    onSubmit={() => null}
                    onClose={setActiveEditor.bind(null, false)}
                    image={file}
                    sizes={[
                        { width: 910, height: 390, type: PhotoType.SIMPLE },
                    ]}
                    oneSubmit={addPhotos}
                />
            )}
        </div>
    )
}

export default TextEditor
