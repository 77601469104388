import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { Button } from 'antd'
import AvatarEditor from 'react-avatar-editor'
import styles from './AvatarCrop.module.scss'
import { Rotate } from '../../../utils/photoEditor'
import { PhotoType } from '../../../types/types'

export type onReadyImg = {
    image: File
    type: PhotoType
    is_main?: boolean
}

type Size = {
    width: number
    height: number
    type: PhotoType
}

interface IProps {
    image: File
    size: Size
    onReady: (obj: onReadyImg) => void
}

const Editor: FC<IProps> = ({ onReady, image, size }) => {
    const editor = useRef<AvatarEditor>(null)
    const [config, setConfig] = useState({
        allowZoomOut: false,
        position: { x: 0.5, y: 0.5 },
        scale: 1,
        color: [80, 80, 80, 0.6], // RGBA
        rotate: 0,
        borderRadius: 1,
    })

    const handleRotate = (type: Rotate) => {
        type === Rotate.Left
            ? setConfig((prev) => ({ ...prev, rotate: config.rotate - 90 }))
            : setConfig((prev) => ({ ...prev, rotate: config.rotate + 90 }))
    }

    const handleZoom = (e: ChangeEvent<HTMLInputElement>) => {
        setConfig((prev) => ({ ...prev, scale: +e.target.value }))
    }

    const handlePosition = (position: any) => {
        setConfig((prev) => ({ ...prev, position }))
    }

    const getImageFile = () => {
        const dataUrl = editor.current?.getImage().toDataURL()
        if (!dataUrl) return
        const file = fetch(dataUrl)
            .then((r) => r.blob())
            .then(
                (blobFile) =>
                    new File([blobFile], `image_${Date.now()}.png`, {
                        type: 'image/png',
                    })
            )

        return file
    }

    function init() {
        setTimeout(() => {
            handleSave()
        }, 100)
    }

    const handleSave = async () => {
        const image = await getImageFile()

        if (!image) return
        onReady({ image, type: size.type })
    }

    useEffect(() => {
        init()
    }, [config])

    return (
        <div
            className={
                size.type === PhotoType.SIMPLE ? styles.simple : styles.editor
            }
        >
            <span className={styles.title}>Отредактируйте фото</span>
            <AvatarEditor
                ref={editor}
                image={image}
                width={size.width}
                height={size.height}
                position={config.position}
                borderRadius={config.borderRadius}
                color={config.color}
                scale={parseFloat(config.scale + '')}
                rotate={config.rotate}
                onPositionChange={handlePosition}
            />

            <ul className={styles.actions}>
                <li>
                    Увеличение{' '}
                    <input
                        onChange={handleZoom}
                        type="range"
                        title="Zoom"
                        min="0.2"
                        max="2"
                        step="0.01"
                        defaultValue="0.5"
                        value={config.scale}
                    />
                </li>
                <li>
                    Поворот:{' '}
                    <div className={styles.btnRotate}>
                        <Button onClick={handleRotate.bind(null, Rotate.Left)}>
                            В лево
                        </Button>{' '}
                        <Button onClick={handleRotate.bind(null, Rotate.Right)}>
                            В право
                        </Button>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default Editor
