import { IPhotoArea, PhotoType, ResponseNewPhotos } from '../types/types'

export const titleOfPhotoType = (type: PhotoType) => {
    let title = ''
    switch (type) {
        case PhotoType.PREVIEW:
            title = 'Превью на сайте'
            break
        case PhotoType.BIG_PREVIEW:
            title = 'Широкое превью на сайте'
            break
        case PhotoType.MAIN_PHOTO:
            title = 'Основное изображение на сайте'
            break
        case PhotoType.MOBILE_PREVIEW:
            title = 'Превью в МП'
            break
        case PhotoType.MOBILE_MIN_PREVIEW:
            title = 'Маленькое превью в МП'
            break
        case PhotoType.MOBILE_HEAD_PHOTOS:
            title = 'Фото в заголовке в МП'
            break
        case PhotoType.MOBILE_PHOTOS:
            title = 'Фото события в МП'
            break
        case PhotoType.CAROUSEL_PREVIEW:
            title = 'Превью в карусель на сайте'
            break
        default:
            title = ''
    }

    return title
}

export enum Rotate {
    Left = 'left',
    Right = 'right',
}

export const isChecked = (type: PhotoType) => {
    return (
        type === PhotoType.MOBILE_HEAD_PHOTOS ||
        type === PhotoType.MOBILE_PHOTOS
    )
}

export function isMainPhoto(arr: IPhotoArea[]) {
    let newArr = [...arr]

    if (newArr.length === 1) {
        return newArr.map((photo) => ({ ...photo, is_main: true }))
    }

    const indexHead = newArr.findIndex(
        (item) => item.type === PhotoType.MOBILE_HEAD_PHOTOS
    )

    const indexPhotos = newArr.findIndex(
        (item) => item.type === PhotoType.MOBILE_PHOTOS
    )

    const indexIsMainMobileHead = newArr
        .filter((photo) => photo.type === PhotoType.MOBILE_HEAD_PHOTOS)
        .findIndex((item) => item.is_main)

    const indexIsMainMobilePhotos = newArr
        .filter((photo) => photo.type === PhotoType.MOBILE_PHOTOS)
        .findIndex((item) => item.is_main)

    if (indexIsMainMobileHead < 0) {
        newArr = newArr.map((photo, i) =>
            i === indexHead ? { ...photo, is_main: true } : photo
        )
    }

    if (indexIsMainMobilePhotos < 0) {
        newArr = newArr.map((photo, i) =>
            i === indexPhotos ? { ...photo, is_main: true } : photo
        )
    }

    return newArr
}
export function getPrewiewPhoto(arr: ResponseNewPhotos[]) {
    const preview = arr.find((photo) => photo.type === PhotoType.PREVIEW)
    const path = !!preview ? preview.photo_url : arr[0].photo_url
    return path
}
