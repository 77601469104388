import { CloseOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import Title from 'antd/lib/typography/Title'
import React, { FC } from 'react'
import { SocialNetworks } from '../../../types/types'
import { TitleAndDefinition } from '../../simples/TitleAndDefinition'
import { PhotoGallery } from '../PhotoGallery'
import styles from './CreateSocialNetwork.module.scss'


type Props = {
    onChange: () => void
    data: SocialNetworks
    onDelete: () => void
}

/**
 * 
 * @param onChange 
 * @param data 
 * @param onDelete 
 * @returns 
 */

const SocialItem: FC<Props> = ({ onChange, data, onDelete }) => {

    return (
        <div className={styles.wrapSocial}>
             <div className={styles.image}>
                    <img alt="" src={`${process.env.REACT_APP_API_URL}/${data.photo.url}`} />
                </div>
            <div className={styles.nameWrapper}>
                {/* <div className={styles.label}>Название сети</div> */}
                <div className={styles.socName}>{data.name}</div>
            </div>
            <Button style={{
                marginRight: '20px'
            }}
                onClick={onChange}>Изменить</Button>
            <DeleteOutlined
                style={{
                    color: 'red'
                }}
                onClick={onDelete}
                className={styles.closeIcon}
            />
        </div>
    )
}

export default React.memo(SocialItem)