import { ExportType } from '../components/complexes/RequestsPage/RequestsPage'
import { endpoints } from '../constants/constants'
import { RequestStatus, RequestType } from '../types/types'
import api from '../utils/api'
import { route } from '../utils/route'

export class RequestService {
    static async getRequestList(data: {
        status?: RequestStatus
        offset?: number
        limit?: number
        cityId?: number
        fio?: string
        createdAt?: string
        updatedAt?: string
        eventId?: number
        placeId?: number
    }): Promise<RequestType[]> {
        let query = []
        const sData: any = data
        for (let key in sData) {
            if (sData[key]) {
                query.push(`${key}=${sData[key]}`)
            }
        }
        const response = await api.get(
            `${endpoints.requestList}?${query.join('&')}`
        )

        return response.data.data
    }

    static async getXls(
        data: ExportType,
        filterData?: {
            status?: RequestStatus
            offset?: number
            limit?: number
            cityId?: number
            fio?: string
            createdAt?: string
            updatedAt?: string
            eventId?: number
            placeId?: number
        }
    ) {
        let query = []
        const queryData: any = filterData
        for (let key in queryData) {
            if (queryData[key]) {
                query.push(`${key}=${queryData[key]}`)
            }
        }

        const formData = new FormData()

        const sd: Array<string> = []
        let sData: any = data
        for (let key in sData) {
            if (sData[key]) {
                sd.push(key)
            }
        }

        formData.append('fields', sd.join(','))

        const response = await api.post(
            `${endpoints.requestXls}?${query.join('&')}`,
            formData
        )

        return response.data.data
    }

    static async changeStatus(
        id: number,
        status: RequestStatus
    ): Promise<RequestType> {
        const response = await api.post(
            route(endpoints.requestChangeStatus, { id, status })
        )
        return response.data.data
    }
}
