import React, { FC, useEffect, useState } from 'react'
import { List } from 'antd'
import produce from 'immer'

import styles from './ListNews.module.scss'
import { News, News as NewsService } from '../../../services/News'
import { NewsResponseType } from '../../../types/types'
import { ListItem } from '../ListItem'
import { useHistory } from 'react-router'
import { routes } from '../../../constants/config'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import {
    selectActivePage,
    setNewsPage,
    setOnPage,
    setTotalCounts,
} from '../../../store/slice/app'
import Title from 'antd/lib/typography/Title'
import { ListHolder } from '../../simples/ListHolder'
import { getPrewiewPhoto } from '../../../utils/photoEditor'

type Props = {}

const ListNews: FC<Props> = () => {
    const dispatch = useAppDispatch()
    const activePage = useAppSelector((state) => state.app.newsPage)

    const nav = useHistory()
    const [list, setList] = useState<NewsResponseType[]>([])

    const [removedStatus, setRemovedStatus] = useState(false)
    const [totalCount, setTotalCount] = useState<number>(0)
    const [onPage, setOnPage] = useState<number>(10)

    const getList = async () => {
        const list = await NewsService.getListNews(activePage)
        setTotalCount(list.meta.maxCount)
        setOnPage(10)
        setList(list.data)
    }

    const removeCategory = async (id: number) => {
        const response = await NewsService.deleteNews(id)
        if (response) {
            setRemovedStatus(true)
        }
    }

    useEffect(() => {
        getList().then()
        setRemovedStatus(false)
    }, [removedStatus, activePage])

    /**
     * публикация
     */
    const onPublish = async (id: number) => {
        const response = await News.publishEvent(id)
        if (response) {
            setList(
                produce((draft) => {
                    const find = draft.filter((item) => item.id === id)[0]
                    find.published = 1
                })
            )
        }
    }

    /**
     * снятие с публикации
     */
    const onUnPublish = async (id: number) => {
        const response = await News.unPublishEvent(id)
        if (response) {
            setList(
                produce((draft) => {
                    const find = draft.filter((item) => item.id === id)[0]
                    find.published = 0
                })
            )
        }
    }

    return (
        <ListHolder
            currentPage={activePage}
            totalCount={totalCount}
            onPageChange={(page) => dispatch(setNewsPage(page))}
            onPageShow={setOnPage}
        >
            <Title level={2}>Список новостей</Title>
            <List
                className={styles.itemsWrapper}
                itemLayout="horizontal"
                dataSource={list}
                renderItem={(item) => (
                    <>
                        <List.Item className={styles.item}>
                            <ListItem
                                photoPath={
                                    !!item.new_photos?.length
                                        ? getPrewiewPhoto(item.new_photos)
                                        : item.previews[0]?.url || ''
                                }
                                name={item.name}
                                description={item.description}
                                onPublish={() => onPublish(item.id)}
                                onUnPublish={() => onUnPublish(item.id)}
                                onChange={() =>
                                    nav.push(
                                        `${routes.create_news}?newsID=${item.id}`
                                    )
                                }
                                onDelete={() => removeCategory(item.id)}
                                isPublish={!!item.published}
                            />
                        </List.Item>
                    </>
                )}
            />
        </ListHolder>
    )
}

export default ListNews
