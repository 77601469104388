import React, { useEffect } from 'react'
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom'
import styles from './App.module.scss'
import { routes } from '../../constants/config'
import { PrivateRoute } from '../simples/PrivateRoute'
import Auth from '../pages/Auth/Auth'

import { Home } from '../pages/Home'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import {
    checkUser,
    selectAuthChecking,
    selectIsAuth,
} from '../../store/slice/auth'
import { MainPreloader } from '../simples/MainPreloader'
import {
    fetchCities,
    fetchEventCategories,
    fetchPlaces,
} from '../../store/slice/storage'

const App = () => {
    const preloading = useAppSelector(selectAuthChecking)
    const dispatch = useAppDispatch()

    const isAuth = useAppSelector(selectIsAuth)

    useEffect(() => {
        dispatch(checkUser())
        dispatch(fetchEventCategories())
        dispatch(fetchPlaces())
        dispatch(fetchCities())
    }, [dispatch, isAuth])

    if (preloading) {
        return <MainPreloader />
    }

    return (
        <div className={styles.app}>
            <Router>
                <Switch>
                    <PrivateRoute path={routes.home}>
                        <Home />
                    </PrivateRoute>

                    <Route path={routes.login} exact>
                        <Auth />
                    </Route>
                    <Redirect to={routes.home} />
                </Switch>
            </Router>
        </div>
    )
}

export default App
