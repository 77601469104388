import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../index'

/**
 * Начальное состояние редьюсера Auth/
 */

export const initialState = {
    
        pageCount: 0,
        totalCount: 50,
        activePage: 1,
        onPage: 10,
        eventPage: 1,
        newsPage: 1,
        articlePage: 1,
        locationPage: 1,

    
}



/**
 * Создание слайса App
 */
export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setTotalCounts: (state, action) => {
            state.totalCount = action.payload
        },
        setActivePage: (state, action) => {
            state.activePage = action.payload
        },
        setEventPage: (state, action) => {
            state.eventPage = action.payload
        },
        setNewsPage: (state, action) => {
            state.newsPage = action.payload
        },
        setArticlePage: (state, action) => {
            state.articlePage = action.payload
        },
        setLocationPage: (state, action) => {
            state.locationPage = action.payload
        },
        setOnPage: (state, action) => {
            state.onPage = action.payload
        },
    },
  
})

export const selectTotalCount = (state: RootState) => state.app.totalCount
export const selectActivePage = (state: RootState) => state.app.activePage
export const selectOnPage = (state: RootState) => state.app.onPage



export const {
    setTotalCounts,
    setActivePage,
    setOnPage,
    setEventPage,
    setArticlePage,
    setLocationPage,
    setNewsPage
} = appSlice.actions


// -------------------------------------------
// Export a reducer
// -------------------------------------------
export default appSlice.reducer
