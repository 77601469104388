import { message } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { List } from 'antd'
import produce from 'immer'
import { SpecialProject as SpecialProjectService } from '../../../services/SpecialProject'
import { SpecialProjectResponseType } from '../../../types/types'
import styles from './ListSpecialProject.module.scss'
import { useHistory } from 'react-router-dom'
import { ListItem } from '../ListItem'
import { routes } from '../../../constants/config'
import Title from 'antd/lib/typography/Title'
import { ListHolder } from '../../simples/ListHolder'
import { getPrewiewPhoto } from '../../../utils/photoEditor'

type Props = {}

const ListSpecialProject: FC<Props> = () => {
    const nav = useHistory()
    const [list, setList] = useState<SpecialProjectResponseType[]>([])

    const [removedStatus, setRemovedStatus] = useState(false)

    const [totalCount, setTotalCount] = useState<number>(0)
    const [onPage, setOnPage] = useState<number>(10)

    const [page, setPage] = useState<number>(1)

    /**
     * получаем список
     */
    const getList = async () => {
        const list = await SpecialProjectService.getListSpecialProject(page)
        setTotalCount(list.meta.maxCount)
        setOnPage(10)
        setList(list.data)
    }

    const removeProject = async (id: number) => {
        const response = await SpecialProjectService.deleteSpecialProject(id)

        if (response) {
            setRemovedStatus(true)
        } else {
            message.error('Не вышло удалить картинку')
        }
    }

    useEffect(() => {
        getList().then()
        setRemovedStatus(false)
    }, [removedStatus, page])

    /**
     * публикация
     */
    const onPublish = async (id: number) => {
        const response = await SpecialProjectService.publishSpecialProject(id)
        if (response) {
            setList(
                produce((draft) => {
                    const find = draft.filter((item) => item.id === id)[0]
                    find.published = 1
                })
            )
        }
    }

    /**
     * снятие с публикации
     */
    const onUnPublish = async (id: number) => {
        const response = await SpecialProjectService.unPublishSpecialProject(id)
        if (response) {
            setList(
                produce((draft) => {
                    const find = draft.filter((item) => item.id === id)[0]
                    find.published = 0
                })
            )
        }
    }

    return (
        <ListHolder
            totalCount={totalCount}
            onPageChange={setPage}
            onPageShow={setOnPage}
        >
            <Title level={2}>Список проектов</Title>
            <List
                className={styles.itemsWrapper}
                bordered={true}
                itemLayout="horizontal"
                dataSource={list}
                renderItem={(item) => (
                    <>
                        <List.Item className={styles.item}>
                            <ListItem
                                photoPath={
                                    !!item.new_photos?.length
                                        ? getPrewiewPhoto(item.new_photos)
                                        : item.photos[0]?.url || ''
                                }
                                name={item.title}
                                description={item.description || ''}
                                onPublish={() => onPublish(item.id)}
                                onUnPublish={() => onUnPublish(item.id)}
                                onChange={() =>
                                    nav.push(
                                        `${routes.special_projects}?projectID=${item.id}`
                                    )
                                }
                                onDelete={() => removeProject(item.id)}
                                isPublish={!!item.published}
                            />
                        </List.Item>
                        <div className={styles.borderline} />
                    </>
                )}
            />
        </ListHolder>
    )
}

export default ListSpecialProject
