import { Layout } from 'antd'
import React, { FC } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Aside } from '../../complexes/Aside'
import { Main } from '../../complexes/Main'


type Props = RouteComponentProps & {}

const Home: FC<Props> = () => {
    return (
        <Layout style={{ minHeight: '100vh', flexDirection: 'row'}}>
                <Aside/>
                <Main/>
        </Layout>
    )
}

export default withRouter(Home)
