import { FC, useEffect, useState } from 'react'
import { ListHolder } from '../../simples/ListHolder'
import { Userstable } from '../UsersTable'
import Title from 'antd/lib/typography/Title'
import { User as UserSevice } from '../../../services/User'
import { User as UserType } from '../../../types/types'
import styles from './Users.module.scss'
import { MainPreloader } from '../../simples/MainPreloader'

const Users: FC = () => {
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [onPage, setOnPage] = useState(10)
    const [data, setData] = useState({ usersCount: 0, users: [] as UserType[] })

    const handleChangePage = (num: number) => setPage(num)

    const loadUsers = async () => {
        setLoading(true)
        try {
            const data = await UserSevice.getUserList(page - 1)
            setData(data)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        loadUsers().then()
    }, [page])

    return (
        <ListHolder
            currentPage={page}
            totalCount={data.usersCount}
            onPageChange={handleChangePage}
            onPageShow={setOnPage}
        >
            {loading && <MainPreloader />}

            <div className={styles.root}>
                <Title level={2}>Всего пользователей: {data.usersCount}</Title>
                <Userstable users={data.users} />
            </div>
        </ListHolder>
    )
}

export default Users
