import { Button, Select } from 'antd';
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../constants/config';
import { CityService } from '../../../services/CityService';
import { TextField } from '../../simples/TextField';
import styles from './EditCityPage.module.scss'

const { Option } = Select;

const EditCityPage: FC = (): JSX.Element => {

    const nav = useHistory()
    const cityID = useHistory().location.search?.split('=')[1]


    const [error, setError] = useState<{
        name: string
        lat: string
        lon: string
    }>({
        name: '',
        lat: '',
        lon: ''
    })

    const [name, setName] = useState<string>('')
    const [longitude, setLongitude] = useState<string>('')
    const [latitude, setLatitude] = useState<string>('')

    const [search, setSearch] = useState<string>('')

    const [placeList, setPlacelist] = useState<Array<string>>([])


      /**
 *  опции для выбора места
 */
       const optionsPlaceList = useMemo(() => {
        return placeList.map((item) => {
            return <Option key={item} value={item} >{item}</Option>
        
        })
    }, [placeList])

    const getPlaceHint = async (search: string) => {
       try {
        const response = await CityService.getHints(search)
        setPlacelist(response)
       } catch (error) {
           console.log(error);
           
       }
        // setPlacelist(response)
    }

    const getCityById = useCallback(async () => {
        const response = await CityService.getCityById(cityID)

        setName(response.name)
    }, [cityID])

    useEffect(() => {
        if (cityID) {
            getCityById().then()
        }
    }, [cityID])

    // const setCoordLat = useCallback((value: string) => {
    //     if (value.match(/[0-9]/)) {
    //         setLatitude(value)
    //     }
    // }, [])
    // const setCoordLon = useCallback((value: string) => {
    //     if (value.match(/[0-9]./)) {
    //         setLongitude(value)
    //     }
    // }, [])

    const onSave = useCallback(async () => {
        if (!name) {
            setError({ ...error, name: 'Это поле обязательное' })
        }
        if (cityID) {
            try {
                const response = await CityService.updateCity(cityID, {
                    name, latitude, longitude
                })
                nav.push(routes.cities)
            } catch (error) {

            }
        } else {
            try {
                const response = await CityService.createCity({
                    name, latitude, longitude
                })
                nav.push(routes.cities)
            } catch (error) {

            }
        }
    }, [cityID, error, latitude, longitude, name, nav])

    return (
        <div className={styles.root}>
            <div className={styles.itemHolder}>
                {/* <TextField
                    value={name}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                    label={'Название города'}
                /> */}

                <Select
                    showSearch
                    value={name}
                    style={{ width: '100%' }}
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    filterOption={false}
                    onSearch={getPlaceHint}
                    onChange={setName}
                    notFoundContent={null}
                >
                    {optionsPlaceList}
                </Select>
            </div>
            <div className={styles.itemHolder}>
                <div className={styles.coords}>
                    <TextField
                        classes={{
                            root: styles.textField
                        }}
                        value={longitude}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setLongitude(e.target.value)}
                        label={'Долгота'}
                    />
                    <TextField
                        classes={{
                            root: styles.textField
                        }}
                        value={latitude}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setLatitude(e.target.value)}
                        label={'Широта'}
                    />
                </div>
            </div>

            <Button

                onClick={onSave}>{cityID ? 'Сохранить изменения' : 'Добавить город'}</Button>

        </div>
    )
}

export default EditCityPage;
