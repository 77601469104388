import { Button, List, message, Modal } from 'antd'
import Title from 'antd/lib/typography/Title'
import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FeedbackService } from '../../../services/FeedbackService'
import { FeedbackType } from '../../../types/types'
import { ListHolder } from '../../simples/ListHolder'
import { StarRating } from '../../simples/StarRating'
import styles from './EventFeedbacks.module.scss'
import Item from './Item'


const EventFeedbacks: FC = () => {

    const eventID = useHistory().location.search?.split('=')[1]

    const [list, setList] = useState<FeedbackType[]>([])
    const nav = useHistory()

    const [viewFeedback, setViewFeedback] = useState<FeedbackType>()


    const [totalCount, setTotalCount] = useState<number>(0)
    const [onPage, setOnPage] = useState<number>(10)

    const [page, setPage] = useState<number>(1)

    const getList = async () => {

        let list
        if (eventID) {
            list = await FeedbackService.getFeedbackEventListById(+eventID, page)
        } else {
            list = await FeedbackService.getEventFeedbackFullList(page)
        }

        setTotalCount(list.meta.maxCount)
        setOnPage(10)
        setList(list.data)
    }

    /**
    * публикация
    */
    const onPublish = async (id: number) => {
        try {
            const response = await FeedbackService.publishFeedbackEvent(id)
            if (response) {
                setList(
                    produce((draft) => {
                        const find = draft.filter((item) => item.id === id)[0]
                        find.published = 1
                    })
                )
            }
            setViewFeedback(undefined)
            message.success('Отзыв принят!')
        } catch (error) {
            message.error('Ошибка! Попробуйте позднее!')
        }
    }

    /**
     * снятие с публикации
     */
    const onUnPublish = async (id: number) => {
        try {
            const response = await FeedbackService.unPublishEventFeedback(id)
            if (response) {
                setList(
                    produce((draft) => {
                        const find = draft.filter((item) => item.id === id)[0]
                        find.published = 0
                    })
                )
            }
            setViewFeedback(undefined)
            message.success('Отзыв отклонен!')
        } catch (error) {
            message.error('Ошибка! Попробуйте позднее!')
        }
    }

    /**
     * просмотреть отзыв
     */
    const onViewFeedback = (id: number) => {
        const find = list.find((item) => item.id === id)
        setViewFeedback(find)
    }

    const removeFeedback = async (id: number) => {
        const response = await FeedbackService.deleteFeedbackEvent(id)

        if (response) {
            setList((prev) => prev.filter((item) => item.id !== id))
        }

    }

    useEffect(() => {
        getList().then()
    }, [eventID, page])


    return (
        <>
            <ListHolder
                totalCount={totalCount}
                onPageChange={setPage}
                onPageShow={setOnPage}
            >
                <Title level={2}>Список отзывов</Title>
                <List
                    // className={styles.itemsWrapper}
                    bordered={true}
                    itemLayout="horizontal"
                    dataSource={list}
                    renderItem={item => (
                        <>
                            <List.Item>
                                <Item
                                    userPhoto={item.userPhoto || ''}
                                    name={item.title}
                                    content={item.content}
                                    onPublish={() => onPublish(item.id)}
                                    onUnPublish={() => onUnPublish(item.id)}
                                    onChange={() => onViewFeedback(item.id)}
                                    onDelete={() => removeFeedback(item.id)}
                                    isPublish={!!item.published} title={''}

                                />
                            </List.Item>
                        </>
                    )}
                />
            </ListHolder>
            <Modal
                bodyStyle={{ height: '400px' }}
                width={800}
                title={`Отзыв`}
                visible={!!viewFeedback?.id}
                onCancel={() => setViewFeedback(undefined)}
                cancelText={'Отмена'}
                footer={[
                    <Button key="back" onClick={() => setViewFeedback(undefined)}>
                        Закрыть
                    </Button>,
                    <Button
                        style={{
                            backgroundColor: '#a7c957',
                            borderColor: '#a7c957'
                        }}
                        key="submit"
                        type="primary"
                        onClick={() => onPublish(viewFeedback?.id || 0)}>
                        Принять отзыв
                    </Button>,
                    <Button style={{
                        backgroundColor: '#d62828',
                        borderColor: '#d62828'
                    }}
                        key="submit"
                        type="primary"
                        onClick={() => onUnPublish(viewFeedback?.id || 0)}>
                        Отклонить отзыв
                    </Button>,

                ]}

            >
                <label>


                </label>
                <label>

                    <StarRating
                        isEdit={false}
                        rate={viewFeedback?.rating || 0}
                        onChange={() => { }}
                        number={5}
                    />
                    <div className="">
                        <span>Отзыв на</span>
                        <span>{viewFeedback?.placeName}</span>

                    </div>
                    <div>
                        <div className={styles.img}>
                            <img

                                src={`${process.env.REACT_APP_API_URL}/${viewFeedback?.userPhoto}`}
                                alt="фото отсутствует"
                            />
                        </div>
                        <div className=""><span>Имя: </span>{viewFeedback?.userName}</div>
                    </div>
                    <div className={styles.infoBlock}><span>Заголовок:</span>{viewFeedback?.title}</div>
                    <div></div>
                    <div className={styles.infoBlock}><span>Отзыв:</span>{viewFeedback?.content}</div>
                </label>

            </Modal>
        </>
    )
}


export default EventFeedbacks