
import { List, message } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { CityService } from '../../../services/CityService';
import { CityType, MetaType } from '../../../types/types';
import { ListHolder } from '../../simples/ListHolder';
import ListItem from './ListItem';
import styles from './ListCity.module.scss'
import { useHistory } from 'react-router-dom';
import { routes } from '../../../constants/config';
import produce from 'immer';


const ListCity: FC = (): JSX.Element => {
    const nav = useHistory()

    const [list, setList] = useState<CityType[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [onPage, setOnPage] = useState<number>(10)
    const [page, setPage] = useState<number>(1)


    /**
 * получения списка записей
 */
    const getList = async () => {
        const list = await CityService.getCityList(page)
        setTotalCount(list.meta.maxCount)
        setList(list.data)
    }

    useEffect(() => {
        getList().then()
    }, [page])

    /**
     * Клик по городу в списке
     */
    const onCityClick = useCallback((id: number) => {
        console.log(id);

    }, [])

    const goToEditPage = useCallback((id: number) => {
        nav.push(`${routes.editCities}?id=${id}`)
    }, [nav])

    const onDeleteCity = useCallback(async (id: number) => {
        try {
            const response = await CityService.deleteCity(id)

            if (response.success) {
                setList(prev => prev.filter(i => i.id !== id))
            }

            message.success('Город был удален')

        } catch (error) {
            message.error('Ошибка при удалении города')
        }
    }, [])

    return (
        <ListHolder
            totalCount={totalCount}
            onPageChange={setPage}
            onPageShow={setOnPage}
        >
            <Title level={2}>Список городов</Title>
            <List
                className={styles.itemsWrapper}
                bordered={false}
                itemLayout="horizontal"
                dataSource={list}
                split
                renderItem={item => (
                    <>
                        <List.Item className={styles.item}>
                            <ListItem
                                city={item}
                                onClick={onCityClick}
                                onChange={goToEditPage}
                                onDelete={onDeleteCity} />
                        </List.Item>
                    </>
                )}
            />
        </ListHolder>

    )
}
export default ListCity;
