import classNames from 'classnames'
import { FC, useState } from 'react'
import { PhotoType, SizeType } from '../../../types/types'
import styles from './AvatarCrop.module.scss'
import { onReadyImg } from './Editor'
import EditorList from './EditorList'

interface Props {
    sizes: SizeType[]
    image: any
    onSubmit: (values: onReadyImg) => void
    onClose: () => void
    oneSubmit?: (file: File) => void
    classes?: {
        root?: string
    }
}

const AvatarCrop: FC<Props> = ({
    image,
    classes,
    onSubmit,
    onClose,
    sizes,
    oneSubmit,
}) => {
    const [files, setFiles] = useState<onReadyImg[]>([])
    const disabled = !files.length

    const handleReadyPhoto = ({ image, type }: onReadyImg) => {
        const indexFile = files.findIndex((item) => item.type === type)

        if (indexFile >= 0) {
            setFiles((prev) =>
                prev.map((img, index) =>
                    index === indexFile ? { image, type } : img
                )
            )
        } else {
            setFiles((prev) => [...prev, { image, type }])
        }
    }

    const unChecked = (type: PhotoType) => {
        setFiles((prev) => prev.filter((item) => item.type !== type))
    }

    const handleSubmit = () => {
        !oneSubmit && files.map(onSubmit)
        oneSubmit && oneSubmit(files[0].image)
        onClose()
    }

    return (
        <div
            onClick={onClose}
            className={classNames(styles.root, classes && classes.root)}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className={classNames(styles.content)}
            >
                <span onClick={onClose} className={styles.close} />
                <div
                    className={classNames(
                        styles.wrapper,
                        sizes.length === 1 && styles.mini
                    )}
                >
                    {sizes.length > 1 && (
                        <>
                            <h2 className={styles.title}>Фото редактор</h2>

                            <div className={styles.mainImage}>
                                <img src={image} alt="image_observe" />
                            </div>
                        </>
                    )}

                    <EditorList
                        unChecked={unChecked}
                        image={image}
                        sizes={sizes}
                        onReady={handleReadyPhoto}
                    />

                    <div className={styles.btnCenter}>
                        <button
                            disabled={disabled}
                            onClick={handleSubmit}
                            className={styles.send}
                        >
                            Сохранить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AvatarCrop
