import React, { FC } from 'react'
import { User } from '../../../types/types'
import { ListHolder } from '../../simples/ListHolder'
import styles from './UsersTable.module.scss'

interface Props {
    users: User[]
}

const UsersTable: FC<Props> = ({ users }) => {
    const columns = [
        { title: 'ФИО', id: 1 },
        { title: 'Email ', id: 2 },
        { title: 'Телефон ', id: 3 },
    ]

    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    {columns.map((column) => (
                        <th scope="col" key={column.id}>
                            {column.title}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {users.map((user) => (
                    <tr key={user.id}>
                        <td>{user.fullName}</td>
                        <td>{user.email}</td>
                        <td>{user.phone || 'Телефон отсутсвует'}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default UsersTable
