import { ErrorsTypes } from '../utils/api'
import api from '../utils/api'
import { endpoints } from '../constants/constants'
import { getFormData } from '../constants/helper'
import {
    AdvertisingResponseType,
    AdvFileType,
    CreateAdvertisingResponseType,
    DataUploadPhoto,
    PhotoResponseType,
    RemovePhotoResponseType,
    UploadPhoto,
} from '../types/types'
import { route } from '../utils/route'

export type AdvertisingType = {
    success: boolean
    errors?: ErrorsTypes
    data?: {
        advertising: AdvertisingResponseType
    }
}
export type CreateAdvertising = {
    data: CreateAdvertisingResponseType
    baseUrl: string
    errors: string[]
    systemVersion: number
    needUpdate: number
    success: boolean
    meta: any
}

export type GetListResponse = {
    data: AdvertisingResponseType[]
    baseUrl: string
    errors: any
    systemVersion: number
    needUpdate: number
    success: boolean
    meta: any
}

type DataUpdateAdType = {
    title: string
    description: string
    link: string
}

type ResponseUploadPhototype = {
    data: PhotoResponseType
    success: boolean
}

/**
 * Сервис для работы с рекламой
 */
export class Advertising {
    /**
     * Метод для получения id создаваемой рекламной записи
     */
    static async createAd(): Promise<CreateAdvertisingResponseType> {
        const response = await api.get<CreateAdvertising>(endpoints.createAd)

        return response.data.data
    }

    /**
     * Метод для отправки фотографий рекламной записи
     */

    static async uploadPhoto(
        id: number,
        type: AdvFileType,
        data: DataUploadPhoto
    ): Promise<ResponseUploadPhototype> {
        const response = await api.post(
            `${endpoints.uploadPhotoAd}/${id}`,
            getFormData({ ...data, type }, true)
        )
        return response.data
    }

    /**
     * Метод для удаления фотографии рекламной записи
     */

    static async removePhoto(id: number): Promise<boolean> {
        const response = await api.delete<RemovePhotoResponseType>(
            `${endpoints.removePhotoAd}/${id}`
        )

        return response.data.success
    }

    /**
     * Запрос на обновление рекламной записи

     * @param id id индентификатор рекламной записи
     * @param title название рекламной записи
     * @param description  Описание рекламной записи
     * @param link  Ссылка на источник

     */

    static async updateAd(
        id: number,
        data: DataUpdateAdType
    ): Promise<AdvertisingType> {
        const result: AdvertisingType = {
            success: false,
        }

        try {
            console.log('getFormData(data)', data)

            const response = await api.post<AdvertisingResponseType>(
                `${endpoints.updateAd}/${id}`,
                getFormData(data)
            )

            result.success = true
            result.data = {
                advertising: response.data,
            }
        } catch (error: any) {
            console.log('error', error.response)
        }
        return result
    }

    /**
     * Метод для получения списка рекламных записей
     */
    static async getListAd(
        page?: number,
        onPage?: number,
        limit: number = 10
    ): Promise<GetListResponse> {
        const offset = (page ? page - 1 : 0) * (onPage ? onPage : 10)
        const lim = `&limit=${limit}`
        const response = await api.get<GetListResponse>(
            `${endpoints.listAd}?offset=${offset}${lim}`
        )
        return response.data
    }
    /**
     * Метод для получения списка рекламных записей
     */
    static async getOneAd(id: number): Promise<AdvertisingResponseType> {
        const response = await api.get(route(endpoints.getOneAdv, { id }))
        return response.data.data
    }

    /**
     * Метод удаления рекламную запись по id
     */

    static async deleteAd(id: number): Promise<boolean> {
        const response = await api.delete<GetListResponse>(
            `${endpoints.deleteAd}/${id}`
        )
        console.log('response', response)

        return response.data.success
    }
    /**
     * публикация
     */
    static async publishAdv(id: number): Promise<boolean> {
        const response = await api.get<GetListResponse>(
            route(endpoints.publishAdv, { id })
        )

        return response.data.success
    }
    /**
     * снятие с публикации
     */
    static async unPublishAdv(id: number): Promise<boolean> {
        const response = await api.get<GetListResponse>(
            route(endpoints.unpublishAdv, { id })
        )
        return response.data.success
    }
}
