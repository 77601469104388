import { PhotoType } from '../types/types'
import { RoutesMap } from '../utils/route'

export const API_PREFIX = '/v1/'
export const DOMAIN = process.env.REACT_APP_API_URL + '/'

export enum PhotoGaleryType {
    MAIN = 1,
    PREVIEW = 2,
    PHOTO_LIST = 3,
}

export const endpoints: RoutesMap = {
    // Auth
    login: `auth/login`,
    getUser: 'auth/user',

    // Users
    getUserList: 'user/count',

    //  Категории статей

    createArticleCategory: 'articles-categories/create',
    updateArticleCategory: 'articles-categories/update',
    listArticleCategories: 'articles-categories/list',
    deleteArticleCategory: 'articles-categories/delete',

    //  Категории новостей

    createNewsCategory: 'news-categories/create',
    updateNewsCategory: 'news-categories/update',
    listNewsCategories: 'news-categories/list',
    deleteNewsCategory: 'news-categories/delete',

    //  Тип локации

    createPlaceType: 'places-types/create',
    updatePlaceType: 'places-types/update',
    listPlacesType: 'places-types/list',
    deletePlaceType: 'places-types/delete',

    //  Категории событий

    createEventCategory: 'events-categories/create',
    updateEventCategory: 'events-categories/update',
    listEventCategories: 'events-categories/list',
    deleteEventCategory: 'events-categories/delete',

    // Загрузка файлов

    loadFile: 'attaches/load-file',

    // Реклама

    createAd: 'ad/create',
    updateAd: 'ad/update',
    deleteAd: 'ad/delete',
    uploadPhotoAd: 'ad/photo-upload',
    removePhotoAd: 'ad/photo-remove',
    getAd: 'ad/get',
    getRandomAd: 'ad/random',
    publishingStatusAd: 'ad/publishing',
    listAd: 'ad/list',
    getOneAdv: 'ad/get/:id',
    publishAdv: 'ad/publish/:id',
    unpublishAdv: 'ad/un-publish/:id',

    // События
    publishEvent: 'events/publish',
    unPublishEvent: 'events/un-publish',
    uploadPhotoEvent: 'events/photo-upload',
    uploadPreviewEvent: 'events/preview-upload',
    removePhotoEvent: 'events/photo-remove',
    removePreviewEvent: 'events/preview-remove',
    createEvent: 'events/create',
    updateEvent: 'events/update',
    deleteEvent: 'events/delete',
    getEvent: 'events/get/:id',
    listEvents: 'events/admin-list',
    xlsEvents: 'events/import',

    // Локации

    createPlaces: 'places/create',
    updatePlaces: 'places/update',
    getPlaces: 'places/get',
    getOnePlace: 'places/get/:id',
    listPlaces: 'places/list',
    deletePlaces: 'places/delete',
    publishPlaces: 'places/publish',
    unPublishPlaces: 'places/un-publish',
    uploadPhotoPlaces: 'places/photo-upload',
    removePhotoPlaces: 'places/photo-remove',
    toTopPlace: 'places/set-top/:id',

    // Социальные сети

    createSNtype: 'social-network-types/create',
    updateSNtype: 'social-network-types/update',
    getSNtype: 'social-network-types/get',
    listSNtype: 'social-network-types/list',
    deleteSNtype: 'social-network-types/delete/:id',
    photoUploadSNtype: 'social-network-types/photo-upload',
    photoRemoveSNtype: 'social-network-types/photo-remove',

    // Специальные проекты

    createSpecialProject: 'special-projects/create',
    updateSpecialProject: 'special-projects/update',
    publishSpecialProject: 'special-projects/publish',
    unPublishSpecialProject: 'special-projects/un-publish',
    getSpecialProject: 'special-projects/get/:id',
    listSpecialProject: 'special-projects/list',
    deleteSpecialProject: 'special-projects/delete',
    uploadPhotoSpecialProject: 'special-projects/photo-upload',
    removePhotoSpecialProject: 'special-projects/photo-remove',

    // Статьи
    publishArticle: 'articles/publish',
    unPublishArticle: 'articles/un-publish',
    createArticle: 'articles/create',
    uploadPhotoArticle: 'articles/photo-upload',
    uploadPreviewArticle: 'articles/preview-upload',
    removePhotoArticle: 'articles/photo-remove',
    removePreviewArticle: 'articles/preview-remove',
    updateArticle: 'articles/update',
    getArticle: 'articles/get/:id',
    listArticle: 'articles/list',
    deleteArticle: 'articles/delete',
    publishingArticle: 'articles/publishing',

    // Новости
    publishNews: 'news/publish',
    unPublishNews: 'news/un-publish',
    createNews: 'news/create',
    uploadPhotoNews: 'news/photo-upload',
    uploadPreviewNews: 'news/preview-upload',
    removePhotoNews: 'news/photo-remove',
    removePreviewNews: 'news/preview-remove',
    updateNews: 'news/update',
    getNews: 'news/get/:id',
    listNews: 'news/list',
    deleteNews: 'news/delete',
    publishingNews: 'news/publishing',

    removeToken: `auth/remove-token`,
    removeRefreshToken: `auth/remove-refresh-token`,
    refreshToken: `$auth/refresh-token`,
    sendPasswordRecoveryLink: `${API_PREFIX}auth/send-password-recovery-link`,
    recoveryPassword: `${API_PREFIX}auth/recovery-password`,

    // Signup
    signupConfirm: `${API_PREFIX}signup/confirm/:token`,
    signup: `${API_PREFIX}signup`,

    //FEEDBACK
    publishFeedback: 'places-reviews/publish?id=:id',
    unPublishFeedback: 'places-reviews/un-publish?id=:id',
    getFeedbackListById: 'places-reviews/list?placeId=:id',
    getFeedbackList: 'places-reviews/list',
    getOneFeedback: 'places-reviews/get/:id',
    deleteFeedback: 'places-reviews/delete?id=:id',

    // EVENTS FEEDBACK
    publishEventFeedback: 'events-reviews/publish?id=:id',
    unPublishEventFeedback: 'events-reviews/un-publish?id=:id',
    getFeedbackEventListById: 'events-reviews/list?eventId=:id',
    getFeedbackEventList: 'events-reviews/list',
    getOneFeedbackEvent: 'events-reviews/get/:id',
    deleteFeedbackEvent: 'events-reviews/delete?id=:id',

    // CITIES
    cityList: 'city/list',
    getCityById: 'city/get/:id',
    createCity: 'city/create',
    updateCity: 'city/update/:id',
    deleteCity: 'city/delete/:id',
    cityHint: 'city/hint',

    // REQUESTS
    requestList: 'requests/list',
    requestXls: 'requests/download',
    requestChangeStatus: 'requests/status/:id??status=:status',
}

export const photoSizes = [
    { width: 440, height: 390, type: PhotoType.PREVIEW },
    { width: 910, height: 390, type: PhotoType.BIG_PREVIEW },
    { width: 1380, height: 600, type: PhotoType.MAIN_PHOTO },
    { width: 359, height: 265, type: PhotoType.MOBILE_PREVIEW },
    { width: 155, height: 217, type: PhotoType.MOBILE_MIN_PREVIEW },
    { width: 375, height: 400, type: PhotoType.MOBILE_HEAD_PHOTOS },
    { width: 524, height: 524, type: PhotoType.MOBILE_PHOTOS },
]

export const articlePhotoSizes = [
    { width: 700, height: 620, type: PhotoType.CAROUSEL_PREVIEW },
    ...photoSizes,
]

export const locationPhotoSizes = [
    { width: 440, height: 390, type: PhotoType.PREVIEW },
    { width: 910, height: 390, type: PhotoType.BIG_PREVIEW },
    { width: 1380, height: 600, type: PhotoType.MAIN_PHOTO },
    { width: 375, height: 400, type: PhotoType.MOBILE_HEAD_PHOTOS },
]

export const specialProjectPhotoSizes = [
    { width: 359, height: 265, type: PhotoType.MOBILE_PREVIEW },
    ...locationPhotoSizes,
]

export const mailRe = /\S+@\S+\.\S{2}/
