import { Input } from 'antd'
import React, { FC } from 'react'
import styles from './InputLabled.module.scss'


type Props = {
    value: string
    onChange: (e: string) => void
    label: string
    placeholder?: string
    multiple?: boolean
}

/**
 * 
 * @param value 
 * @param onChange 
 * @param label 
 * @param placeholder 
 * @returns 
 */

const { TextArea } = Input;

const InputLabled: FC<Props> = ({ value, onChange, label, placeholder, multiple = false }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.label}>{label}</div>
            {!multiple ? <Input
                onChange={(e) => onChange(e.target.value)}
                value={value}
                placeholder={placeholder || label}
            /> :
                <TextArea
                    placeholder={placeholder || label}
                    allowClear
                    onChange={(e) => onChange(e.target.value)}
                    autoSize={{ minRows: 6, maxRows: 10 }}
                    showCount={true}
                    value={value}
                />}
        </div>
    )
}

export default React.memo(InputLabled)