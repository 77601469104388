import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CategoryEvent } from '../../services/CategoryEvent'
import { CityService } from '../../services/CityService'
import { Location } from '../../services/Location'
import {
    CategoryResponseType,
    CityType,
    LocationResponseType,
} from '../../types/types'

/**
 * Начальное состояние редьюсера Auth/
 */

export const initialState = {
    eventCategories: [] as CategoryResponseType[],
    places: [] as LocationResponseType[],
    cities: [] as CityType[],
}

export const fetchEventCategories = createAsyncThunk(
    'storage/fetchEventCategories',
    async () => {
        const response = await CategoryEvent.getListEventCategories(1, 10, 400)
        return response.data
    }
)
export const fetchPlaces = createAsyncThunk('storage/fetchPlaces', async () => {
    let data: LocationResponseType[] = []
    let isLoad = true
    let page = 1

    while (isLoad) {
        const response = await Location.getListLocation(page, 10, 50)

        if (response.data.length === 0) {
            isLoad = false
        }
        page += page
        data.push(...response.data)
    }

    return data
})

export const fetchCities = createAsyncThunk('storage/fetchCities', async () => {
    let data: CityType[] = []
    let page = 1
    const response = await CityService.getCityList(page, 10, 50)
    data.push(...response.data)
    return data
})
/**
 * Создание слайса App
 */
export const storageSlice = createSlice({
    name: 'storage',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchEventCategories.fulfilled, (state, action) => {
            state.eventCategories = action.payload
        })
        builder.addCase(fetchPlaces.fulfilled, (state, action) => {
            state.places = action.payload
        })
        builder.addCase(fetchCities.fulfilled, (state, action) => {
            state.cities = action.payload
        })
    },
})

// -------------------------------------------
// Export a reducer
// -------------------------------------------
export default storageSlice.reducer
