import { Input } from 'antd'
import React, { FC } from 'react'
import style from './TitleAndDefinition.module.scss'

const { TextArea } = Input

type InputNames = 'first' | 'second' | 'third' | 'textarea'

type Props = {
    firstPlaceholderTitle?: string
    firstPlaceholder?: boolean
    secondPlaceholderTitle?: string
    secondPlaceholder?: boolean
    thirdPlaceholderTitle?: string
    thirdPlaceholder?: boolean
    textAreaPlaceholderTitle?: string
    textAreaPlaceholder?: boolean
    onChangeFirst?: (e: any) => void
    onChangeSecond?: (e: any) => void
    onChangeThird?: (e: any) => void
    onChangeTextArea?: (e: any) => void
    firstTitle?: string
    secondTitle?: string
    thirdTitle?: string
    textAreaTitle?: string
    firstValue?: string | number
    secondValue?: string | number
    thirdValue?: string | number
    textAreaValue?: string
    isError?: boolean
    isSeanseStyle?: boolean
    excludeErrorInputs?: InputNames[]
}

const TitleAndDefinition: FC<Props> = ({
    firstValue,
    secondValue,
    thirdValue,
    textAreaValue,
    firstPlaceholder = true,
    firstTitle,
    firstPlaceholderTitle,
    secondPlaceholder = false,
    secondTitle,
    secondPlaceholderTitle,
    thirdPlaceholder = false,
    thirdTitle,
    thirdPlaceholderTitle,
    textAreaPlaceholder = false,
    textAreaTitle,
    textAreaPlaceholderTitle,
    onChangeFirst,
    onChangeSecond,
    onChangeThird,
    onChangeTextArea,
    isError = false,
    isSeanseStyle = false,
    excludeErrorInputs,
}) => {
    const styleError = {
        border: isError ? '1px solid red' : '0px solid red',
        marginBottom: isSeanseStyle ? '0px' : '15px',
    }

    return (
        <>
            {firstPlaceholder ? (
                <div
                    className={style.inputContainer}
                    style={
                        excludeErrorInputs?.includes('first')
                            ? undefined
                            : styleError
                    }
                >
                    <h2 className={style.title}>{firstTitle}</h2>
                    <Input
                        placeholder={firstPlaceholderTitle}
                        allowClear
                        onChange={(e) => {
                            // @ts-ignore
                            onChangeFirst(e.target.value)
                        }}
                        value={firstValue}
                    />
                </div>
            ) : null}
            {secondPlaceholder ? (
                <div className={style.inputContainer}>
                    <h2 className={style.title}>{secondTitle}</h2>
                    <Input
                        placeholder={secondPlaceholderTitle}
                        allowClear
                        onChange={(e) => {
                            // @ts-ignore
                            onChangeSecond(e.target.value)
                        }}
                        value={secondValue}
                    />
                </div>
            ) : null}
            {thirdPlaceholder ? (
                <div
                    className={style.inputContainer}
                    style={
                        excludeErrorInputs?.includes('third')
                            ? undefined
                            : styleError
                    }
                >
                    <h2 className={style.title}>{thirdTitle}</h2>
                    <Input
                        placeholder={thirdPlaceholderTitle}
                        allowClear
                        onChange={(e) => {
                            // @ts-ignore
                            onChangeThird(e.target.value)
                        }}
                        value={thirdValue}
                    />
                </div>
            ) : null}

            {textAreaPlaceholder ? (
                <div
                    className={style.inputContainer}
                    style={
                        excludeErrorInputs?.includes('textarea')
                            ? undefined
                            : styleError
                    }
                >
                    <h2 className={style.title}>{textAreaTitle}</h2>

                    <TextArea
                        placeholder={textAreaPlaceholderTitle}
                        allowClear
                        onChange={(e) => {
                            // @ts-ignore
                            onChangeTextArea(e.target.value)
                        }}
                        autoSize={{ minRows: 6, maxRows: 10 }}
                        showCount={true}
                        value={textAreaValue}
                    />
                </div>
            ) : null}
        </>
    )
}

export default TitleAndDefinition
