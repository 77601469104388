import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../store/slice/auth'
import appReducer from '../store/slice/app'
import storageReducer from '../store/slice/storage'

export const store = configureStore({
    reducer: {
        auth: authReducer,
        app: appReducer,
        storage: storageReducer
    },
})

// Вывод типов `RootState` и `AppDispatch` из стора.
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
