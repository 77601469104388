import { Button, Input, message, Modal } from 'antd';
import React, { FC, useEffect, useState } from 'react'
import { List } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import produce from 'immer';
import { CategoryNews, NewsTypeResponse } from '../../../services/CategoryNews';
import styles from './ListNewsCategories.module.scss'
import { useAppSelector, useAppDispatch } from '../../../hooks/store';
import { selectActivePage, setOnPage, setTotalCounts } from '../../../store/slice/app';
import Title from 'antd/lib/typography/Title';
import { ListHolder } from '../../simples/ListHolder';



type Props = {}

const ListNewsCategories: FC<Props> = () => {

  const [totalCount, setTotalCount] = useState<number>(0)
  const [onPage, setOnPage] = useState<number>(10)

  const [page, setPage] = useState<number>(1)

    const [list, setList] = useState<NewsTypeResponse[]>([])

    const [currentChangingItem, setCurrentChangingItem] = useState<NewsTypeResponse>({} as NewsTypeResponse)

    const [removedStatus, setRemovedStatus] = useState(false)

    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);


    const getList = async () => {
            
        const list = await CategoryNews.getListNewsCategories(page)
        setTotalCount(list.meta.maxCount)
        setOnPage(10)
        setList(list.data)
    }
    
    const showModal = (id:number) => {
       
        const changingElem = list.filter(item => item.id === id)
    
        setCurrentChangingItem(changingElem[0])
        setVisible(true);

      };

      const handleOk = async () => {

        if(!!!currentChangingItem.name.trim() || !!!currentChangingItem.description.trim()){
          message.warning('Все поля являются обязательными для заполнения')
          return
        }

        const response = await CategoryNews.updateCategory(currentChangingItem.id, currentChangingItem.name, currentChangingItem.description)

        setConfirmLoading(true);
        if (response) {
            setVisible(false);
          setConfirmLoading(false);
        }
     
      };

      const handleCancel = () => {

        setVisible(false);

      };

    const removeCategory = async (id: number) => {
        const response = await CategoryNews.deleteNewsCategory(id)
        
        if (response) {
            setRemovedStatus(true)
        } 
        else {
          
            message.error('This is an error message');

        }
        
    }


    useEffect(()=> {
        getList().then()
        setRemovedStatus(false)
    },[removedStatus, confirmLoading, page])

    return (
        <ListHolder 
        totalCount={totalCount} 
        onPageChange={setPage} 
        onPageShow={setOnPage}  >
        <Title level={2}>Список категой новостей</Title>
        <List
            className = {styles.itemsWrapper}
            bordered = {true}
            itemLayout="horizontal"
            dataSource={list}
            renderItem={item => (
            <>
                <List.Item className = {styles.item}>
                    <div className = {styles.textWrapper}>
                        <h3 className = {styles.title}>{item.name}</h3>
                        <p className = {styles.description}>{item.description}</p>
                    </div>
                    <div>
                        <span onClick = {()=> showModal(item.id)} className = {styles.change}>Изменить</span>
                        <DeleteOutlined className = {styles.icon} onClick = {()=> removeCategory(item.id)}/>
                    </div>
                </List.Item>
                <div className = {styles.borderline}/>
            </>
            )}
        />


            <Modal
              bodyStyle = {{height: '400px'}}
              width = {800}
              title="Редактирование категории"
              visible={visible}
              onOk={handleOk}
              confirmLoading={confirmLoading}
              onCancel={handleCancel}
              okText = 'Сохранить'
              cancelText = 'Отменить'
            > 
              <label> 
              <h3 className = {styles.titleModal}>Название категории</h3>
                <Input
                  value = {currentChangingItem.name}
                  onChange = {(e)=> setCurrentChangingItem(produce(draft => {
                      draft.name = e.target.value
                  }))}
                />

              </label>
              <label>
                <h3 className = {styles.titleModal}>Описание</h3>
                <Input
                  value = {currentChangingItem.description}
                  onChange = {(e) => setCurrentChangingItem(produce(draft => {
                      draft.description = e.target.value
                  }))}
                />
              </label>
              
            </Modal>
          </ListHolder>


    )
}

export default ListNewsCategories
