import { Pagination } from 'antd'
import React, { FC, useEffect, useLayoutEffect, useState } from 'react'
import styles from './ListHolder.module.scss'

type Props = {
    children: any
    totalCount: number
    onPageChange: (page: number) => void
    onPageShow: (onPage: number) => void
    currentPage?: number
}

/**
 * 
 * @param children 
 * @param totalCount 
 * @param onPageChange 
 * @param onPageShow 
 * @returns 
 */

const ListHolder: FC<Props> = ({ children, totalCount, onPageChange, onPageShow, currentPage}) => {
    return (
        <div className={styles.wrapper}>
            <div className="">
                {children}
            </div>

            <div className={styles.pagination}>
                <Pagination
                    hideOnSinglePage
                    onChange={onPageChange}
                    current={currentPage}
                    defaultCurrent={1}
                    size={'small'}
                    total={totalCount}
                    onShowSizeChange={onPageShow}
                    showSizeChanger={false}
                />
            </div>
        </div>
    )
}

export default ListHolder