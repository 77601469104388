import { ChangeEvent, FC, useState } from 'react'
import { IPhotoArea, PhotoType, SizeType } from '../../../types/types'
import { titleOfPhotoType } from '../../../utils/photoEditor'
import { AvatarCrop } from '../AvatarCrop'
import { onReadyImg } from '../AvatarCrop/Editor'
import { PhotoList } from '../PhotoList'
import styles from './PhotoArea.module.scss'

interface Props {
    data: IPhotoArea[]
    sizes: SizeType[]
    onSubmit: (value: onReadyImg) => Promise<void>
    onSelectMain: (id: number, type: PhotoType) => void
    onDelete: (id: number) => void
}

const PhotoArea: FC<Props> = ({
    data,
    sizes,
    onSubmit,
    onSelectMain,
    onDelete,
}) => {
    const [photo, setPhoto] = useState('')
    const [isCrop, setIsCrop] = useState(false)

    const currentSizes =
        data &&
        sizes.filter(
            (size) =>
                size.type ===
                data.find((photo) => photo.type === size.type)?.type
        )

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        setIsCrop(true)
        const fileReader = new FileReader()

        if (target?.files) {
            fileReader.onload = ({ target }) => {
                setPhoto(target?.result as string)
            }
            fileReader.readAsDataURL(target.files[0])
        }
    }

    return (
        <div className={styles.wrapper}>
            <h1 className={styles.title}>Фото</h1>

            <label className={styles.load} htmlFor="file">
                Загрузить
            </label>
            <input
                id="file"
                className={styles.input}
                type="file"
                multiple={false}
                onChange={handleChange}
            />

            {!!currentSizes &&
                !!data &&
                currentSizes.map((item, i) => (
                    <PhotoList
                        key={i}
                        data={data.filter((photo) => photo.type === item.type)}
                        title={titleOfPhotoType(item.type)}
                        onSelectMain={onSelectMain}
                        onDelete={onDelete}
                    />
                ))}

            {isCrop && (
                <AvatarCrop
                    onSubmit={onSubmit}
                    onClose={setIsCrop.bind(null, false)}
                    image={photo}
                    sizes={sizes}
                />
            )}
        </div>
    )
}

export default PhotoArea
