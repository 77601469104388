import { Alert, Button, message } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { FC, useEffect, useState } from 'react'
import { CategoryArticle } from '../../../services/CategoryArticle';
import { TitleAndDefinition } from '../../simples/TitleAndDefinition';
import styles from './ArticleCategory.module.scss'

type Props = {}

const ArticleCategory: FC<Props> = () => {

    const [namaCategoty, setCategory] = useState('')
    const [description, setDescription] = useState('')
    const [isError, setError] = useState(false)
    
    const onClickHandler = async () => {
        if (namaCategoty.trim() === '' || description.trim() === '') {
            setError(true)
            return
        }
        const response = await CategoryArticle.createNewCategory(namaCategoty, description)

        if (response.success) {
            setCategory('')
            setDescription('')
        } else {
            message.error('Не получилось создать категорию');
        }
    }

    useEffect(()=> {
       setTimeout(() => {
            setError(false) 
       }, 5000); 
    },[isError])

    return (
        <div className = {styles.wrapper}> 
        <Title level={2}>Создание категории статей</Title>
            {isError ? <Alert className = {styles.alert} message="Все поля обязательны к заполнению" type="error" /> : null}
            <TitleAndDefinition
                isError = {isError} 
                firstValue = {namaCategoty}
                textAreaValue = {description}
                firstTitle = "Название категории статьи"
                textAreaTitle = "Описание статьи"
                firstPlaceholder = {true}
                textAreaPlaceholder = {true}
                firstPlaceholderTitle = "Введите название категории"
                textAreaPlaceholderTitle = "Введите описание категории"
                onChangeFirst = {(value)=> setCategory(value)}
                onChangeTextArea = {(value) => setDescription(value)}
            />
            <Button type = 'primary' onClick = {onClickHandler}>Отправить</Button>
        </div> 
    )
}

export default ArticleCategory
