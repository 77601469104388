import { FC, useState } from 'react'
import { titleOfPhotoType } from '../../../utils/photoEditor'
import { Checkbox } from 'antd'
import Editor, { onReadyImg } from './Editor'
import styles from './AvatarCrop.module.scss'
import { PhotoType, SizeType } from '../../../types/types'

interface IProps {
    sizes: SizeType[]
    image: File
    unChecked: (type: PhotoType) => void
    onReady: (obj: onReadyImg) => void
}

const EditorList: FC<IProps> = ({ onReady, image, unChecked, sizes }) => {
    const [photoTypes, setPhotoTypes] = useState<string[]>([])

    const hadleChecked = (type: PhotoType) => {
        if (photoTypes.includes(type)) {
            unChecked(type)
            setPhotoTypes((prev) => prev.filter((t) => t !== type))
        } else {
            setPhotoTypes((prev) => [...prev, type])
        }
    }

    return (
        <ul className={styles.checkList}>
            {sizes.map((size, i) => (
                <li className={styles.checkListItem} key={size.type}>
                    {sizes.length > 1 && (
                        <div className={styles.items}>
                            <Checkbox
                                checked={photoTypes.includes(size.type)}
                                onChange={hadleChecked.bind(null, size.type, i)}
                            />
                            <span className={styles.sizeTitle}>
                                {titleOfPhotoType(size.type)}
                            </span>
                        </div>
                    )}

                    {sizes.length > 1 && photoTypes.includes(size.type) && (
                        <Editor size={size} onReady={onReady} image={image} />
                    )}

                    {sizes.length === 1 && (
                        <Editor size={size} onReady={onReady} image={image} />
                    )}
                </li>
            ))}
        </ul>
    )
}

export default EditorList
