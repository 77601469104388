import React, { FC, useEffect, useRef, useState } from 'react'
import styles from './DropDown.module.scss'
import { ReactComponent as Arrow } from '../../../assets/icons/ArrowDropDown.svg';
import { ReactComponent as Search } from '../../../assets/icons/SearchDropDown.svg';
import classNames from 'classnames';
type Props = {
    onChange: (id: number) => void
    value?: number
    list: OptionType[]
}
export type OptionType = {
    value: number
    label: string
}

const DropDown: FC<Props> = ({ onChange, value, list }) => {
    const [select, setSelect] = useState<OptionType[]>([])
    const [valueInput, setValueInput] = useState<string>('')

    const [isShow, setIsShow] = useState<boolean>(false)


    const ref = useRef<HTMLElement>()
    const refInput = useRef<HTMLElement>()


    const handleClick = (event: MouseEvent) => {
        // console.log(event, 55);

        // @ts-ignore
        if (event.target && ref.current && ref.current.contains(event.target)) {
            setIsShow(false)
        }



    }
    // document.addEventListener('click', handleClick, true)


    const toggleShow = () => setIsShow(prev => !prev)

    useEffect(() => {
        if (!!valueInput) {
            const arr: OptionType[] = []
            list.forEach(item => {
                if (item.label.includes(valueInput)) {
                    arr.push(item)
                }
            })
            setSelect(arr)
        }
        else {
            setSelect(list)
        }

    }, [valueInput])
    // useEffect(() => {
    //     return () => document.removeEventListener('click', handleClick, true)
    // }, [])
    const onChangeState = (id: number) => {
        const text = list.find(item => item.value === id)?.label || ''
        setValueInput(text)
        setIsShow(false)
        onChange(id)
    }
    return (
        <div
            className={styles.wrapper}
        >
            <div
                onClick={toggleShow}
                // ref={refInput
                // } 
                className={classNames(styles.wrapperInput, {
                    [styles.activWrapperInput]: isShow
                })}
            >
                <input
                    type="text"
                    className={styles.input}
                    value={valueInput}
                    onChange={(e) => setValueInput(e.target.value)}

                />
                <span
                    className={classNames(styles.arrowDropDown)}
                >
                    {!isShow ? < Arrow /> : <Search />}
                </span>
            </div>
            {isShow &&
                <div
                    // @ts-ignore
                    ref={ref}
                >

                    <div

                        className={classNames(styles.selectWrapper, {
                            [styles.noSelectWrapper]: !isShow,
                        })}
                    >
                        {select.map((item) => {
                            return <div
                                className={styles.selectItem}
                                onClick={() => onChangeState(item.value)}
                            >
                                {item.label}
                            </div>
                        })}
                    </div>
                </div>
            }
        </div>
    )
}

export default DropDown