import { Checkbox } from 'antd'
import { FC, useEffect, useState } from 'react'
import { IPhotoArea, PhotoType } from '../../../types/types'
import { isChecked } from '../../../utils/photoEditor'
import { ReactComponent as Delete } from '../../../assets/icons/delete.svg'
import styles from './PhotoList.module.scss'
import { Loader } from '../../simples/Loader'

interface IProps {
    data: IPhotoArea[]
    title: string
    onSelectMain: (id: number, type: PhotoType) => void
    onDelete: (id: number) => void
}

const PhotoList: FC<IProps> = ({ data, title, onSelectMain, onDelete }) => {
    const [load, setLoad] = useState(false)

    const isLoading = () => {
        setLoad(true)
        data.forEach((item) => {
            fetch(item.url, { method: 'GET' })
                .then((_) => setLoad(true))
                .catch((_) => setLoad(false))
                .finally(() => setLoad(false))
        })
    }

    useEffect(() => {
        isLoading()
    }, [])
    return (
        <div className={styles.wrapper}>
            <span className={styles.title}>{title}</span>

            <ul className={styles.list}>
                {data.map((item, i) => (
                    <li key={item.photo_id}>
                        <div className={styles.imgWrapper}>
                            {load ? (
                                <div className={styles.load}>
                                    <Loader />
                                </div>
                            ) : (
                                <>
                                    <img
                                        className={styles.img}
                                        src={item.url}
                                        alt="image_item"
                                    />
                                    <div className={styles.itemMask}>
                                        <Delete
                                            onClick={onDelete.bind(
                                                null,
                                                data[i].photo_id
                                            )}
                                            width="40"
                                            height="40"
                                        />
                                    </div>
                                </>
                            )}
                        </div>

                        {isChecked(data[i].type) && (
                            <div className={styles.check}>
                                <Checkbox
                                    checked={data[i].is_main}
                                    onChange={onSelectMain.bind(
                                        null,
                                        data[i].photo_id,
                                        data[i].type
                                    )}
                                />
                                Главное
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default PhotoList
