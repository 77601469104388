import {
    CreateEventResponseType,
    LocationResponseType,
    UploadPhoto,
    DataUploadPhoto,
    PhotoResponseType,
    RemovePhotoResponseType,
    MetaType,
} from './../types/types'
import { getFormData } from './../constants/helper'
import { ErrorsTypes } from '../utils/api'
import api from '../utils/api'
import { endpoints } from '../constants/constants'
import { route } from '../utils/route'

export type DataCreateLocation = {
    typeId: number | undefined
    name: string
    address: string
    contacts: string
    description: string
    email?: string
    url?: string
    longitude: number
    latitude: number
    schedule?: string
    socialNetworks?: string
    content?: string

    cityId?: number
}

export type LocationCategory = {
    success: boolean
    errors?: ErrorsTypes
    data?: {
        location: LocationResponseType
    }
}
export type GetListResponse = {
    data: LocationResponseType[]
    baseUrl: string
    errors: any
    systemVersion: number
    needUpdate: number
    success: boolean
    meta: MetaType
}

export type CreateLocationResponse = {
    data: LocationResponseType
    baseUrl: string
    errors: any
    systemVersion: number
    needUpdate: number
    success: boolean
    meta: {
        totalCount: number
    }
}

/**
 * Сервис для работы с локациями
 */
export class Location {
    /**
     * Метод для получения списка локаций
     */

    static async getListLocation(
        page?: number,
        onPage?: number,
        limit: number = 10
    ): Promise<GetListResponse> {
        const offset = (page ? page - 1 : 0) * (onPage ? onPage : 10)
        const lim = `&limit=${limit}`
        const response = await api.get<GetListResponse>(
            `${endpoints.listPlaces}?offset=${offset}${lim}`
        )
        return response.data
    }
    /**
     * Метод для получения одной локации
     * @return - один объект локации
     */

    static async getOneLocation(id: number): Promise<LocationResponseType> {
        const response = await api.get(route(endpoints.getOnePlace, { id }))
        return response.data.data
    }

    /**
     * Метод для получения id создаваемой локации
     */
    static async createLocation(): Promise<CreateEventResponseType> {
        const response = await api.get<CreateLocationResponse>(
            endpoints.createPlaces
        )

        return response.data.data
    }

    /**
     * Метод для отмены публикации локации
     */

    static async unPublishLocation(id: number): Promise<boolean> {
        const response = await api.get<CreateLocationResponse>(
            `${endpoints.unPublishPlaces}/${id}`
        )

        return response.data.success
    }

    /**
     * Метод для для публикации локации
     */

    static async publishLocation(id: number): Promise<boolean> {
        const response = await api.get<CreateLocationResponse>(
            `${endpoints.publishPlaces}/${id}`
        )

        return response.data.success
    }

    /**
     * Метод для отправки фотографий локации
     */

    static async uploadPhoto(
        id: number,
        data: DataUploadPhoto
    ): Promise<UploadPhoto> {
        const result: UploadPhoto = {
            success: false,
        }

        try {
            const response = await api.post<PhotoResponseType>(
                `${endpoints.uploadPhotoPlaces}/${id}`,
                getFormData(data, true)
            )

            result.success = true
            result.data = {
                events: response.data,
            }
        } catch (error: any) {
            console.log('error', error.response)
        }
        return result
    }

    /**
     * Метод для удаления фотографии локации
     */

    static async removePhoto(id: number): Promise<boolean> {
        const response = await api.delete<RemovePhotoResponseType>(
            `${endpoints.removePhotoPlaces}/${id}`
        )

        return response.data.success
    }

    /**
     * Запрос на создание новой локации
     *
     * @param categoryId id места где будет ивент
     * @param placeId id места где будет ивент
     * @param name  Название события
     * @param description  Описание события
     * @param content Содержание события
     * @param price цена на ивент
     * @param youtubeLink ссылка на ютуб
     * @param buyTicketLink ссылка на билеты
     * @param startAt date format дата начала
     * @param endAt date format дата окончания
     * @param seances JSON интересные факты
     * @param interestingFacts JSON интересные факты
     */

    static async updateLocation(
        id: number,
        data: DataCreateLocation
    ): Promise<LocationCategory> {
        const result: LocationCategory = {
            success: false,
        }

        try {
            const response = await api.post<LocationResponseType>(
                `${endpoints.updatePlaces}/${id}`,
                getFormData(data)
            )

            result.success = true
            result.data = {
                location: response.data,
            }
        } catch (error: any) {
            console.log('updateLocation error', error.response)
        }
        return result
    }

    /**
     * Метод удаления локацию по id
     */

    static async deleteLocation(id: number): Promise<boolean> {
        const response = await api.delete<GetListResponse>(
            `${endpoints.deletePlaces}/${id}`
        )

        return response.data.success
    }

    /**
     * метод помещения в топ
     */
    static async toTopPlace(id: number): Promise<any> {
        const response = await api.get(route(endpoints.toTopPlace, { id }))
        return response.data
    }
}
