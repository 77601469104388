import { Button, Checkbox } from 'antd'
import React, { FC } from 'react'
import style from './SaveBtnBlock.module.scss'


type Props = {
    onSave: () => void
    onPublish: (e: boolean) => void
    isPublish: boolean
    hasID: boolean
}

/**
 * 
 * @param onSave 
 * @param onPublish 
 * @param isPublish 
 * @param hasID 
 */

const SaveBtnBlock: FC<Props> = ({ onSave, onPublish, isPublish, hasID }) => {
    return (
        <div className={style.btnWrapper}>
            <Button type='primary' onClick={onSave}>{hasID ? 'Сохранить' : 'Отправить'}</Button>
            <Checkbox checked={isPublish} onChange={(e) => {
                onPublish(e.target.checked)
            }}>
                {!!isPublish ? 'Опубликовано' : 'Опубликовать'}
            </Checkbox>
        </div>
    )
}

export default React.memo(SaveBtnBlock)