import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { User as UserType } from '../../types/types'
import { Auth as AuthService } from '../../services/Auth'
import { User as UserService } from '../../services/User'
import { ErrorsTypes } from '../../utils/api'
import { RootState } from '../index'

/**
 * Начальное состояние редьюсера Auth/
 */

export const initialState = {
    /** Объект авторизованного пользователя. */
    user: null as UserType | null,
    /** Состояние: проверка токена пользователя */
    checking: false,
    /** Состояние: проверка токена пользователя была произведена */
    checked: false,
    /** Состояние: пользователь авторизован */
   
    isAuth: false,
    /** Состояние: идет обновление данных пользователя */
    updating: false,
    /** Состояние: данные пользователя были обновлены */
    updated: false,
    /** Состояние: было ли обновление пользователя с ошибкой */
    errorUpdate: false,
    /** Объект ошибки при обновлении пользователя */
    errorUpdateData: undefined as ErrorsTypes | undefined,
}

// -------------------------------------------
// Async Thunks
// -------------------------------------------

/**
 * Проверка токена авторизации пользователя.
 * Перед запросом проверятся существование токена, если его нет, то метод завершается с ошибкой.
 * Если токен актуален, то возвращается объект пользователя, который сохраняется в стор.
 */
export const checkUser = createAsyncThunk(
    'auth/checkUser',
    async () => {
        const user = await UserService.getUser()
        return { user }
    },
    {
        condition: () => {
            const token = AuthService.getToken()
            return !!token
        },
        dispatchConditionRejection: true,
    }
)

/**
 * Выход пользователя.
 * На бэк уходит запрос на удаление токена. Так же токен удаляется из localStorage.
 */
// export const signOut = createAsyncThunk('auth/signOut', async () => {
//     await AuthService.removeToken()
// })

// -------------------------------------------
// Slice
// -------------------------------------------

/**
 * Создание слайса Auth.
 */
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthStatus: (state) => {
            state.isAuth = !state.isAuth
        },
        updateUser: (state, action) => {
            state.user = action.payload
        }
    },
    extraReducers: (builder) => {
        /**
         * Перед отправкой запроса на проверку токена ставим состояние checking в true.
         */
        builder.addCase(checkUser.pending, (state) => {
            state.checking = true
        })
        /**
         * Если проверка токена прошла успешно, то сохраняем объект авторизованного пользователя в стор.
         */
        builder.addCase(checkUser.fulfilled, (state, action) => {
            state.user = action.payload.user
            state.checking = false
            state.checked = true
            state.isAuth = true
        })
        /**
         * Если проверка токена завершилась провалом, то ставим состояние, что проверка была осуществлена
         */
        builder.addCase(checkUser.rejected, (state) => {
            state.checking = false
            state.checked = true
        })
    },
})

// -------------------------------------------
// Export action creators
// -------------------------------------------

// -------------------------------------------
// Selectors
// -------------------------------------------

/**
 * Селектор, идет ли в текущий момент проверка токена.
 * @param state Объект стора
 */
export const selectAuthChecking = (state: RootState) => state.auth.checking
/**
 * Селектор, была ли проверка токена.
 * @param state Объект стора
 */
// export const selectAuthChecked = (state: RootState) => state.auth.checked
/**
 * Селектор, авторизован ли пользователь.
 * @param state Объект стора
 */
export const selectIsAuth = (state: RootState) => state.auth.isAuth

/**
 * Селектор объекта авторизованного пользователя.
 * @param state Объект стора
 */
// export const selectAuthUser = (state: RootState) => state.auth.user


export const {
    setAuthStatus,
    updateUser
} = authSlice.actions


// -------------------------------------------
// Export a reducer
// -------------------------------------------
export default authSlice.reducer
