import { mailRe } from './constants'

export const getFormDataEvents = (data: any) => {
    const fd: FormData = new FormData()

    for (let [key, item] of Object.entries(data)) {
        if (item !== undefined || item !== '') {
            if (typeof item === 'string' || typeof item === 'number') {
                // @ts-ignore
                fd.append(`${key}`, item)
            } else if (typeof item === 'boolean') {
                fd.append(`${key}`, JSON.stringify(item))
            } else if (Array.isArray(item)) {
                fd.append(`${key}`, JSON.stringify(item))
                // item.forEach((elem) => {
                //     fd.append(`${key}`, elem)
                // })
            } else {
                new Error('Неизвестный формат данных')
            }
        }
    }

    return fd
}

export const getFormData = (data: any, isFile?: boolean) => {
    const fd: FormData = new FormData()

    for (let [key, item] of Object.entries(data)) {
        if (item !== undefined || item !== '') {
            if (typeof item === 'string' || typeof item === 'number') {
                // @ts-ignore
                fd.append(`${key}`, item)
            } else if (typeof item === 'boolean') {
                fd.append(`${key}`, item ? '1' : '0')
            } else if (Array.isArray(item)) {
                if (isFile) {
                    item.forEach((elem) => {
                        fd.append(`${key}`, elem)
                    })
                } else {
                    fd.append(`${key}`, JSON.stringify(item))
                }
            } else {
                new Error('Неизвестный формат данных')
            }
        }
    }

    return fd
}

export const getUnique = (arrBase: any, arrNew: any) => {
    console.log('arrBase', arrBase)
    console.log('arrNew', arrNew)

    //@ts-ignore
    const newUniqueValue = Array.from(new Set(arrNew)).map(JSON.stringify)
    //@ts-ignore
    const baseUniqueValue = Array.from(new Set(arrBase)).map(JSON.stringify)

    let unique1 = newUniqueValue.filter(
        (o) => baseUniqueValue.indexOf(o) === -1
    )
    // let unique2 = baseUniqueValue.filter((o) => newUniqueValue.indexOf(o) === -1);
    //@ts-ignore
    return unique1.map(JSON.parse)
}

export const uploadPhoto = async (
    id: number,
    method: (id: number, data: any) => any,
    data: any
): Promise<any> => {
    const response = await method(id, data)
    return response
}

export const validMail = (mail: string): boolean => {
    return !!mail.match(mailRe)
}
