import { Alert, Button, message } from 'antd'
import Title from 'antd/lib/typography/Title'
import React, { FC, useEffect, useState } from 'react'
import { LocationTypes } from '../../../services/LocationTypes'
import { TitleAndDefinition } from '../../simples/TitleAndDefinition'
import styles from './CreateLocationType.module.scss'

type Props = {}

const CreateLocationType: FC<Props> = () => {
    
    const [namaCategoty, setCategory] = useState('')
    const [description, setDescription] = useState('')
    const [isError, setError] = useState(false)
    
    const onClickHandler = async () => {
        if (namaCategoty.trim() === '' || description.trim() === '') {
            setError(true)
            return
        }
        const response = await LocationTypes.createLocationType(namaCategoty, description)

        if (response.success) {
            setCategory('')
            setDescription('')
        } else {
            message.error('Не получилось создать тип локации');
        }
    }

    useEffect(()=> {
       setTimeout(() => {
            setError(false) 
       }, 5000); 
    },[isError])

    return (
        <div className = {styles.wrapper}> 
        <Title level={2}>Список типа локации</Title>
        {isError ? <Alert className = {styles.alert} message="Все поля обязательны к заполнению" type="error" /> : null}
            <TitleAndDefinition 
                isError = {isError}
                firstValue = {namaCategoty}
                textAreaValue = {description}
                firstTitle = 'Название локации'
                textAreaTitle = 'Описание локации'
                firstPlaceholder = {true}
                textAreaPlaceholder = {true}
                firstPlaceholderTitle = 'Введите название локации'
                textAreaPlaceholderTitle = 'Введите описание локации'
                onChangeFirst = {(value)=> setCategory(value)}
                onChangeTextArea = {(value) => setDescription(value)}
            />
            <Button onClick = {onClickHandler}>Отправить</Button>
        </div> 
    )
}

export default CreateLocationType
